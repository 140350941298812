import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// component
import ButtonWithIcon from '../../components/General/ButtonWithIcon';

// data management
import { configContext } from '../../store/contexts';
import { SET_MODAL_CONFIG } from '../../store/constants';

// utility
import useGetBoardPublicKey from '../../hooks/useGetBoardPublicKey';
import { useCopyBoard } from '../../hooks/useCopyBoard';

// theme
import { colors } from '../../styles/theme';

// assets
import Copy from '../../assets/Copy';
import Delete from '../../assets/Delete';
import SocialShareIcon from '../../components/General/Icons/SocialShareIcon';

type propTypes = {
  board: Object,
};

const ContextMenu = ({ board }: propTypes) => {
  const { boardId, title } = board;

  // destructure getBoardPublicKey
  const { getBoardPublicKey } = useGetBoardPublicKey();

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { contextMenuX, contextMenuY } = configState.contextMenuPosition;

  const clickShareButton = async () => getBoardPublicKey(board);

  const copyBoard = useCopyBoard();

  return (
    <Styles className="context-menu" style={{ left: contextMenuX, top: contextMenuY + 5 }}>
      <ButtonWithIcon Icon={Copy} onClick={() => copyBoard(boardId)} text="copy" />
      <ButtonWithIcon
        Icon={SocialShareIcon}
        onClick={clickShareButton}
        text="share"
        color={colors.darkGrey}
      />
      <ButtonWithIcon
        Icon={Delete}
        onClick={e => {
          e.stopPropagation(); // prevent board from loading when clicking on delete board button
          configDispatch({
            type: SET_MODAL_CONFIG,
            payload: { open: true, type: 'delete', boardId, title },
          });
        }}
        text="delete"
      />
    </Styles>
  );
};

export default ContextMenu;

const Styles: any = styled.div`
  margin: 10px 0 0 -115px !important;
  button {
    :hover {
      background: ${colors.paleGrey};
    }
    :focus {
      border: ${colors.paleGrey};
    }
  }
`;
