import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext, tableContext } from '../../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../../store/constants';

// utility
import focusInputId from '../../../../util/focusInputId';
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

type propTypes = {
  dataQuality: Object,
  rowIndex: number,
  columnIndex: number,
};

const Quality = ({ dataQuality, rowIndex, columnIndex }: propTypes) => {
  const { qualityId, columnId } = dataQuality;

  const [title, setTitle] = useState(dataQuality.title || '');
  const [initialTitle, setInitialTitle] = useState(dataQuality.title);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // get width of column as it updates
  const { width } = columns.find(c => c.columnId === columnId);

  // destructure request utility
  const update = useUpdateRequest();

  // update quality on change
  const updateQuality = async value => {
    // if title is unchanged, do not trigger update request.
    if (initialTitle !== title) {
      setInitialTitle(title);

      // send request and update store
      update('element', 'quality', `/qualities/${qualityId}`, { title: value });
    }
  };

  return (
    <Styles
      className="cell"
      style={{
        width: width < 80 ? 60 : width - 20,
        minWidth: width < 80 ? 60 : width - 20,
        margin: '0 10px',
      }}
    >
      <input
        id={`tableQuality${rowIndex}${columnIndex}`}
        type="text"
        value={title}
        aria-label={title || 'empty text quality'}
        style={{
          width: width < 80 ? 80 : width - 20,
          minWidth: width < 80 ? 80 : width - 20,
        }}
        onChange={e => setTitle(e.target.value)}
        onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' })}
        onBlur={e => updateQuality(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 27) e.target.blur(); // escape key
          if (e.keyCode === 13) e.target.blur(); // return key
          if (e.keyCode === 27) e.target.blur(); // escape key
          if (e.keyCode === 38) focusInputId(`tableQuality${rowIndex - 1}${columnIndex}`); // up arrow
          if (e.keyCode === 38 && rowIndex === 0) focusInputId(`tableColumn${columnId}`); // up arrow
          if (e.keyCode === 40) focusInputId(`tableQuality${rowIndex + 1}${columnIndex}`); // down arrow
        }}
      />
    </Styles>
  );
};

export default Quality;

const Styles: any = styled.div``;
