import React from 'react';
import { useStateValue } from 'react-conflux';
import styled from 'styled-components';

// components
import Button from '../../../components/General/Button';
import SignInDropdown from '../../../components/General/SignInDropdown';

// data management
import { configContext } from '../../../store/contexts';

// utility
import useContextMenu from '../../../hooks/useContextMenu';

type propTypes = {
  boardId: number,
  isPublicRoute: boolean,
};

const ViewOnlyButton = ({ boardId, isPublicRoute }: propTypes) => {
  // config state
  const [configState] = useStateValue(configContext);
  const { contextMenuOpen, contextMenuPosition } = configState;
  const { contextMenuId } = contextMenuPosition;

  // context menu
  const { openContextMenu } = useContextMenu();

  if (!isPublicRoute) return <></>;

  return (
    <Styles>
      <Button
        text="view only"
        className="invert-button"
        event={e => openContextMenu(e, boardId)}
        style={{ marginRight: 10 }}
      />

      {contextMenuOpen && contextMenuId === boardId && <SignInDropdown />}
    </Styles>
  );
};

export default ViewOnlyButton;

const Styles: any = styled.div``;
