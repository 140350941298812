import { useState, useCallback, useEffect } from 'react';
import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../store/contexts';
import { SET_DRAGGING_ID } from '../store/constants';

// utility
import { getScaleAdjustment } from './useCanvasUtility';

// set position locally, dispatch when mouse is up.
// update value in useEffect
const useDraggableElement = (
  originalX: number,
  originalY: number,
  setElementTranslation: Function,
  updatePosition: Function,
  elementId: number
) => {
  const [configState, configDispatch] = useStateValue(configContext);
  const { scale } = configState.canvasConfig;

  // local state
  const [state, setState] = useState({
    isDragging: false,
    origin: { x: 0, y: 0 },
    elementTranslation: { x: originalX, y: originalY },
    isDragIcon: false,
  });

  useEffect(() => {
    setElementTranslation({ x: originalX, y: originalY });
  }, [state.isDragging]); // eslint-disable-line

  // sets the position of origin to the mouse clientX & clientY - the previous origin
  const handleMouseDownElement = useCallback(({ clientX, clientY, target, button }: Object) => {
    if (target.getAttribute('class') === 'dragicon' && button === 0) {
      configDispatch({ type: SET_DRAGGING_ID, payload: elementId });

      setState(prevState => ({
        ...prevState,
        isDragging: true,
        origin: { x: clientX - state.origin.x, y: clientY - state.origin.y },
      }));
    }
  }, []); // eslint-disable-line

  // moves the canvas when holding mouse down
  const handleMouseMove = useCallback(
    ({ clientX, clientY, button }) => {
      const adjustment = getScaleAdjustment(scale);

      const translation = {
        x: adjustment * (clientX - state.origin.x) + state.elementTranslation.x,
        y: adjustment * (clientY - state.origin.y) + state.elementTranslation.y,
      };

      if (button === 0) {
        setState(prevState => ({
          ...prevState,
          elementTranslation: translation,
        }));
        setElementTranslation(translation);
      }
    },
    [state.origin] // eslint-disable-line
  );

  const handleMouseUpElement = () => {
    configDispatch({ type: SET_DRAGGING_ID, payload: undefined });
    const { x, y } = state.elementTranslation;

    setState(prevState => ({
      ...prevState,
      isDragging: false,
    }));
    updatePosition(x, y, elementId);
  };

  // adds event listener and removes it based on isDragging
  useEffect(() => {
    if (state.isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [state.isDragging, handleMouseMove]); // eslint-disable-line

  return {
    handleMouseDownElement,
    handleMouseUpElement,
    elementTranslation: state.elementTranslation,
  };
};

export default useDraggableElement;
