import React from 'react';

const Copy = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7.5" y="1.5" width="9" height="13" rx="1" fill="#2C2C2C" stroke="white" />
      <rect x="3.5" y="5.5" width="9" height="13" rx="1" fill="#2C2C2C" stroke="white" />
    </svg>
  );
};

export default Copy;
