export const sortElementsByPON = (elements: Array<Object>) => {
  const sortedPONs: Array<Object> = elements
    .map(element => element.PON.split('.').map(PON => parseInt(PON, 10))) // eslint-disable-line
    .sort((a, b) => {
      // eslint-disable-next-line
      for (let i = 0; i < Math.max(a.length, b.length); i++) {
        if (!a[i]) return -1;
        if (!b[i]) return 1;
        if (a[i] - b[i] !== 0) return a[i] - b[i];
      }

      return 0;
    })
    .map(PON => PON.join('.'));

  const sortedElements: Array<Object> = sortedPONs.map(PON =>
    elements.find(element => element.PON === PON)
  );

  return sortedElements;
};

export const sortChildrenByPON = (childrenPON: Array<Object>) => {
  const sortedChildren: Array<Object> = childrenPON
    .map(PON => PON.split('.').map(PON => parseInt(PON, 10))) // eslint-disable-line
    .sort((a, b) => {
      // eslint-disable-next-line
      for (let i = 0; i < Math.max(a.length, b.length); i++) {
        if (!a[i]) return -1;
        if (!b[i]) return 1;
        if (a[i] - b[i] !== 0) return a[i] - b[i];
      }

      return 0;
    })
    .map(PON => PON.join('.'));

  return sortedChildren;
};
