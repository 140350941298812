import React from 'react';
import styled from 'styled-components';

// components
import Link from './Link';

type propTypes = {
  Icon: Function,
  color?: string,
  text?: string,
  disabled?: boolean,
  onClick?: Function,
  testId?: string,
  redirect?: string,
  className?: string,
  style?: Object,
  position?: string,
};

const ButtonWithIcon = ({
  testId,
  text,
  disabled,
  onClick,
  Icon,
  color,
  redirect,
  className,
  style,
  position,
}: propTypes) => {
  if (redirect) {
    return (
      <Link to={`/${redirect}`} tabIndex="-1">
        <Styles type="button" className={className} style={style}>
          {text}
          <Icon color={color} />
        </Styles>
      </Link>
    );
  }
  if (disabled) {
    return (
      <Styles
        data-testid={testId}
        type="button"
        className="disabled-button"
        disabled={disabled}
        style={style}
      >
        <Icon color={color} />
        {text}
      </Styles>
    );
  }

  if (position === 'right') {
    return (
      <Styles
        data-testid={testId}
        type="button"
        style={style}
        className={className}
        onClick={onClick}
      >
        {text}
        <Icon color={color} />
      </Styles>
    );
  }

  return (
    <Styles
      data-testid={testId}
      type="button"
      className={className}
      onClick={onClick}
      style={style}
    >
      <Icon color={color} />
      {text}
    </Styles>
  );
};

export default ButtonWithIcon;

ButtonWithIcon.defaultProps = {
  color: '',
  text: '',
  disabled: false,
  onClick: undefined,
  testId: '',
  redirect: undefined,
  className: '',
  style: {},
  position: '',
};

const Styles: any = styled.button`
  border: 0 !important;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    vertical-align: bottom;
    height: auto;
  }
`;
