import { useState, useEffect, useCallback } from 'react';
import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import { profileContext, configContext } from '../store/contexts';
import { SET_USER_COUNTS, SET_NOTIFICATION_CONFIG } from '../store/constants';

// utility
import usePostRequest from '../requestHooks/usePostRequest';
import useFreemium from './useFreemium';
import { decodePublicKey } from '../util/publicKey';
import useHistory from './useHistory';

export const useCopyBoard = () => {
  // profile store
  const [profileState, profileDispatch] = useStateValue(profileContext);
  const { userId } = profileState.user;

  // destructure post request
  const postRequest = usePostRequest();

  // destructure checkBoardCount
  const { checkBoardCount } = useFreemium();

  const history = useHistory();

  const copyBoard = async (boardId: number) => {
    const allowCopy = checkBoardCount();

    if (allowCopy) {
      const success = await postRequest(
        'global',
        'board',
        `/boards/copy/${boardId}/userId/${userId}`,
        {}
      );

      if (success) {
        const { data } = await Axios.get(`/users/${userId}/counts`);

        profileDispatch({ type: SET_USER_COUNTS, payload: data });
      }

      // Redirect to /dashboard after copy
      if (window.location.pathname.includes('/board')) history.push('/dashboard');
    }
  };

  return copyBoard;
};

export const useCopyBoardFromUrl = () => {
  const history = useHistory();

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { planId } = profileState.chargebee;

  // destructure copyBoard
  const copyBoard = useCopyBoard();

  // get pathname from url
  const { pathname } = window.location;

  // upon landing on /template-copy/:boardPublicKey -> trigger copy board function
  const [isCopyCompleted, setIsCopyCompleted] = useState(false);

  const copyBoardFromURL = useCallback(
    (publicBoardKey: string) => {
      // decode key
      const boardId = decodePublicKey(publicBoardKey);

      // initiate copy
      if (boardId !== 0) copyBoard(boardId);

      // key is invalid
      if (boardId === 0) {
        const notification = {
          notificationOpen: true,
          type: 'error',
          message: "We're unable to copy this board. 😢",
        };

        // trigger notification -- invalid board key, unable to copy
        configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });
      }

      // set copy process completed to initiate redirect
      setIsCopyCompleted(true);

      // clear boardKey from storage
      localStorage.removeItem('boardKey');

      // Redirect to /dashboard after copy
      history.push('/dashboard');
    },
    [configDispatch, copyBoard, history]
  );

  // effect to store key in local storage
  useEffect(() => {
    if (pathname.includes('/dashboard/template-copy/')) {
      localStorage.setItem('boardKey', pathname.slice(-10));
    }
  }, [pathname]);

  // effect to check for stored key and initiate board copy
  useEffect(() => {
    // get key from storage
    const publicBoardKey = localStorage.getItem('boardKey');

    if (!isCopyCompleted && publicBoardKey && planId) copyBoardFromURL(publicBoardKey);
  }, [isCopyCompleted, copyBoardFromURL, planId]);

  return { isCopyCompleted, copyBoardFromURL };
};
