import { useState } from 'react';
import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import {
  globalContext,
  elementContext,
  tableContext,
  configContext,
  profileContext,
} from '../store/contexts';

// dynamic get request utility
const useGetRequest = () => {
  // config store
  const [, configDispatch] = useStateValue(configContext); // eslint-disable-line

  // profile store
  const [, profileDispatch] = useStateValue(profileContext); // eslint-disable-line

  // global store
  const [, globalDispatch] = useStateValue(globalContext); // eslint-disable-line

  // element store
  const [, elementDispatch] = useStateValue(elementContext); // eslint-disable-line

  // table store
  const [, tableDispatch] = useStateValue(tableContext); // eslint-disable-line

  // request management boolean
  const [inProgress, setInProgress] = useState(false);
  const getRequest = async (store: string, resource: string, url: string) => {
    // uppercase table for dispatch
    const RESOURCE = resource.toUpperCase();

    // if request is in progress, block new request
    if (!inProgress) {
      try {
        // start get request
        setInProgress(true);
        eval(`${store}Dispatch`)({ type: `GET_${RESOURCE}_START` }); // eslint-disable-line

        // get data
        const { data } = await Axios.get(`${url}`);

        if (data) {
          // get success - dispatch new data to store
          setInProgress(false);
          eval(`${store}Dispatch`)({ type: `GET_${RESOURCE}_SUCCESS`, payload: data }); // eslint-disable-line
        }

        return data;
      } catch (error) {
        // stop get request
        setInProgress(false);

        // dispatch get failure
        eval(`${store}Dispatch`)({ type: `GET_${RESOURCE}_FAIL` }); // eslint-disable-line
        return false;
      }
    }

    // if request is already in progress - return false
    return false;
  };

  return getRequest;
};

export default useGetRequest;
