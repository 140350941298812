import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Button from './Button';

// data management
import { configContext } from '../../store/contexts';
import { SET_MODAL_CONFIG } from '../../store/constants';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  event: Function,
};

const DeleteButtons = ({ event }: propTypes) => {
  // config state
  const [, configDispatch] = useStateValue(configContext);

  const cancelDeletion = () => {
    // close delete modal
    configDispatch({ type: SET_MODAL_CONFIG, payload: { open: false } });
  };

  return (
    <Styles>
      <Button text="cancel" className="cancel-button" event={cancelDeletion} />

      <Button text="delete" className="delete-button" event={event} />
    </Styles>
  );
};

export default DeleteButtons;

const Styles: any = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    min-width: 180px;
    @media (max-width: 550px) {
      min-width: 120px;
    }
  }

  .cancel-button {
    background: ${colors.white};
    border: solid 1px ${colors.white};
    color: ${colors.purple};

    :hover {
      background: ${colors.palePurple};
      border: solid 1px ${colors.palePurple};
    }
  }
`;
