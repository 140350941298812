import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  testId: string,
  href: string,
  text: string,
};

const HelpButtons = ({ testId, href, text }: propTypes) => {
  return (
    <Styles>
      <a data-testid={testId} href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </Styles>
  );
};

export default HelpButtons;

const Styles: any = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  border: none;
  border-bottom: 1px solid ${colors.paleGrey};

  a {
    color: ${colors.purple};
    margin: 0;
    padding-left: 16px;

    :hover {
      color: ${colors.darkPurple};
      transition: 0.5s;
    }
  }
`;
