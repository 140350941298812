import React from 'react';

type propTypes = {
  color: string,
};

const SocialShareIcon = ({ color }: propTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="4" r="4" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7479 13H2.25195C3.14004 9.54955 6.27224 7 9.99992 7C13.7276 7 16.8598 9.54955 17.7479 13Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 15.5H5H6.5H9.09865C8.57994 14.6033 7.61042 14 6.5 14H4.5C2.84315 14 1.5 15.3431 1.5 17C1.5 18.6569 2.84315 20 4.5 20H6.5C7.61042 20 8.57994 19.3967 9.09865 18.5H6.5H5H4.5C3.67157 18.5 3 17.8284 3 17C3 16.1716 3.67157 15.5 4.5 15.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.999 15.5H15.499C16.3275 15.5 16.999 16.1716 16.999 17C16.999 17.8284 16.3275 18.5 15.499 18.5H14.999H13.499H10.9004C11.4191 19.3967 12.3886 20 13.499 20H15.499C17.1559 20 18.499 18.6569 18.499 17C18.499 15.3431 17.1559 14 15.499 14H13.499C12.3886 14 11.4191 14.6033 10.9004 15.5H13.499H14.999Z"
        fill={color}
      />
      <path
        d="M7 17L13 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SocialShareIcon;
