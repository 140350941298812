import { createContext } from 'react';

// $FlowFixMe
export const globalContext = createContext();
// $FlowFixMe
export const tableContext = createContext();
// $FlowFixMe
export const elementContext = createContext();
// $FlowFixMe
export const profileContext = createContext();
// $FlowFixMe
export const configContext = createContext();
