import React, { useState } from 'react';
import { useStateValue } from 'react-conflux/dist/cjs.min';
import styled from 'styled-components';

// components
import Button from '../../General/Button';
import Divider from '../../General/Divider';
import Header from './Header';
import ToggleSwitch from '../../General/ToggleSwitch';

// data management
import { globalContext, configContext } from '../../../store/contexts';
import { SET_MODAL_CONFIG, SET_NOTIFICATION_CONFIG } from '../../../store/constants';
import useUpdateBoardStatus from '../../../hooks/useUpdateBoardStatus';

// assets
import moreInfoIcon from '../../../assets/moreinfo-icon.svg';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  modalConfig: Object,
  closeModal: Function,
};

const ShareModal = ({ modalConfig, closeModal }: propTypes) => {
  const { boardId } = modalConfig;

  // global store
  const [globalState] = useStateValue(globalContext);
  const { boards, board } = globalState;

  // find the board has the same boardId of modalConfig out of boards
  const thisBoard = boards.find(elem => elem.boardId === boardId);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // destructure updatePublicStatus
  const { updatePublicStatus, updateCopyableStatus } = useUpdateBoardStatus();

  // set state for deactivation link
  const [showLink, setShowLink] = useState(false);

  // copy public URL to clipboard
  const copyToClipboard = () => {
    // $FlowFixMe
    navigator.clipboard.writeText(document.getElementById('public-url').textContent);
    const notification = {
      notificationOpen: true,
      message: 'link copied to clipboard',
      type: 'success',
    };

    // trigger link copied notification
    configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });

    closeModal();
  };

  const selectUrl = () => {
    window.getSelection().selectAllChildren(document.getElementById('public-url'));
  };

  const deactivateLink = () => {
    if (thisBoard === undefined) {
      updatePublicStatus(board);
    } else {
      // show deactivate notification modal
      updatePublicStatus(thisBoard);
      const notification = {
        notificationOpen: true,
        message: "You've deactivated the share link to this board. This board is now private.",
        type: 'deactivate',
      };
      configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });
    }

    configDispatch({ type: SET_MODAL_CONFIG, payload: { open: false } });
  };

  const toggleDeactivateDisplay = () => setShowLink(!showLink);

  const toggleIsCopyable = () => {
    if (thisBoard) updateCopyableStatus(thisBoard);
    if (!thisBoard) updateCopyableStatus(board);
  };

  return (
    <Styles>
      <div>
        <Header text="Share your BrainSquall" closeModal={closeModal} />
        <p className="description">
          You&apos;ve activated link sharing, anyone with this link will be able to view this board.
        </p>
        <div className="copy-board">
          <button
            type="button"
            id="public-url"
            onClick={selectUrl}
            style={{ fontFamily: 'inherit' }}
          >
            {thisBoard === undefined
              ? `https://create.brainsquall.co/board/${board.boardPublicKey}`
              : `https://create.brainsquall.co/board/${thisBoard.boardPublicKey}`}
          </button>
          <Button className="copy-button" type="button" text="Copy Link" event={copyToClipboard} />
        </div>

        <Divider style={{ margin: '30px 0 25px 0' }} />

        <div className="copy-toggle-section">
          <div>
            <p className="bold">Give viewers the option to copy this board.</p>
            <p className="description">
              If yes, anyone on the internet with this link will be able to copy this board.
            </p>
          </div>
          <ToggleSwitch
            isActive={thisBoard ? thisBoard.isCopyable : board.isCopyable}
            onClick={toggleIsCopyable}
          />
        </div>

        <button className="deactivate-link" type="button" onClick={toggleDeactivateDisplay}>
          Deactivate Link <img src={moreInfoIcon} alt="deactivate link" />
        </button>

        {showLink && (
          <div className="deactivate-section">
            <p className="deactivate-link-text">
              While this link is deactivated, the people you&apos;ve shared this link with
              won&apos;t have access to the board. You can reactivate the link to share.
            </p>
            <Button
              className="invert-button deactivate-button"
              text="Deactivate"
              event={deactivateLink}
            />
          </div>
        )}
      </div>
    </Styles>
  );
};

export default ShareModal;

const Styles: any = styled.div`
  display: flex;
  flex-direction: column;

  .copy-board {
    display: flex;
    background: ${colors.paleGrey};
    border-radius: 4px;
  }

  .copy-button {
    min-width: 110px;
    z-index: 1;

    @media (max-width: 550px) {
      min-width: 95px;
      font-size: 1.3rem;
    }
  }

  #public-url {
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    height: 35px;
    margin-top: 0 !important;
    padding: 7px;
    text-transform: none;
    white-space: nowrap;

    overflow: ellipsis;
    overflow-y: hidden;
    overflow-x: scroll;

    ::-webkit-scrollbar {
      display: none;
    }

    :hover {
      cursor: text;
    }
  }

  .copy-toggle-section {
    display: flex;
    justify-content: space-between;

    div {
      width: 350px;
    }
  }

  .deactivate-link {
    border: none;

    margin-top: 20px;
    padding: 0 !important;

    img {
      margin: 0 0 -1px 5px;
    }
  }

  .deactivate-section {
    display: flex;
    flex-direction: column;

    .deactivate-link-text {
      margin-top: 0 !important;
      font-size: 1.5rem;
      letter-spacing: 0.5px;
    }

    .deactivate-button {
      margin: 0 auto;
      width: 115px;

      :hover,
      :focus {
        background: ${colors.paleRed};
        border: 1px solid ${colors.warningRed};
        color: ${colors.warningRed};
      }
    }
  }
`;
