import React from 'react';
import styled from 'styled-components';

// utility
import { getMaxResources, convertPlanId } from '../../../util/freemium';

type propTypes = {
  chargebee: Object,
  userCounts: Object,
};

const PlanDetails = ({ chargebee, userCounts }: propTypes) => {
  const { planId, status } = chargebee;
  const { totalBoards, totalElements, addedLayers } = userCounts;
  const { boardMax, elementMax, addedLayerMax } = getMaxResources(planId, status);

  const currentPlan = convertPlanId(planId, status);

  const conditionalText = () => {
    if (currentPlan === 'enterprise') {
      return (
        <p>
          You are currently using the <span>{currentPlan}</span> Plan! This plan has no limits!
          <span role="img" aria-label="fire emoji">
            🔥
          </span>
        </p>
      );
    }

    return (
      <>
        <p>
          You are currently using the <span>{currentPlan}</span> Plan! Below is a list of your
          available resources. If you&apos;d like to upgrade your account to access more resources,
          upgrade your plan below.
        </p>

        <br />

        <p>
          <span>Boards Used:</span> {totalBoards} / {boardMax}
        </p>

        <p>
          <span>Elements Used:</span> {totalElements} / {elementMax}
        </p>

        <p>
          <span>Layers Used:</span> {addedLayers} / {addedLayerMax}
        </p>
      </>
    );
  };

  return (
    <Styles className="settings-container">
      <h3 className="container-title">Subscription Details</h3>

      <div className="container-content">{conditionalText()}</div>
    </Styles>
  );
};

export default PlanDetails;

const Styles: any = styled.div`
  span {
    text-transform: capitalize;
  }
`;
