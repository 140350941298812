import { useState } from 'react';
import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import {
  globalContext,
  elementContext,
  tableContext,
  profileContext,
  configContext,
} from '../store/contexts';

// dynamic post request utility
const usePostRequest = () => {
  // config store
  const [, configDispatch] = useStateValue(configContext); // eslint-disable-line

  // global store
  const [, globalDispatch] = useStateValue(globalContext); // eslint-disable-line

  // element store
  const [, elementDispatch] = useStateValue(elementContext); // eslint-disable-line

  // table store
  const [, tableDispatch] = useStateValue(tableContext); // eslint-disable-line

  // profile store
  const [, profileDispatch] = useStateValue(profileContext); // eslint-disable-line

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  const postRequest = async (
    store: string,
    resource: string,
    url: string,
    incomingPost: Object
  ) => {
    // uppercase table for dispatch
    const RESOURCE = resource.toUpperCase();

    // if request is in progress, block new request
    if (!inProgress) {
      try {
        // start post request
        setInProgress(true);
        eval(`${store}Dispatch`)({ type: `ADD_${RESOURCE}_START` }); // eslint-disable-line

        // post board
        const { data } = await Axios.post(`${url}`, incomingPost);

        if (data) {
          // post success - dispatch new data to store
          setInProgress(false);
          eval(`${store}Dispatch`)({ type: `ADD_${RESOURCE}_SUCCESS`, payload: data }); // eslint-disable-line
        }

        return data;
      } catch (error) {
        // stop post request
        setInProgress(false);

        // dispatch post failure
        eval(`${store}Dispatch`)({ type: `ADD_${RESOURCE}_FAIL` }); // eslint-disable-line
        return false;
      }
    }

    // if request is already in progress - return false
    return false;
  };

  return postRequest;
};

export default usePostRequest;
