import jwt from 'jsonwebtoken';

declare class process {
  static env: {
    REACT_APP_PK: string,
  };
}

// environment variables
const secret = process.env.REACT_APP_PK;

export const setLocalTokenizedStorage = (name: string, data: Object) => {
  // tokenize data and store in local storage.
  const tokenizedData = jwt.sign(data, secret);
  localStorage.setItem(name, tokenizedData);

  return true;
};

export const setLocalStorage = () => {};

export const getLocalStorage = () => {};
