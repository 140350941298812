import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../../../store/contexts';

type propTypes = {
  element: Object,
};

const Labels = ({ element }: propTypes) => {
  const { dataQualities } = element;

  // config store
  const [configState] = useStateValue(configContext);
  const { labelId } = configState.canvasConfig;

  // primary data quality
  const label = dataQualities.find(quality => quality.columnId === labelId);

  if (!label) return <></>;

  return (
    <Styles>
      <span>{label && label.title}</span>
    </Styles>
  );
};

export default Labels;

const Styles: any = styled.div`
  position: fixed;
  margin-left: 5px;

  span {
    font-size: 1.1rem;
  }
`;
