import React, { useState } from 'react';
import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';

import Axios from '../../config/axios_config';

// components
import Button from '../../components/General/Button';

// data management
import { globalContext, profileContext, configContext } from '../../store/contexts';
import {
  ADD_BOARD_START,
  ADD_BOARD_SUCCESS,
  ADD_BOARD_FAIL,
  SELECT_BOARD,
  SET_ACTIVE_LAYER_ID,
} from '../../store/constants';

// utility
import useGetRequest from '../../requestHooks/useGetRequest';
import useFreemium from '../../hooks/useFreemium';
import useHistory from '../../hooks/useHistory';

// environment variables
const secret = process.env.REACT_APP_PK;

const NewBoard = () => {
  const history = useHistory();

  // global store
  const [, globalDispatch] = useStateValue(globalContext);

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { user } = profileState;
  const { userId } = user;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // request management
  const [inProgress, setInProgress] = useState(false);

  // destructure request utility
  const getRequest = useGetRequest();

  // destructure checkBoardCount
  const { checkBoardCount } = useFreemium();

  const addBoard = async () => {
    const allowPost = checkBoardCount();

    if (!inProgress && allowPost) {
      try {
        // start request and state updates
        setInProgress(true);

        // add board to database
        globalDispatch({ type: ADD_BOARD_START });
        const board = await Axios.post(`/boards/user/${userId}`, { title: 'Untitled Board' });
        const { boardId, layerId } = board.data;
        // if board comes back, fetch elements and table data
        if (board) {
          // tokenize boardId and store in local storage for refresh reference.
          const tokenizedBoard = jwt.sign(board.data, secret);
          localStorage.setItem('board', tokenizedBoard);

          // send get request and set data to store
          const elementsData = await getRequest(
            'element',
            'initial_elements',
            `/elements/qualities/board/${boardId}/layer/${layerId}`
          );

          if (elementsData) {
            // send get request and set data to store
            const tableData = await getRequest('table', 'table', `/table/board/${boardId}`);

            if (tableData) {
              configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });
              globalDispatch({ type: ADD_BOARD_SUCCESS, payload: board.data });
              globalDispatch({ type: SELECT_BOARD, payload: board.data });

              history.push('/dashboard/board');
            }
          }
        }
      } catch (error) {
        globalDispatch({ type: ADD_BOARD_FAIL });
        setInProgress(false);
      }
    }
  };

  return <Button text="New Board" event={addBoard} style={{ minWidth: 115 }} />;
};

export default NewBoard;
