import freemiumConfig from '../config/freemium_config';

export const convertPlanId = (planId: string, status: string) => {
  // default to starter plan
  let plan = 'starter';

  if (status !== 'cancelled') {
    // convert planIds to plan for config access
    if (planId === 'personal-monthly' || planId === 'personal-annual') plan = 'personal';
    if (
      planId === 'beta-trial' ||
      planId === 'professional-monthly' ||
      planId === 'professional-annual' ||
      planId === 'alpha-pilot-annual'
    ) {
      plan = 'professional';
    }

    if (planId === 'internal-plan' || planId === 'enterprise') {
      plan = 'enterprise';
    }
  }

  return plan;
};

export const getMaxResources = (planId: string, status: string) => {
  const plan = convertPlanId(planId, status);

  // access plan config
  return freemiumConfig[plan];
};
