import React from 'react';
import styled from 'styled-components';

// components
import Input from './Input';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  label: string,
  value: string,
  setValue: Function,
  placeholder: string,
  handleBlur: Function,
  className?: string,
  id?: string,
  type?: string,
  testId?: string,
};

const InputWithLabel = ({
  label,
  value,
  setValue,
  placeholder,
  handleBlur,
  className,
  id,
  type,
  testId,
}: propTypes) => {
  return (
    <Styles htmlFor={label}>
      {label}
      <Input
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        handleBlur={handleBlur}
        className={className}
        id={id}
        type={type}
        testId={testId}
      />
    </Styles>
  );
};

export default InputWithLabel;

InputWithLabel.defaultProps = {
  className: '',
  id: '',
  type: 'text',
  testId: '',
};

const Styles: any = styled.label`
  input {
    width: 250px;
    margin: 0 0 5px 3px;
    border: 2px solid transparent;

    :focus {
      border: 2px solid ${colors.purple};
      border-radius: 3px;
    }
  }
`;
