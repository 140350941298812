import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Element from './Element';
import Qualities from '../Qualities';

// data management
import { configContext } from '../../../../store/contexts';

// utility
import useViewSync from '../../../../hooks/useViewSync';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  index: number,
  element: Object,
  updateElement: Function,
  tableWidth: number,
};

const ElementRow = ({ index, element, updateElement, tableWidth }: propTypes) => {
  const { dataQualities, elementId } = element;

  // config store
  const [configState] = useStateValue(configContext);
  const { activeEditId } = configState;

  const syncRef = useViewSync();

  return (
    <Styles
      style={{ width: tableWidth }}
      ref={syncRef}
      id={activeEditId === elementId ? 'table-ref' : null}
    >
      <Element rowIndex={index} element={element} updateElement={updateElement} />
      <Qualities rowIndex={index} dataQualities={dataQualities} elementId={elementId} />
    </Styles>
  );
};

export default ElementRow;

const Styles: any = styled.div`
  display: flex;
  height: 40px;
  padding: 0 15px;

  input {
    border: 3px solid transparent;
  }

  :nth-child(even) {
    background: ${colors.background};
  }
`;
