import React, { useState, useEffect } from 'react';
import { useStateValue } from 'react-conflux';

// components
import DragIcon from '../../../components/General/DragIcon';
import Title from './Title';
import Labels from './Labels';
import NewElementButtons from './NewElementButtons';

// data management
import { elementContext, configContext } from '../../../store/contexts';
import { UPDATE_ELEMENT_SUCCESS } from '../../../store/constants';

// utility
import useContextMenu from '../../../hooks/useContextMenu';
import useHighlightDeleting from '../../../hooks/useHighlightDeleting';
import { roundToNth } from '../../../util/canvasMath';
import useDetermineBackground from '../../../hooks/useDetermineBackground';
import useDraggableElement from '../../../hooks/useDraggableElement';
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  element: Object,
  updateElement: Function,
  elementTranslation: Object,
  setElementTranslation: Function,
};

const Element = ({
  element,
  updateElement,
  elementTranslation,
  setElementTranslation,
}: propTypes) => {
  const { elementId, parentId, xCoord, yCoord, title, PON } = element;

  // config store
  const [configState] = useStateValue(configContext);
  const { activeEditId, activeLayerId, activeDragId } = configState;

  // context menu
  const { openContextMenu } = useContextMenu();

  // element hover state
  const [elementHover, setElementHover] = useState(false);

  // set initial title from database, if title is updated, update the local title
  const [inputValue, setInputValue] = useState(title);

  // dynamically change width of field as title length changes
  const [inputWidth, setInputWidth] = useState(title.length * 7.3 + 50);

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { activeEdit, elements } = elementState;

  // when delete modal is open, get ids of elements on pending deletion branch.
  const { getPendingDeletion } = useHighlightDeleting();
  const pendingDeletion = getPendingDeletion();

  // destructure determine background
  const determineBackground = useDetermineBackground();

  // destructure request utility
  const update = useUpdateRequest();

  // element position
  const { x, y } = elementTranslation;

  // if element title updates on another component, sync local input values
  useEffect(() => {
    if (activeEdit.elementId === elementId) {
      setInputWidth(activeEdit.title.length * 7.3 + 70);
      setInputValue(activeEdit.title);
    }
  }, [activeEdit.title, activeEdit.elementId, elementId]);

  // update element position when element is dropped.
  const updatePosition = async (dropX, dropY, droppedElementId) => {
    // calculate drop position based on canvasConfig
    const safeDropX = roundToNth(dropX, 5);
    const safeDropY = roundToNth(dropY, 5);

    setElementTranslation({ x: safeDropX, y: safeDropY });

    if (elementId === droppedElementId) {
      // optimistically update element position for quick UI rendering
      elementDispatch({
        type: UPDATE_ELEMENT_SUCCESS,
        payload: { ...element, xCoord: safeDropX, yCoord: safeDropY },
      });
    }

    // send request and update store
    update('element', 'element', `/elements/${elementId}/layer/${activeLayerId}`, {
      xCoord: safeDropX,
      yCoord: safeDropY,
    });
  };

  const getForeignObjectWidth = () => {
    if (inputWidth + 40 < 170) return 170;

    return inputWidth + 40;
  };

  const { handleMouseDownElement, handleMouseUpElement } = useDraggableElement(
    xCoord,
    yCoord,
    setElementTranslation,
    updatePosition,
    elementId
  );

  return (
    <foreignObject
      x={elementId === activeDragId ? x : xCoord}
      y={elementId === activeDragId ? y : yCoord}
      onMouseDown={handleMouseDownElement}
      onMouseUp={handleMouseUpElement}
      height={80}
      width={getForeignObjectWidth()}
    >
      <div
        className={activeEditId === elementId ? 'active-element-canvas node' : 'node'}
        style={{
          width: inputWidth + 20,
          border: pendingDeletion.includes(elementId) && `2px solid ${colors.warningRed}`,
          background: determineBackground(element),
        }}
        onContextMenu={e => openContextMenu(e, elementId)}
        onMouseEnter={() => setElementHover(true)}
        onMouseLeave={() => setElementHover(false)}
      >
        <div>
          {parentId ? (
            <span className="pon-label">{PON.slice(2)}</span>
          ) : (
            <span className="pon-label" />
          )}
          <div className="icon-and-text">
            <DragIcon />
            <Title
              updateElement={updateElement}
              element={element}
              elements={elements}
              inputValue={inputValue}
              setInputValue={setInputValue}
              inputWidth={inputWidth}
              setInputWidth={setInputWidth}
            />
          </div>

          <Labels element={element} />
        </div>

        {/* element button to add new child element */}
        <NewElementButtons
          activeLayerId={activeLayerId}
          element={element}
          elementHover={elementHover}
          elementDispatch={elementDispatch}
          getForeignObjectWidth={getForeignObjectWidth}
        />
      </div>
    </foreignObject>
  );
};

export default Element;
