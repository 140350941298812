import React from 'react';
import styled from 'styled-components';

// component
import Button from '../../../General/Button';

// content
import { outroContent } from './surveyContent';

// asset
import DropdownCarrot from '../../../../assets/DropdownCarrot';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  handleSubmit: Function,
};

const OutroModal = ({ handleSubmit }: propTypes) => {
  return (
    <Styles>
      <h3>{outroContent.title}</h3>
      <p>{outroContent.description}</p>
      <Button
        Icon={DropdownCarrot}
        text="Done"
        className="survey-filled-button"
        color={colors.darkGrey}
        event={handleSubmit}
      />
    </Styles>
  );
};

export default OutroModal;

const Styles: any = styled.div`
  p {
    margin-bottom: 32px;
  }
`;
