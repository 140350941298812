// theme
import { colors } from '../styles/theme';

export const colorNames = [
  colors.userRed,
  colors.userPink,
  colors.userOrange,
  colors.userYellow,
  colors.white,
  colors.userPurple,
  colors.userBlue,
  colors.userGreen,
  colors.userLime,
  colors.paleGrey,
];

export const shapeOptions = [];
