import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  isActive: boolean,
  onClick: Function,
};

const ToggleSwitch = ({ isActive, onClick }: propTypes) => {
  return (
    <Styles htmlFor="copy-toggle" className="switch" onClick={onClick}>
      <input type="checkbox" checked={isActive} readOnly />
      <span className="slider round" />
      {isActive ? <p className="active-toggle">Yes</p> : <p className="inactive-toggle">No</p>}
    </Styles>
  );
};

export default ToggleSwitch;

const Styles: any = styled.label`
  /* switch - the box around the slider */
  position: relative;
  display: inline-block;
  min-width: 68px;
  height: 28px;

  :hover {
    cursor: pointer;
  }

  /* hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;

    :checked + .slider {
      background: ${colors.purple};
    }

    :focus + .slider {
      box-shadow: 0 0 1px ${colors.purple};
    }

    :checked + .slider:before {
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
    }
  }

  /* slider styling */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.mediumGrey};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    border-radius: 34px;

    :before {
      position: absolute;
      content: '';
      height: 22px;
      min-width: 22px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  p {
    position: absolute;
    top: 2px;
    color: ${colors.white};
  }

  .active-toggle {
    left: 13px;
  }

  .inactive-toggle {
    right: 15px;
  }
`;
