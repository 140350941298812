import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import { globalContext } from '../store/contexts';
import { UPDATE_BOARD_SUCCESS, UPDATE_BOARD_START, UPDATE_BOARD_FAIL } from '../store/constants';

// utility
import { setLocalTokenizedStorage } from '../util/localStorage';

const useUpdateBoardStatus = () => {
  // global store
  const [, globalDispatch] = useStateValue(globalContext);

  const updatePublicStatus = async (board: Object) => {
    const { boardId, isPublic } = board;

    globalDispatch({ type: UPDATE_BOARD_START });

    try {
      const { status } = await Axios.put(`/boardShare/${boardId}`, { isPublic: !isPublic });

      if (status === 200) {
        globalDispatch({
          type: UPDATE_BOARD_SUCCESS,
          payload: { ...board, boardId, isPublic: !isPublic },
        });

        // tokenize board and store in local storage for refresh reference.
        setLocalTokenizedStorage('board', { ...board, isPublic: !isPublic });
      }
    } catch (error) {
      globalDispatch({ type: UPDATE_BOARD_FAIL });
    }
  };

  const updateCopyableStatus = async (board: Object) => {
    const { boardId, isCopyable } = board;

    globalDispatch({ type: UPDATE_BOARD_START });

    try {
      const { status } = await Axios.put(`/boardShare/${boardId}`, { isCopyable: !isCopyable });

      if (status === 200) {
        globalDispatch({
          type: UPDATE_BOARD_SUCCESS,
          payload: { ...board, boardId, isCopyable: !isCopyable },
        });

        // tokenize board and store in local storage for refresh reference.
        setLocalTokenizedStorage('board', { ...board, isCopyable: !isCopyable });
      }
    } catch (error) {
      globalDispatch({ type: UPDATE_BOARD_FAIL });
    }
  };

  return { updatePublicStatus, updateCopyableStatus };
};

export default useUpdateBoardStatus;
