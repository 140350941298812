import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import BoardCard from './BoardCard';
import LoadingBoardCard from './LoadingBoardCard';

// data management
import { globalContext } from '../../store/contexts';

// theme
import { colors } from '../../styles/theme';

const BoardList = () => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { boards, addBoardIsLoading } = globalState;

  return (
    <Styles>
      {boards.map(board => {
        const { boardId } = board;

        return <BoardCard key={boardId} board={board} boards={boards} />;
      })}

      {addBoardIsLoading && <LoadingBoardCard />}
    </Styles>
  );
};

export default BoardList;

const Styles: any = styled.div`
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;

  .deletion-card {
    box-shadow: ${colors.boxShadow};
    border: solid 2px ${colors.lightRed};
    background: ${colors.paleRed};
    border-radius: 10px;
  }
`;
