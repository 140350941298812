import { useFindParentElement } from './useFindElement';

const useFilterChildren = () => {
  // destructure utility
  const [findParentElement] = useFindParentElement();

  // when element is removed, remove PON from parent's children array
  const filterChildren = (element: Object) => {
    // find parent element
    const parent = findParentElement(element);

    const updatedChildrenPON = parent.childrenPON.filter(childPON => childPON !== element.PON);

    return updatedChildrenPON;
  };

  return [filterChildren];
};

export default useFilterChildren;
