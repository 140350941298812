import React from 'react';

type propTypes = {
  value: string,
  setValue: Function,
  placeholder: string,
  handleBlur: Function,
  className?: string,
  id?: string,
  type?: string,
  testId?: string,
  ariaLabel?: string,
};

const Input = ({
  value,
  setValue,
  placeholder,
  handleBlur,
  type,
  className,
  id,
  testId,
  ariaLabel,
}: propTypes) => {
  return (
    <input
      id={id}
      className={className}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleBlur}
      placeholder={placeholder}
      onKeyDown={e => {
        if (e.keyCode === 27) e.target.blur(); // escape key
        if (e.keyCode === 13) e.target.blur(); // return key
      }}
      type={type}
      data-testid={testId}
      aria-label={ariaLabel}
    />
  );
};

export default Input;

Input.defaultProps = {
  className: '',
  id: '',
  type: 'text',
  testId: '',
  ariaLabel: '',
};
