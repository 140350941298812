import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ReadOnlyElementRow from './ElementRow';

// data management
import { elementContext } from '../../../store/contexts';

type propTypes = {
  tableWidth: number,
};

const ReadOnlyElements = ({ tableWidth }: propTypes) => {
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  return (
    <Styles>
      {elements.map((element, index) => {
        const { elementId } = element;
        return (
          <ReadOnlyElementRow
            key={elementId}
            index={index}
            element={element}
            tableWidth={tableWidth}
          />
        );
      })}
    </Styles>
  );
};

export default ReadOnlyElements;

const Styles: any = styled.div``;
