import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// components
import AccountMenu from './AccountMenu';
import ButtonWithIcon from '../../General/ButtonWithIcon';

// assets
import accountIcon from '../../../assets/AccountIcon';

type propTypes = {}; // eslint-disable-line

const AccountIcon = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // open / close menu helper functions
  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [setMenuOpen, menuOpen]);

  // attach close menu listeners when help menu is opened
  useEffect(() => {
    if (menuOpen) document.addEventListener('click', toggleMenu, false);

    return () => document.removeEventListener('click', toggleMenu, false);
  }, [toggleMenu, menuOpen]);

  return (
    <Styles>
      <ButtonWithIcon Icon={accountIcon} onClick={toggleMenu} />

      <AccountMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </Styles>
  );
};

export default AccountIcon;

const Styles: any = styled.div`
  button {
    outline: none;
    border: 0;

    img {
      height: 21px;
      width: 15px;
    }
  }
`;
