import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// component
import Close from '../../../assets/Close';

// assets
import successCheck from '../../../assets/success-check.png';
import warning from '../../../assets/warning.png';

// data management
import { configContext } from '../../../store/contexts';
import { SET_NOTIFICATION_CONFIG } from '../../../store/constants';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {}; // eslint-disable-line

const NotificationModal = () => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { notificationOpen, message, type } = configState.notificationConfig;

  // timer to automatically close notification modal
  useEffect(() => {
    const timeoutId = setTimeout(
      () => configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: { notificationOpen: false } }),
      5000
    );

    return () => clearTimeout(timeoutId);
  }, [notificationOpen, configDispatch]);

  const closeNotification = () => {
    configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: { notificationOpen: false } });
  };

  // return error notification modal
  if (type === 'error') {
    return (
      <Styles className={notificationOpen ? 'fade-in error-notification' : 'error-notification'}>
        <img src={warning} alt="warning icon" style={{ width: '24px', height: '24px' }} />
        <p>{message}</p>
        <Close color={colors.warningRed} event={closeNotification} />
      </Styles>
    );
  }

  // return success notification modal
  if (type === 'success') {
    return (
      <Styles
        className={notificationOpen ? 'fade-in success-notification' : 'success-notification'}
      >
        <img src={successCheck} alt="success check" style={{ width: '24px', height: '24px' }} />
        <p>{message}</p>
        <Close color={colors.successGreen} event={closeNotification} />
      </Styles>
    );
  }

  // return deactivate notification modal
  if (type === 'deactivate') {
    return (
      <Styles
        className={notificationOpen ? 'fade-in deactivate-notification' : 'deactivate-notification'}
      >
        <p>{message}</p>
        <Close color={colors.white} event={closeNotification} />
      </Styles>
    );
  }

  return <></>;
};

export default NotificationModal;

const Styles: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: calc(50% - 32px);
  left: calc(50% - 206px);

  height: 64px;
  width: 412px;
  padding: 0 20px;

  border-radius: 6px;

  font-size: 1.5rem;
  z-index: 2;

  @media (max-width: 550px) {
    width: 300px;
    bottom: calc(50% - 60px);
    left: calc(50% - 150px);
  }

  a {
    margin-top: 10px;
  }
`;
