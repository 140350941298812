import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import Axios from '../../config/axios_config';

// data management
import { elementContext, configContext } from '../../store/contexts';
import { UPDATE_ELEMENT_SUCCESS } from '../../store/constants';

// utility
import { colorNames } from '../../config/element_config';
import usePostRequest from '../../requestHooks/usePostRequest';

// theme
import { colors } from '../../styles/theme';

// asset
import check from '../../assets/check-mark.svg';

type propTypes = {
  elementId?: number,
};

const ColorQuality = ({ elementId }: propTypes) => {
  // config store
  const [configState] = useStateValue(configContext);
  const { activeLayerId } = configState;

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { elements } = elementState;

  // get colorId for element
  const element = elements.find(el => el.elementId === elementId);
  const { configQuality } = element;

  // destructure request utility
  const postRequest = usePostRequest();

  const updateElementColor = async (colorIndex: number) => {
    // send request and update store
    const { data } = await Axios.put(`/configQualities/${configQuality.configQualityId}`, {
      colorId: colorIndex,
    });

    elementDispatch({ type: UPDATE_ELEMENT_SUCCESS, payload: { ...element, configQuality: data } });
  };

  const addElementColor = async (colorIndex: number) => {
    // send post request and set data to store
    const data = await postRequest('element', 'simple_quality', `/configQualities`, {
      elementId,
      layerId: activeLayerId,
      colorId: colorIndex,
    });

    elementDispatch({ type: UPDATE_ELEMENT_SUCCESS, payload: { ...element, configQuality: data } });
  };

  const handleElementColor = async (colorIndex: number) => {
    if (configQuality && configQuality.configQualityId) {
      updateElementColor(colorIndex);
    } else {
      addElementColor(colorIndex);
    }
  };

  return (
    <Styles>
      {colorNames.map((color, colorIndex) => {
        return (
          // eslint-disable-next-line
          <button
            key={color}
            type="button"
            className="color"
            style={{ background: color }}
            onClick={() => handleElementColor(colorIndex)}
          >
            {configQuality && configQuality.colorId === colorIndex && (
              <img className="check-mark" src={check} alt="selected color" />
            )}
            {!configQuality && color === '#ffffff' && (
              <img className="check-mark" src={check} alt="selected color" />
            )}
          </button>
        );
      })}
    </Styles>
  );
};

export default ColorQuality;

ColorQuality.defaultProps = {
  elementId: undefined,
};

const Styles: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border-top: 0.5px solid ${colors.paleGrey};

  width: 132px;
  height: 62px;

  padding: 8px 0;

  .color {
    position: relative;
    border: 0.5px solid ${colors.lightGrey} !important;
    border-radius: 50% !important;

    width: 19px;
    min-width: 0;
    height: 19px;

    margin-right: 4px;
    padding: 0;

    :nth-child(5),
    :nth-child(10) {
      margin-right: 0;
    }

    :hover {
      box-shadow: 0 2px 4px 0 ${colors.lightGrey};
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.3s;
    }
  }

  .check-mark {
    position: absolute;
    top: 4px;
    left: 3px;
  }
`;
