// $FlowFixMe
const Hashids = require('hashids/cjs');

const hashId = new Hashids(process.env.REACT_APP_SALT, 10);
// Generate Public Key from boardId + salt
export const generatePublicKey = (boardId: number) => {
  const publicKey = hashId.encode(boardId);

  return publicKey;
};

// confirm key length and string only contains characters and number values
export const validatePublicKey = (publicKey: string) => {
  const letters = /^[0-9a-zA-Z]+$/;

  if (!publicKey.match(letters) || !publicKey.length === 10) return false;

  return true;
};

// Decode Public Key and get the board ID from public url
export const decodePublicKey = (publicKey: string) => {
  // return 0 if key doesn't pass validation
  if (!validatePublicKey(publicKey)) return 0;

  const boardId = +hashId.decode(publicKey);
  return boardId;
};
