import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Header from './Header';
import TextDisplay from './TextDisplay';

// data management
import { elementContext, configContext } from '../../../store/contexts';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  element: Object,
  inputValue: string,
};

const TutorialDropdown = ({ element, inputValue }: propTypes) => {
  const { elementId, PON } = element;

  // config store
  const [configState] = useStateValue(configContext);
  const { activeView, activeEditId } = configState;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // tutorial open/close state management
  const [tutorialOpen, setTutorialOpen] = useState(false);

  useEffect(() => {
    if (activeView === 'outline') {
      if (activeEditId === elementId && inputValue !== '') setTutorialOpen(true);
      if (activeEditId !== elementId || inputValue === '' || elements.length > 3) {
        setTutorialOpen(false);
      }
    }
  }, [activeEditId, elementId, inputValue, elements.length, activeView]);

  // conditionally return the proper tutorial based on current element
  const renderTutorial = () => {
    if (PON === '1') return <TextDisplay keyOne="Enter ↵" text="to make a child (1) 👶" />;
    if (PON === '1.1') return <TextDisplay keyOne="Enter ↵" text="to make a sibling (2) 👯" />;
    if (PON === '1.1.1') {
      return <TextDisplay keyOne="Shift ↑" keyTwo="Tab ↹" text="to turn into a sibling (2) 👯" />;
    }
    if (PON === '1.2') {
      return (
        <>
          <TextDisplay keyOne="Tab ↹" text="to turn into a grandchild (1.1)  👶 " />
          <TextDisplay keyOne="Shift ↑" keyTwo="Enter ↵" text="to add text 🗒️" />
        </>
      );
    }
    return <></>;
  };
  if (tutorialOpen && (PON === '1' || PON === '1.1' || PON === '1.1.1' || PON === '1.2')) {
    return (
      <Styles>
        <Header setTutorialOpen={setTutorialOpen} />

        {renderTutorial()}
      </Styles>
    );
  }
  // default to return empty fragment
  return <></>;
};
export default TutorialDropdown;
const Styles: any = styled.div`
  display: flex;
  flex-direction: column;

  width: 448px;
  padding: 15px;
  margin-left: 40px;

  position: fixed;
  z-index: 10;

  background: ${colors.white};
  border: solid 1px ${colors.paleGrey};
  border-radius: 4px;
  box-shadow: ${colors.boxShadow};
`;
