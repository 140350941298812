import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import TextareaAutosize from 'react-textarea-autosize';

// data management
import { configContext } from '../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../store/constants';

// utility
import focusInputId from '../../../util/focusInputId';
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';
import useDeleteRequest from '../../../requestHooks/useDeleteRequest';

type propTypes = {
  textQuality: Object,
  element: Object,
};

const Quality = ({ textQuality, element }: propTypes) => {
  const { elementId } = element;
  const { qualityId } = textQuality;

  const [text, setText] = useState(textQuality.text);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // destructure request utility
  const update = useUpdateRequest();
  const deleteRequest = useDeleteRequest();

  // update quality on change
  const updateTextQuality = e => {
    setText(e.target.value);

    // send request and update store
    update('element', 'quality', `/qualities/${qualityId}`, { text: e.target.value });
  };

  // delete textQuality from element
  const deleteTextQuality = e => {
    e.preventDefault();
    const success = deleteRequest('element', 'quality', '/qualities', qualityId);

    // focus on parent input
    if (success) focusInputId(`outline${elementId}`);
  };

  return (
    <Styles>
      <TextareaAutosize
        data-testid={`textQuality${qualityId}`}
        id={`textQuality${qualityId}`}
        aria-label={text || 'empty text quality'}
        value={text}
        type="text"
        placeholder="Text qualities hold large amounts of text."
        onChange={e => updateTextQuality(e)}
        onKeyDown={e => {
          if (text === '' && e.keyCode === 8) deleteTextQuality(e); // delete key
          if (e.keyCode === 27) e.target.blur(); // escape key
        }}
        onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'outline' })}
      />
    </Styles>
  );
};

export default Quality;

const Styles: any = styled.div`
  textarea {
    width: 100%;
    max-width: 100%;
    border: 0;
    resize: none;
    overflow: hidden;
  }
`;
