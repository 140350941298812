import React from 'react';
import styled from 'styled-components';

// components
import AdjustWidth from './AdjustWidth';

type propTypes = {
  title: string,
  boardId: number,
  currentPosition: number,
  setCurrentPosition: Function,
  elementColumnWidth: number,
  setElementColumnWidth: Function,
  isHeld: boolean,
  setIsHeld: Function,
  isHidden: boolean,
};

const FixedTitleColumn = ({
  title,
  boardId,
  currentPosition,
  setCurrentPosition,
  elementColumnWidth,
  setElementColumnWidth,
  isHeld,
  setIsHeld,
  isHidden,
}: propTypes) => {
  return (
    <Styles>
      <div
        className="cell"
        style={{
          minWidth: elementColumnWidth < 100 ? 100 : elementColumnWidth,
          width: elementColumnWidth < 100 ? 100 : elementColumnWidth,
        }}
      >
        <p>{title}</p>
      </div>

      <AdjustWidth
        boardId={boardId}
        currentPosition={currentPosition}
        setCurrentPosition={setCurrentPosition}
        elementColumnWidth={elementColumnWidth}
        setElementColumnWidth={setElementColumnWidth}
        isHeld={isHeld}
        setIsHeld={setIsHeld}
        isHidden={isHidden}
      />
    </Styles>
  );
};

export default FixedTitleColumn;

const Styles: any = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'SofiaProBold';
  }
`;
