import React from 'react';
import styled from 'styled-components';

type propTypes = {
  column: Object,
};

const ReadOnlyColumn = ({ column }: propTypes) => {
  const { width, columnType, title } = column;

  return (
    <Styles
      className="cell"
      style={{ width: width < 80 ? 80 : width, minWidth: width < 80 ? 80 : width }}
    >
      {columnType === 'text' ? (
        <p className="readOnlyTableTitle">{title}</p>
      ) : (
        <p className="capitalize">{columnType}</p>
      )}
    </Styles>
  );
};

export default ReadOnlyColumn;

const Styles: any = styled.div`
  .readOnlyTableTitle {
    overflow: hidden !important;
    white-space: nowrap !important;
    word-wrap: break-word !important;
    border-right: 1em solid transparent;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;
