import React from 'react';
import styled from 'styled-components';

type propTypes = {
  textQuality: Object,
};

const ReadOnlyQuality = ({ textQuality }: propTypes) => {
  const { text } = textQuality;

  return <Styles>{text}</Styles>;
};

export default ReadOnlyQuality;

const Styles: any = styled.p`
  width: 100%;
  max-width: 100%;
  border: 0;
  resize: none;
  overflow: hidden;
`;
