import { css } from 'styled-components';

// theme
import { colors } from './theme';

export default css`
  * {
    box-sizing: border-box;
  }

  html {
    /* 1rem === 10px */
    font-size: 62.5%;
  }

  html,
  body {
    scroll-behavior: smooth;
    font-family: 'SofiaPro', 'Helvetica', sans-serif;
    background: ${colors.white};
    color: ${colors.darkGrey};
    cursor: default;
    margin: 0;
    padding: 0;

    @media (max-width: 800px) {
      width: 100%;
      margin: 0;
    }
  }

  h1 {
    font-size: 2.2rem;
    letter-spacing: 1.25px;
    line-height: 1.25;
    margin-bottom: 15px;

    @media (max-width: 800px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 2rem;
    word-spacing: 3px;
    margin-bottom: 15px;
    line-height: 1.2;

    @media (max-width: 800px) {
      font-size: 2.4rem;
    }
  }

  h3 {
    font-size: 1.8rem;
    padding-bottom: 10px;
    line-height: 3rem;

    @media (max-width: 800px) {
      font-size: 1.8rem;
    }
  }

  /* h4 used for footer */
  h4 {
    font-size: 1.6rem;
    letter-spacing: 1px;
    padding-bottom: 5px;
  }

  /* h5 user in nav bar */
  h5 {
    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  h6 {
    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  p,
  label,
  span,
  input,
  textarea,
  option,
  select,
  button,
  a {
    font-size: 1.5rem;
    font-family: inherit;

    :focus {
      outline: none;
    }
  }

  p,
  label {
    line-height: 1.5;
    letter-spacing: 0.5px;
    margin-bottom: 10px;

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  a {
    text-decoration: none;
    color: ${colors.darkGrey};

    :hover {
      color: ${colors.mediumGrey};
      transition: 0.3s;
      cursor: pointer;
    }
  }

  input,
  textarea {
    line-height: 1.5;
    letter-spacing: 0.6px;
    border: none;
    background: white;
  }

  .bold {
    font-family: 'SofiaProBold', 'Helvetica', sans-serif;
  }

  /* error notification styling */
  .error-notification,
  .success-notification,
  .deactivate-notification {
    p {
      margin-bottom: 0;
      width: 80%;
      font-size: 15px;
    }

    svg {
      width: unset;
      cursor: pointer;
    }
  }

  .error-notification {
    color: ${colors.warningRed};
    border: 1px solid ${colors.warningRed};
    background: ${colors.paleRed};
  }

  .success-notification {
    color: ${colors.successGreen};
    border: 1px solid ${colors.successGreen};
    background: ${colors.paleGreen};
  }

  .deactivate-notification {
    color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    background: ${colors.darkGrey};
  }

  /* notification modal animations */
  .fade-in {
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
    -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
    animation: fadein 0.5s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* context menu styling */
  .context-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 5px;

    background: ${colors.background};
    border-radius: 4px;
    box-shadow: 0px 2px 4px ${colors.lightGrey};
    z-index: 3;
    margin-left: -130px;

    a:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    button,
    li {
      font-family: 'SofiaPro', 'Helvetica', sans-serif;
      font-size: 1.5rem;
      text-align: left;

      min-width: 110px;

      width: 100%;
      height: 40px;
      background: ${colors.white};

      border: solid 1px ${colors.paleGrey};
      border-bottom: none;
      border-radius: 0;

      z-index: 10;

      :first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      :last-child {
        border: 1px solid ${colors.paleGrey};
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      :focus {
        border: solid 1px ${colors.paleGrey};
        border-bottom: none;

        :last-child {
          border-bottom: solid 1px ${colors.paleGrey};
        }
      }
    }
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
`;
