import React from 'react';

type propTypes = {
  color: string,
  event: Function,
};

const Close = ({ color, event }: propTypes) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={event}
    >
      <path d="M10.5 1.5L1.5 10.5" stroke={color} strokeWidth="2" />
      <path d="M1.5 1.5L10.5 10.5" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Close;
