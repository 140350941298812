import ReactGA from 'react-ga';

declare class process {
  static env: {
    REACT_APP_ENV: string,
    REACT_APP_PROD_GA_TRACKING_ID: string,
    REACT_APP_DEV_GA_TRACKING_ID: string,
  };
}

const trackingId =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_GA_TRACKING_ID
    : process.env.REACT_APP_DEV_GA_TRACKING_ID;

export const InitializeGA = () => {
  ReactGA.initialize(trackingId);
};

export const logPageView = (pathname: string) => {
  // Sets Page
  ReactGA.ga({ page: pathname });
  // Records pageview that user is currently accessing | Also tracks locations entered manually into browser URL.
  ReactGA.pageview(pathname);
};

// Tracks where a user clicks a button.
export const TrackButtonPress = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};
