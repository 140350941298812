import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  style?: Object,
};

const Divider = ({ style }: propTypes) => <Styles style={style} />;

export default Divider;

Divider.defaultProps = {
  style: {},
};

const Styles: any = styled.div`
  margin: 20px auto 30px auto;
  background: ${colors.paleGrey};
  height: 1px;
`;
