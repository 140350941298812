import { useEffect } from 'react';
import { useStateValue } from 'react-conflux';
// data management
import { globalContext, profileContext } from '../store/contexts';
import { SET_INITIAL_BOARD_LOADING } from '../store/constants';

// utility
import useGetRequest from '../requestHooks/useGetRequest';

const useGetBoards = () => {
  // global store
  const [globalState, globalDispatch] = useStateValue(globalContext);
  const { boards, initialBoardLoading } = globalState;

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { userId } = profileState.user;

  // destructure request utility
  const getRequest = useGetRequest();

  // request boards when user has loaded
  useEffect(() => {
    const getBoards = async () => {
      // send get request and set data to store
      if (boards.length === 0) await getRequest('global', 'boards', `/boards/user/${userId}`);
    };
    if (userId && initialBoardLoading) {
      getBoards();
      globalDispatch({ type: SET_INITIAL_BOARD_LOADING });
    }
  }, [userId, getRequest, initialBoardLoading, globalDispatch, boards.length]);
};

export default useGetBoards;
