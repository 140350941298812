import React from 'react';

// theme
import { colors } from '../styles/theme';

const Delete = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="12"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99988 16C4.99988 16.9167 5.74988 17.6667 6.66654 17.6667H13.3332C14.2499 17.6667 14.9999 16.9167 14.9999 16V6H4.99988V16ZM15.8332 3.33333H12.9165L12.0832 2.5H7.9165L7.08317 3.33333H4.1665V5H15.8332V3.33333Z"
          fill={colors.white}
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="-10.8335" y="-10.833" width="41.6667" height="41.6667" fill={colors.darkGrey} />
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="-11"
          y="-11"
          width="42"
          height="42"
        >
          <rect x="-10.8335" y="-10.833" width="41.6667" height="41.6667" fill={colors.white} />
        </mask>
        <g mask="url(#mask1)" />
      </g>
    </svg>
  );
};

export default Delete;
