import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { globalContext } from '../../../store/contexts';

// components
import ReadOnlyElement from './Element';
import ReadOnlyQualities from './Qualities';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  index: number,
  element: Object,
  tableWidth: number,
};

const ReadOnlyElementRow = ({ index, element, tableWidth }: propTypes) => {
  const { dataQualities, elementId } = element;

  // global store
  const [globalState] = useStateValue(globalContext);
  const { elementWidth, ponWidth } = globalState.board;

  return (
    <Styles style={{ width: tableWidth }}>
      <ReadOnlyElement
        rowIndex={index}
        element={element}
        elementWidth={elementWidth}
        ponWidth={ponWidth}
      />
      <ReadOnlyQualities rowIndex={index} dataQualities={dataQualities} elementId={elementId} />
    </Styles>
  );
};

export default ReadOnlyElementRow;

const Styles: any = styled.div`
  display: flex;
  height: 40px;
  padding: 0 15px;

  input {
    border: 3px solid transparent;
  }

  :nth-child(even) {
    background: ${colors.background};
  }
`;
