import React from 'react';
import styled from 'styled-components';

// assets
import closeIcon from '../../../assets/close-50.png';

type propTypes = {
  setTutorialOpen: Function,
};

const Header = ({ setTutorialOpen }: propTypes) => {
  return (
    <Styles>
      <h3>Getting Started</h3>
      <button type="button" className="close-modal" onClick={() => setTutorialOpen(false)}>
        <img src={closeIcon} alt="close delete modal" />
      </button>
    </Styles>
  );
};

export default Header;

const Styles: any = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  h3 {
    padding: 0;
    font-weight: bold;
  }

  .close-modal {
      border: 0;
      padding: 0;

      img {
        width: 10px;
        height: 10px;
      }
`;
