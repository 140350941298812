import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { elementContext, configContext } from '../../../../store/contexts';
import {
  SET_TITLE,
  SET_HIGHLIGHT,
  SET_MENU_TARGET,
  SET_ACTIVE_VIEW,
} from '../../../../store/constants';

// utility
import focusInputId from '../../../../util/focusInputId';
import { useFindNextElement, useFindPreviousElement } from '../../../../hooks/useFindElement';
import useHighlightDeleting from '../../../../hooks/useHighlightDeleting';
import useDetermineBackground from '../../../../hooks/useDetermineBackground';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  rowIndex: number,
  element: Object,
  elements: Array<Object>,
  inputValue: string,
  setInputValue: Function,
  updateElement: Function,
  elementWidth: number,
};

const Title = ({
  rowIndex,
  element,
  elements,
  inputValue,
  setInputValue,
  updateElement,
  elementWidth,
}: propTypes) => {
  const { elementId } = element;
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeEditId } = configState;

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { activeEdit, lastElement } = elementState;

  // destructure utility functions from hooks
  const [findPreviousElement] = useFindPreviousElement();
  const [findNextElement] = useFindNextElement();

  const next = findNextElement(rowIndex);
  const previous = findPreviousElement(rowIndex);

  // when delete modal is open, get ids of elements on pending deletion branch.
  const { getPendingDeletion } = useHighlightDeleting();
  const pendingDeletion = getPendingDeletion();

  // destructure determine background
  const determineBackground = useDetermineBackground();

  return (
    <Styles
      data-testid={`table${elementId}`}
      id={`table${elementId}`}
      type="text"
      className={activeEditId === elementId ? 'active-element-table' : null}
      style={{
        border: pendingDeletion.includes(elementId) && `2px solid ${colors.warningRed}`,
        background: determineBackground(element),
        width: elementWidth < 100 ? 110 : elementWidth + 10 || null,
        minWidth: elementWidth < 100 ? 110 : elementWidth + 10 || null,
      }}
      value={(activeEdit.elementId === elementId && activeEdit.title) || inputValue}
      aria-label={inputValue || 'empty table element'}
      placeholder={elements[0].elementId === elementId ? 'Or even here...' : ''}
      onFocus={() => {
        configDispatch({ type: SET_HIGHLIGHT, payload: elementId });
        configDispatch({ type: SET_MENU_TARGET, payload: elementId });
        configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' });
      }}
      onBlur={() => {
        updateElement(activeEdit);
        configDispatch({ type: SET_HIGHLIGHT, payload: undefined });
      }}
      onChange={e => {
        setInputValue(e.target.value);
        elementDispatch({ type: SET_TITLE, payload: { elementId, title: e.target.value } });
      }}
      onKeyDown={e => {
        if (e.keyCode === 27) e.target.blur(); // escape key
        if (e.keyCode === 13 && next) focusInputId(`table${next.elementId}`); // return key
        if (e.keyCode === 38 && previous) focusInputId(`table${previous.elementId}`); // up arrow
        if (e.keyCode === 40 && next) focusInputId(`table${next.elementId}`); // down arrow
        if ((e.keyCode === 13 || e.keyCode === 40) && elementId === lastElement.elementId) {
          focusInputId('empty-table-input'); // return key & down key
        }
      }}
    />
  );
};

export default Title;

const Styles: any = styled.input``;
