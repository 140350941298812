import React from 'react';
import styled from 'styled-components';

// components
import Loader from '../../components/General/Loader';

// theme
import { colors } from '../../styles/theme';

const LoadingBoardCard = () => {
  return (
    <Styles>
      <Loader />
    </Styles>
  );
};

export default LoadingBoardCard;

const Styles: any = styled.div`
  min-width: 312px;
  max-width: 312px;
  height: 222px;

  border-radius: 8px;
  border: solid 1px ${colors.paleGrey};
  box-shadow: ${colors.boxShadow};
`;
