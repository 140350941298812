import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Column from './Column';
import FixedTitleColumn from './FixedTitleColumn';
import EmptyTextColumn from './EmptyTextColumn';
import PONColumnTitle from './PONColumnTitle';

// data management
import { tableContext, elementContext, globalContext } from '../../../../store/contexts';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  tableWidth: number,
  isHeld: boolean,
  setIsHeld: Function,
  activeId: number,
  setActiveId: Function,
};

const Columns = ({ tableWidth, isHeld, setIsHeld, activeId, setActiveId }: propTypes) => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { boardId, elementWidth, ponWidth } = globalState.board;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // track mouse while adjusting column width
  const [currentPosition, setCurrentPosition] = useState(0);

  // track element  and pon column widths
  const [elementColumnWidth, setElementColumnWidth] = useState(elementWidth);
  const [ponColumnWidth, setPONColumnWidth] = useState(ponWidth);

  // track show/hide for column adjustment
  const [isHidden, setIsHidden] = useState(true);

  const mouseLeave = () => {
    setIsHeld(false);
    setIsHidden(true);
  };

  return (
    <Styles
      style={{ width: tableWidth }}
      onMouseMove={e => {
        if (e.buttons === 1) setCurrentPosition(e.pageX);
      }}
      onMouseEnter={() => setIsHidden(false)}
      onMouseLeave={mouseLeave}
    >
      {elements.length > 0 && (
        <div className="column-titles">
          <FixedTitleColumn
            title="Element"
            boardId={boardId}
            currentPosition={currentPosition}
            setCurrentPosition={setCurrentPosition}
            elementColumnWidth={elementColumnWidth}
            setElementColumnWidth={setElementColumnWidth}
            isHeld={isHeld}
            setIsHeld={setIsHeld}
            isHidden={isHidden}
          />

          <PONColumnTitle
            boardId={boardId}
            currentPosition={currentPosition}
            setCurrentPosition={setCurrentPosition}
            ponColumnWidth={ponColumnWidth}
            setPONColumnWidth={setPONColumnWidth}
            isHeld={isHeld}
            setIsHeld={setIsHeld}
            isHidden={isHidden}
          />

          {columns.map((column, index) => {
            const { columnId } = column;

            return (
              <Column
                key={columnId}
                column={column}
                currentPosition={currentPosition}
                setCurrentPosition={setCurrentPosition}
                columnIndex={index}
                isHeld={isHeld}
                setIsHeld={setIsHeld}
                activeId={activeId}
                setActiveId={setActiveId}
                isHidden={isHidden}
                setIsHidden={setIsHidden}
              />
            );
          })}
        </div>
      )}

      <EmptyTextColumn columns={columns} />
    </Styles>
  );
};

export default Columns;

const Styles: any = styled.div`
  display: flex;
  height: 40px;
  background: ${colors.paleGreen};
  padding-left: 15px;
  z-index: 1;
  position: sticky;
  top: 30px;

  .column-titles {
    display: flex;
    align-items: center;
  }

  .cell p {
    padding: 0 1px 0px 4px;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .column-adjustment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 40px;

    :hover {
      cursor: col-resize;

      span {
        background: ${colors.paleGrey};
      }
    }

    span {
      width: 1px;
      background: ${colors.paleGrey};
      border-radius: 50px;
      padding: 10px 2px;
      margin: 0 8px;
      user-select: none;
    }

    .active-column {
      background: ${colors.mediumGrey};
    }
  }
`;
