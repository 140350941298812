import { sortElementsByPON } from '../util/sortElements';
import { getLastElement } from '../util/getLast';

import {
  GET_INITIAL_ELEMENTS_START,
  GET_INITIAL_ELEMENTS_SUCCESS,
  GET_INITIAL_ELEMENTS_FAIL,
  GET_ELEMENTS_START,
  GET_ELEMENTS_SUCCESS,
  GET_ELEMENTS_FAIL,
  GET_ELEMENT_START,
  GET_ELEMENT_SUCCESS,
  GET_ELEMENT_FAIL,
  GET_LAYER_START,
  GET_LAYER_SUCCESS,
  GET_LAYER_FAIL,
  ADD_ELEMENT_START,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  ADD_SIMPLE_QUALITY_START,
  ADD_SIMPLE_QUALITY_SUCCESS,
  ADD_SIMPLE_QUALITY_FAIL,
  ADD_QUALITY_START,
  ADD_QUALITY_SUCCESS,
  ADD_QUALITY_FAIL,
  UPDATE_ELEMENT_START,
  UPDATE_ELEMENT_SUCCESS,
  UPDATE_ELEMENT_FAIL,
  UPDATE_QUALITY_START,
  UPDATE_QUALITY_SUCCESS,
  UPDATE_QUALITY_FAIL,
  DELETE_ELEMENT_START,
  DELETE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_FAIL,
  DELETE_QUALITY_START,
  DELETE_QUALITY_SUCCESS,
  DELETE_QUALITY_FAIL,
  SET_TITLE,
} from './constants';

const initialState = {
  elements: [],
  element: {},
  lastElement: {},
  activeEdit: {},
  isLoading: false,
  initialElementsLoading: true,
  elementsLoading: false,
  layerLoading: false,
  error: '',
};

const elementReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case GET_INITIAL_ELEMENTS_START:
      return {
        ...state,
        initialElementsLoading: true,
        error: '',
      };

    case GET_INITIAL_ELEMENTS_SUCCESS:
      return {
        ...state,
        elements: sortElementsByPON(action.payload),
        lastElement: getLastElement(action.payload),
        initialElementsLoading: false,
        error: '',
      };

    case GET_INITIAL_ELEMENTS_FAIL:
      return {
        ...state,
        initialElementsLoading: false,
        error: 'Failed to fetch elements.',
      };

    case GET_ELEMENTS_START:
      return {
        ...state,
        elementsLoading: true,
        error: '',
      };

    case GET_ELEMENTS_SUCCESS:
      return {
        ...state,
        elements: sortElementsByPON(action.payload),
        lastElement: getLastElement(action.payload),
        elementsLoading: false,
        error: '',
      };

    case GET_ELEMENTS_FAIL:
      return {
        ...state,
        elementsLoading: false,
        error: 'Failed to fetch elements.',
      };

    case GET_LAYER_START:
      return {
        ...state,
        layerLoading: true,
        error: '',
      };

    case GET_LAYER_SUCCESS:
      return {
        ...state,
        elements: sortElementsByPON(action.payload),
        lastElement: getLastElement(action.payload),
        layerLoading: false,
        error: '',
      };

    case GET_LAYER_FAIL:
      return {
        ...state,
        layerLoading: false,
        error: 'Failed to fetch layer.',
      };

    case GET_ELEMENT_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case GET_ELEMENT_SUCCESS:
      return {
        ...state,
        elements: sortElementsByPON(
          state.elements.map(element => {
            const payloadMatch = element.elementId === action.payload.elementId;

            if (payloadMatch) return action.payload;
            return element;
          })
        ),
        lastElement: getLastElement(state.elements),
        isLoading: false,
        error: '',
      };

    case GET_ELEMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to fetch elements.',
      };

    case ADD_ELEMENT_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_ELEMENT_SUCCESS:
      return {
        ...state,
        elements: sortElementsByPON([...state.elements, action.payload]),
        lastElement: getLastElement([...state.elements, action.payload]),
        isLoading: false,
        error: '',
      };

    case ADD_ELEMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to add element.',
      };

    case ADD_SIMPLE_QUALITY_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_SIMPLE_QUALITY_SUCCESS:
      return {
        ...state,
        elements: state.elements.map(element => {
          const payloadMatch = element.elementId === action.payload.elementId;

          if (payloadMatch && action.payload.qualityId) {
            return { ...element, textQuality: action.payload };
          }

          if (payloadMatch && action.payload.configQualityId) {
            return { ...element, configQuality: action.payload };
          }

          return element;
        }),
        isLoading: false,
        error: '',
      };

    case ADD_SIMPLE_QUALITY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to add quality.',
      };

    case ADD_QUALITY_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_QUALITY_SUCCESS:
      return {
        ...state,
        elements: state.elements.map(element => {
          const payloadMatch = element.elementId === action.payload.elementId;

          if (payloadMatch) {
            return { ...element, dataQualities: [...element.dataQualities, action.payload] };
          }
          return element;
        }),
        isLoading: false,
        error: '',
      };

    case ADD_QUALITY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to add quality.',
      };

    case UPDATE_ELEMENT_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case UPDATE_ELEMENT_SUCCESS:
      return {
        ...state,
        elements: state.elements.map(element => {
          const payloadMatch = element.elementId === action.payload.elementId;

          if (payloadMatch) return action.payload;
          return element;
        }),
        element: action.payload,
        isLoading: false,
        error: '',
      };

    case UPDATE_ELEMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update element.',
      };

    case UPDATE_QUALITY_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case UPDATE_QUALITY_SUCCESS:
      return {
        ...state,
        elements: state.elements.map(el => {
          const payloadMatch = el.elementId === action.payload.elementId;

          if (payloadMatch) {
            const updatedQualities = el.dataQualities.map(quality => {
              if (quality.qualityId === action.payload.qualityId) return action.payload;
              return quality;
            });

            return { ...el, dataQualities: updatedQualities };
          }

          return el;
        }),
        isLoading: false,
        error: '',
      };

    case UPDATE_QUALITY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update quality.',
      };

    case DELETE_QUALITY_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case DELETE_QUALITY_SUCCESS:
      return {
        ...state,
        elements: state.elements.map(element => {
          const { textQuality } = element;

          if (textQuality && textQuality.qualityId === action.payload) {
            return { ...element, textQuality: undefined };
          }

          return element;
        }),
        isLoading: false,
        error: '',
      };

    case DELETE_QUALITY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to delete quality.',
      };

    case DELETE_ELEMENT_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case DELETE_ELEMENT_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        lastElement: { ...action.payload[action.payload.length - 1], childrenPON: [] },
        isLoading: false,
        error: '',
      };

    case DELETE_ELEMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update element.',
      };

    case SET_TITLE:
      return {
        ...state,
        activeEdit: action.payload,
      };

    default:
      return state;
  }
};

export default elementReducer;
