import React, { useEffect } from 'react';
// $FlowFixMe - react-router-dom
import { useLocation } from 'react-router-dom';

// analytics
import { logPageView } from '../../../util/analytics';

const Analytics = () => {
  const { pathname } = useLocation();
  // log page view when pathname changes
  useEffect(() => {
    if (pathname) {
      logPageView(pathname);
    }
  }, [pathname]);

  return <></>;
};

export default Analytics;
