import {
  SET_CONTEXT_MENU_OPEN,
  SET_CONTEXT_MENU_POSITION,
  SET_FULL_SCREEN,
  SET_MENU_TARGET,
  SET_ACTIVE_VIEW,
  SET_MODAL_CONFIG,
  SET_CANVAS_CONFIG,
  SET_NOTIFICATION_CONFIG,
  SET_TABLE_CONFIG,
  SET_HIGHLIGHT,
  SET_DRAGGING_ID,
  SET_ACTIVE_LAYER_ID,
  SET_MINIMIZE_VIEW,
} from './constants';

const initialState = {
  contextMenuOpen: false,
  contextMenuPosition: {},
  fullScreen: '',
  minimizeScreen: '',
  isSync: true,
  menuTargetId: undefined,
  activeView: '',
  modalConfig: {},
  canvasConfig: {
    xAdjustment: 0,
    yAdjustment: 0,
    scale: 0.8,
    labelId: undefined,
  },
  notificationConfig: { notificationOpen: false, message: '', type: '' },
  tableConfig: { hasColorColumn: false },
  activeEditId: undefined,
  activeLayerId: undefined,
};

const configReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case SET_CONTEXT_MENU_OPEN:
      return {
        ...state,
        contextMenuOpen: action.payload,
      };

    case SET_CONTEXT_MENU_POSITION:
      return {
        ...state,
        contextMenuPosition: action.payload,
      };

    case SET_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      };

    case SET_MENU_TARGET:
      return {
        ...state,
        menuTargetId: action.payload,
      };

    case SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload,
      };

    case SET_MODAL_CONFIG:
      return {
        ...state,
        modalConfig: { ...action.payload },
      };

    case SET_CANVAS_CONFIG:
      return {
        ...state,
        canvasConfig: { ...state.canvasConfig, ...action.payload },
      };

    case SET_NOTIFICATION_CONFIG:
      return {
        ...state,
        notificationConfig: action.payload,
      };

    case SET_TABLE_CONFIG:
      return {
        ...state,
        tableConfig: { ...state.tableConfig, ...action.payload },
      };

    case SET_HIGHLIGHT:
      return {
        ...state,
        activeEditId: action.payload,
      };

    case SET_DRAGGING_ID:
      return {
        ...state,
        activeDragId: action.payload,
      };

    case SET_ACTIVE_LAYER_ID:
      return {
        ...state,
        activeLayerId: action.payload,
      };

    case SET_MINIMIZE_VIEW:
      return {
        ...state,
        minimizeScreen: action.payload,
      };

    default:
      return state;
  }
};

export default configReducer;
