import React, { useState } from 'react';
import styled from 'styled-components';

// component
import ButtonWithIcon from '../../../General/ButtonWithIcon';
import Button from '../../../General/Button';

// asset
import DropdownCarrot from '../../../../assets/DropdownCarrot';

// content
import { nameContent } from './surveyContent';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  setPage: Function,
  user: Object,
  setSurveyData: Function,
};

const NameModal = ({ setPage, user, setSurveyData }: propTypes) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  const handleSubmit = () => {
    setSurveyData(surveyData => ({ ...surveyData, firstName, lastName }));
    setPage(3);
  };

  return (
    <Styles>
      <fieldset className="survey-modal-wrapper">
        <legend>{nameContent.title}</legend>
        <label htmlFor="firstName">
          First Name
          <input value={firstName} onChange={e => setFirstName(e.target.value)} />
        </label>
        <label htmlFor="lastName">
          Last Name
          <input value={lastName} onChange={e => setLastName(e.target.value)} />
        </label>
        <p>{nameContent.description}</p>
      </fieldset>
      {firstName !== user.firstName || lastName !== user.lastName ? (
        <Button
          Icon={DropdownCarrot}
          text="submit"
          className="survey-filled-button"
          color={colors.white}
          event={handleSubmit}
        />
      ) : (
        <ButtonWithIcon
          Icon={DropdownCarrot}
          text="skip"
          className="survey-menu-button"
          position="right"
          color={colors.darkGrey}
          onClick={() => setPage(3)}
        />
      )}
    </Styles>
  );
};

export default NameModal;

const Styles: any = styled.form``;
