import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux/dist/cjs.min';

// components
import Link from '../../General/Link';
import Tooltip from '../../General/Tooltip';
import AccountOptions from './AccountOptions';

// assets
import logo from '../../../assets/BrainSquall-logo.jpg';

// data management
import { configContext } from '../../../store/contexts';
import {
  SELECT_BOARD,
  SET_CANVAS_CONFIG,
  SET_FULL_SCREEN,
  SET_ACTIVE_VIEW,
} from '../../../store/constants';

// theme
import { colors } from '../../../styles/theme';

const GeneralHeader = () => {
  // config store
  const [, configDispatch] = useStateValue(configContext);

  const resetBoardSelection = () => {
    // reset selected board
    localStorage.removeItem('boardId');
    configDispatch({ type: SELECT_BOARD, payload: {} });

    // reset canvas config
    configDispatch({
      type: SET_CANVAS_CONFIG,
      payload: { xAdjustment: 0, yAdjustment: 0, scale: 1 },
    });

    // reset active view
    configDispatch({ type: SET_ACTIVE_VIEW, payload: undefined });

    // close full screen
    configDispatch({ type: SET_FULL_SCREEN, payload: '' });
  };

  return (
    <Styles>
      <Link
        to="/dashboard"
        className="logo-and-name"
        testId="logo-link"
        onClick={resetBoardSelection}
      >
        <Tooltip title="my boards">
          <img src={logo} alt="brainsquall logo" />
        </Tooltip>
        <h1 data-testid="logo-text">BrainSquall</h1>
      </Link>

      <AccountOptions />
    </Styles>
  );
};

export default GeneralHeader;

const Styles: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${colors.paleGrey};
  height: 55px;
  padding: 0 20px;
  background: ${colors.white};

  .logo-and-name {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
      width: 45px;
      height: 33px;
    }

    h1 {
      font-size: 2rem;
      margin: 0 10px;
      padding: 0;
    }
  }
`;
