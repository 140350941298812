import React from 'react';
import { useStateValue } from 'react-conflux';

// components
import ReadOnlyTextQuality from './TextQuality';

// data management
import { tableContext } from '../../../store/contexts';

type propTypes = {
  dataQualities: Array<Object>,
  elementId: number,
  rowIndex: number,
};

const Qualities = ({ dataQualities, elementId, rowIndex }: propTypes) => {
  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // render quality if exists, otherwise return an empty cell
  return (
    <>
      {columns.map((column, index) => {
        const { columnId, columnType, width } = column;

        // if quality exists for table position, return quality
        const dataQuality = dataQualities.find(
          quality => quality.columnId === columnId && quality.elementId === elementId
        );
        if (dataQuality && columnType === 'text') {
          return (
            <ReadOnlyTextQuality
              key={dataQuality.qualityId}
              dataQuality={dataQuality}
              rowIndex={rowIndex}
              columnIndex={index}
            />
          );
        }

        // if no quality exists for position, return empty quality
        return (
          <div
            key={2 + columnId + elementId}
            style={{
              width: width < 80 ? 60 : width - 20,
              minWidth: width < 80 ? 60 : width - 20,
              margin: '0 10px',
            }}
          />
        );
      })}
    </>
  );
};

export default Qualities;
