import { getLastChildPON } from './getLast';

// note, this function does not increment PON beyond 999
const generatePON = (element: Object) => {
  const { PON, childrenPON } = element;

  if (childrenPON.length > 0) {
    const lastChildPON = getLastChildPON(childrenPON);

    // when lastChildPON is present, increment last digit of last child PON
    // store last digit of string and convert to number
    const rightSliceIndex = lastChildPON.lastIndexOf('.') + 1;

    const lastDigit = +lastChildPON.slice(rightSliceIndex);

    // increment by 1 and convert to string
    const newLastDigit = String(lastDigit + 1);

    // replace last digit of child with new digit -- when digit is length of 1
    if (String(lastDigit).length === 1) return lastChildPON.slice(0, -1) + newLastDigit;

    // replace last digit of child with new digit -- when digit is greater than 1
    return lastChildPON.slice(0, -newLastDigit.length) + newLastDigit;
  }

  // when no childrenPON are present, add digit to end of parent PON
  return `${PON}.1`;
};

export default generatePON;

generatePON.defaultProps = {
  childPON: undefined,
};
