import React from 'react';
import styled from 'styled-components';

// components
import ReadOnlyElement from './Element';

type propTypes = {
  elements: Array<Object>,
};

const Content = ({ elements }: propTypes) => {
  return (
    <Styles>
      {elements.map(element => {
        const { elementId, textQuality } = element;

        return <ReadOnlyElement key={elementId} element={element} textQuality={textQuality} />;
      })}
    </Styles>
  );
};

export default Content;

const Styles: any = styled.div``;
