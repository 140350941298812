import React, { useState } from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  children: Function,
  title: string,
  className?: string,
  position?: string,
};

const Tooltip = ({ children, title, className, position }: propTypes) => {
  const [isHidden, setIsHidden] = useState(true);
  const [delayHandler, setDelayHandler] = useState(null);

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setIsHidden(false);
      }, 500)
    );
  };
  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setIsHidden(true);
  };

  const determineClassName = () => {
    if (position === 'left') return 'tooltip tooltip-left';
    if (position === 'right') return 'tooltip tooltip-right';
    return 'tooltip';
  };

  return (
    <Styles onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={className}>
      {children}
      {!isHidden && title !== '' && <Styles className={determineClassName()}>{title}</Styles>}
    </Styles>
  );
};

export default Tooltip;

Tooltip.defaultProps = {
  className: '',
  position: '',
};

const Styles: any = styled.span`
  /* tooltip wrapper  */
  position: relative;
  display: inline;

  .tooltip {
    /* important */
    position: absolute;

    /* layout */
    background: ${colors.darkGrey};
    width: 110px;
    min-height: 32px;
    border-radius: 4px;

    /* font */
    color: ${colors.white};
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 0.25px;
    line-height: 16px;

    /* spacing */
    top: 40px;
    left: calc(50% - 60px);
    padding: 8px 16px;

    /* other */
    z-index: 2;

    :after {
      content: '';
      position: absolute;
      top: -8px;
      left: calc(50% - 8px);
      border: 8px solid transparent;
      border-bottom-color: ${colors.darkGrey};
      border-top: 0;
    }
  }

  .tooltip-right {
    width: 100px;

    top: 5px;
    left: calc(50% - -18px);

    :after {
      top: 14px;
      left: -11px;

      transform: rotate(-90deg);
    }
  }

  .tooltip-left {
    position: fixed;
    left: calc(100% - 200px);
    top: calc(100% - 53px);

    width: 130px;
    padding: 8px 16px;

    z-index: 2;

    :after {
      top: 12px;
      left: calc(50% + 60px);
      transform: rotate(90deg);
    }
  }
`;
