import {
  GET_TABLE_START,
  GET_TABLE_SUCCESS,
  GET_TABLE_FAIL,
  ADD_COLUMN_START,
  ADD_COLUMN_SUCCESS,
  ADD_COLUMN_FAIL,
  UPDATE_COLUMN_START,
  UPDATE_COLUMN_SUCCESS,
  UPDATE_COLUMN_FAIL,
  DELETE_COLUMN_START,
  DELETE_COLUMN_SUCCESS,
  DELETE_COLUMN_FAIL,
  ADD_LAYER_START,
  ADD_LAYER_SUCCESS,
  ADD_LAYER_FAIL,
  UPDATE_LAYER_START,
  UPDATE_LAYER_SUCCESS,
  UPDATE_LAYER_FAIL,
  DELETE_LAYER_START,
  DELETE_LAYER_SUCCESS,
  DELETE_LAYER_FAIL,
} from './constants';

const initialState = {
  columns: [],
  layers: [],
  isLoading: false,
  error: '',
};

const tableReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case GET_TABLE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case GET_TABLE_SUCCESS:
      return {
        ...state,
        columns: action.payload.columns,
        layers: action.payload.layers,
        isLoading: false,
        error: '',
      };

    case GET_TABLE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to fetch table data.',
      };

    case ADD_COLUMN_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_COLUMN_SUCCESS:
      return {
        ...state,
        columns: [...state.columns, action.payload],
        isLoading: false,
        error: '',
      };

    case ADD_COLUMN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to add column.',
      };

    case UPDATE_COLUMN_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case UPDATE_COLUMN_SUCCESS:
      return {
        ...state,
        columns: state.columns
          .map(column => {
            if (column.columnId && action.payload) {
              const payloadMatch = column.columnId === action.payload.columnId;

              if (payloadMatch) return action.payload;
            }
            return column;
          })
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
        isLoading: false,
        error: '',
      };

    case UPDATE_COLUMN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update column.',
      };

    case DELETE_COLUMN_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case DELETE_COLUMN_SUCCESS:
      return {
        ...state,
        columns: [...state.columns.filter(column => column.columnId !== action.payload)],
        isLoading: false,
        error: '',
      };

    case DELETE_COLUMN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update column.',
      };

    case ADD_LAYER_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_LAYER_SUCCESS:
      return {
        ...state,
        layers: [...state.layers, action.payload],
        isLoading: false,
        error: '',
      };

    case ADD_LAYER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to add layer.',
      };

    case UPDATE_LAYER_START:
      return { ...state, isLoading: true, error: '' };

    case UPDATE_LAYER_SUCCESS:
      return {
        ...state,
        layers: state.layers.map(layer => {
          const payloadMatch = layer.layerId === action.payload.layerId;

          if (payloadMatch) return action.payload;
          return layer;
        }),
        isLoading: false,
        error: '',
      };

    case UPDATE_LAYER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update layer.',
      };

    case DELETE_LAYER_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case DELETE_LAYER_SUCCESS:
      return {
        ...state,
        layers: [...state.layers.filter(layer => layer.layerId !== action.payload)],
        isLoading: false,
        error: '',
      };

    case DELETE_LAYER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update layer.',
      };

    default:
      return state;
  }
};

export default tableReducer;
