import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ReadOnlyElement from './Element';
import Header from '../../BoardView/Canvas/Header';
import Line from '../../BoardView/Canvas/Line';
import Loader from '../../../components/General/Loader';

// data management
import { elementContext, configContext } from '../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../store/constants';

// utility
import { useCanvasAdjust } from '../../../hooks/useCanvasUtility';
import useDraggable from '../../../hooks/useDraggable';

const Canvas = () => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { fullScreen, activeView, canvasConfig, minimizeScreen } = configState;
  const { scale } = canvasConfig;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements, elementsLoading } = elementState;

  // state to check trackpad pinch
  const [pinchScale, setPinchScale] = useState(0);

  // get zoom utility functions for canvas
  const { increaseScale, decreaseScale } = useCanvasAdjust();

  // set active view on click of focus
  const setView = () => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'canvas' });

  // zoom in/out based on mouse wheel scroll direction
  const handleZoomAdjustment = ({ deltaY }) => {
    if (deltaY < 0 && pinchScale % 1 === 0) decreaseScale();
    if (deltaY > 0 && pinchScale % 1 === 0) increaseScale();
  };

  // handle className logic for  fullscreen, minimize screen and active view
  const determineClassName = () => {
    if (fullScreen === 'canvas') return 'full-screen-view canvas active-view-canvas';
    if (fullScreen === 'outline' || fullScreen === 'table' || minimizeScreen === 'canvas')
      return 'hide';
    if (activeView === 'canvas') return 'active-view-canvas canvas';

    return 'canvas';
  };

  const { handleMouseDown, handleMouseUp, resetPosition, translation } = useDraggable();

  useEffect(() => {
    if (elements.length !== 0) resetPosition();
  }, [elements.length]); // eslint-disable-line react-hooks/exhaustive-deps

  // check trackpad pinch - pinch in is positive, pinching out is negative
  // whole number - up down right left, float is pinching
  const handlePinchToZoom = useCallback(({ deltaY, ctrlKey }: Object) => {
    if (ctrlKey && deltaY % 1 !== 0) {
      setPinchScale(deltaY);
    } else setPinchScale(0);
  }, []);

  useEffect(() => {
    document.addEventListener('wheel', handlePinchToZoom);
    return () => {
      document.removeEventListener('wheel', handlePinchToZoom);
    };
  }, [handlePinchToZoom]);

  return (
    <Styles
      className={determineClassName()}
      onClick={setView}
      onWheel={e => handleZoomAdjustment(e)}
    >
      <Header resetPosition={resetPosition} />

      {elementsLoading ? (
        <Loader />
      ) : (
        <svg
          id="svg-canvas"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          // Enables mobile functionality
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchCancel={handleMouseUp}
        >
          <g transform={`translate(${translation.x}, ${translation.y}) scale(${scale})`}>
            {elements.map(element => {
              const { elementId, parentId } = element;

              if (parentId)
                return <Line key={elementId} element={element} elementTranslation={{}} />;

              return <div key={elementId} />;
            })}

            {elements.map(element => {
              const { elementId } = element;

              return <ReadOnlyElement key={elementId} element={element} />;
            })}
          </g>
        </svg>
      )}
    </Styles>
  );
};

export default Canvas;

const Styles: any = styled.div``;
