import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/theme';

const DragIcon = () => {
  return (
    <Styles className="dragicon">
      <section className="dragicon">
        <span className="dragicon" />
        <span className="dragicon" />
        <span className="dragicon" />
      </section>
      <section className="dragicon">
        <span className="dragicon" />
        <span className="dragicon" />
        <span className="dragicon" />
      </section>
    </Styles>
  );
};

export default DragIcon;

const Styles: any = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 28px;
  margin-right: -5px;

  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }

  section {
    display: flex;
    flex-direction: column;

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${colors.lightGrey};
      margin: 1px;
    }
  }
`;
