import React from 'react';
import styled from 'styled-components';

// components
import Button from '../../components/General/Button';
import NewBoardButton from './NewBoardButton';

const DashboardButtons = () => {
  return (
    <Styles>
      <a href="https://brainsquall.co/templates" target="_blank" rel="noopener noreferrer">
        <Button text="Templates" className="invert-button" />
      </a>
      <NewBoardButton />
    </Styles>
  );
};

export default DashboardButtons;

const Styles: any = styled.div`
  display: flex;

  a {
    margin-right: 15px;
  }
`;
