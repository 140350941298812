import React from 'react';
import styled from 'styled-components';
import LoaderSpinner from 'react-loader-spinner';

type propTypes = {
  containerHeight?: string | number,
  width?: number,
  height?: number,
};

const Loader = ({ containerHeight, width, height }: propTypes) => {
  return (
    <Styles style={{ height: containerHeight }}>
      <LoaderSpinner type="TailSpin" width={width} height={height} color="grey" />
    </Styles>
  );
};

export default Loader;

Loader.defaultProps = {
  containerHeight: undefined,
  width: 40,
  height: 40,
};

const Styles: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
