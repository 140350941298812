import React from 'react';

import Button from './Button';

// auth  - $FlowFixMe
import { useAuth0 } from '../../auth/AuthWrapper';

declare class process {
  static env: {
    REACT_APP_STORAGE: string,
  };
}

const Logout = () => {
  const { logout } = useAuth0();

  const logoutUser = () => {
    logout({});
    localStorage.removeItem(process.env.REACT_APP_STORAGE);
  };

  return (
    <Button
      className="invert-button"
      testId="logout-button"
      type="button"
      text="Logout"
      event={logoutUser}
    />
  );
};

export default Logout;
