import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';

// data management
import { tableContext, globalContext } from '../../../../store/contexts';

// utility
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

declare class process {
  static env: {
    REACT_APP_PK: string,
  };
}

// environment variables
const secret = process.env.REACT_APP_PK;

type propTypes = {
  boardId?: number,
  columnId?: number,
  currentPosition: number,
  setCurrentPosition: Function,
  columnWidth?: number,
  setColumnWidth?: Function,
  elementColumnWidth?: number,
  setElementColumnWidth?: Function,
  isHeld: boolean,
  setIsHeld: Function,
  isHidden: boolean,
};

const AdjustWidth = ({
  boardId,
  columnId,
  currentPosition,
  setCurrentPosition,
  columnWidth,
  setColumnWidth,
  elementColumnWidth,
  setElementColumnWidth,
  isHeld,
  setIsHeld,
  isHidden,
}: propTypes) => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { board } = globalState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // find column being adjusted
  const column = columns.find(c => c.columnId === columnId);

  // track start position of column adjustment
  const [startPosition, setStartPosition] = useState(0);

  // track active column adjustment
  const [activeId, setActiveId] = useState(undefined);

  // destructure request utility
  const update = useUpdateRequest();

  // update local column width variable with column width as it is adjusted
  useEffect(() => {
    // calculate adjustment then reset current position to avoid compounding
    const distanceMoved = Math.floor(currentPosition - startPosition);
    setStartPosition(currentPosition);

    if (distanceMoved > -100 && distanceMoved < 100 && setColumnWidth) {
      if (column && columnId === activeId && columnWidth === 0) setColumnWidth(1);
      if (column && columnId === activeId) setColumnWidth(columnWidth + distanceMoved);
    }
  }, [currentPosition, isHeld, columnId, startPosition]); // eslint-disable-line

  // update local element column width with column width as it is adjusted
  useEffect(() => {
    // calculate adjustment then reset current position to avoid compounding
    const distanceMoved = Math.floor(currentPosition - startPosition);
    setStartPosition(currentPosition);

    if (distanceMoved > -100 && distanceMoved < 100 && setElementColumnWidth) {
      if (board && boardId === activeId && elementColumnWidth === 80) setElementColumnWidth(1);
      if (board && boardId === activeId) setElementColumnWidth(elementColumnWidth + distanceMoved);
    }
  }, [currentPosition, isHeld, boardId, startPosition]); // eslint-disable-line

  // set start position of column, isHeld, and set targetId
  const startAdjustment = (e, targetId) => {
    setActiveId(targetId);
    setCurrentPosition(e.pageX);
    setStartPosition(e.pageX);
    setIsHeld(true);
  };

  // submit request to update column width
  const submitColumnAdjustment = useCallback(async () => {
    setIsHeld(false);
    setActiveId(0);
    setStartPosition(0);
    setCurrentPosition(0);

    // send request and update store
    if (columnId && columnId === activeId) {
      update('table', 'column', `/columns/${columnId}`, { width: columnWidth });
    }

    if (boardId && boardId === activeId) {
      // send request and update store
      const success = update('global', 'board', `/boards/${boardId}`, {
        elementWidth: elementColumnWidth,
      });

      if (success) {
        // tokenize boardId and store in local storage for refresh reference.
        const tokenizedBoard = jwt.sign(board, secret);
        localStorage.setItem('board', tokenizedBoard);
      }
    }
  }, [
    setIsHeld,
    setActiveId,
    setCurrentPosition,
    columnId,
    activeId,
    columnWidth,
    elementColumnWidth,
    update,
    board,
    boardId,
  ]);

  // submit column adjustment is mouse is let go or leaves first row of table
  useEffect(() => {
    if (isHeld === false && activeId) submitColumnAdjustment();
  }, [isHeld, submitColumnAdjustment, activeId]);

  if (boardId) {
    return (
      <Styles
        className="column-adjustment-button"
        onMouseDown={e => startAdjustment(e, boardId)}
        onMouseUp={submitColumnAdjustment}
      >
        <span
          className={activeId === boardId ? 'active-column' : null}
          style={{ display: isHidden ? 'none' : null }}
        />
      </Styles>
    );
  }

  return (
    <Styles
      className="column-adjustment-button"
      onMouseDown={e => startAdjustment(e, columnId)}
      onMouseUp={submitColumnAdjustment}
    >
      <span
        className={activeId === columnId ? 'active-column' : null}
        style={{ display: isHidden ? 'none' : null }}
      />
    </Styles>
  );
};

export default AdjustWidth;

AdjustWidth.defaultProps = {
  boardId: undefined,
  columnId: undefined,
  columnWidth: undefined,
  setColumnWidth: () => {},
  elementColumnWidth: undefined,
  setElementColumnWidth: () => {},
};

const Styles: any = styled.div``;
