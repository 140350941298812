import React from 'react';
// $FlowFixMe - react-router-dom
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// auth - $FlowFixMe
import { useAuth0 } from './auth/AuthWrapper';

// components
import Analytics from './components/Dashboard/Analytics';
import PublicBoardView from './Pages/PublicBoardView';
import HelpModalButton from './components/Dashboard/HelpMenuButton';
import Loader from './components/General/Loader';
import NotFound from './Pages/NotFound';
import Dashboard from './components/Dashboard';

// config
import redirects from './config/redirects';

// fonts
import './styles/Fonts.css';

const App = () => {
  // load blank screen while auth is loading
  const { loading } = useAuth0();

  const { pathname } = window.location;

  if (loading) return <Loader containerHeight="100vh" />;

  return (
    <>
      <Router>
        <Analytics />
        <Switch>
          {redirects[pathname] && <Redirect exact from={pathname} to={redirects[pathname]} />}

          {/* has internal authentication */}
          <Route path="/dashboard" component={Dashboard} />

          {/* unauthenticated routing */}
          <Route path="/board/:boardPublicKey" component={PublicBoardView} />

          <Route component={NotFound} />
        </Switch>
      </Router>

      {/* help button with popup modal */}
      <HelpModalButton />
    </>
  );
};

export default App;
