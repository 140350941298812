const focusInputId = (targetId: string, e?: Object) => {
  // prevent delete key from deleting character in previous input
  if (e) e.preventDefault();

  // target argument id
  const input = document.getElementById(targetId);

  // focus selector on provided input
  if (input) input.focus();
};

export default focusInputId;
