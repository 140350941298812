export const colors = {
  // brand colors
  purple: '#5724F5',
  yellow: '#FBCD4B',
  seaFoam: '#C2E7DA',
  green: '#42A854',
  midnightBlue: '#191B41',

  // main colors
  background: '#FAFAFC',
  scrim: `rgba(0, 0, 0, 0.5)`,
  lightTransparency: `rgba(0, 0, 0, .25)`,
  fullTransparency: `rgba(0, 0, 0, 0)`,

  // purple colors
  darkPurple: '#3D09E3',
  lightPurple: '#764DF4',
  palePurple: '#F4F0FE',

  // feedback colors
  darkGreen: '#006121',
  successGreen: '#007613',
  lightGreen: '#1E8800',
  paleGreen: '#F4FBF5',
  darkRed: '#BF0000',
  warningRed: '#D50000',
  lightRed: '#EF0000',
  paleRed: '#FDF1F2',

  // main view colors
  tableGreen: '#42A854',
  canvasPurple: '#858AE3',
  outlineBlue: '#01ACEB',

  // greys
  black: '#000000',
  darkGrey: '#2C2C2C',
  mediumGrey: '#6D6D6D',
  lightGrey: '#B2B2B2',
  paleGrey: '#EBEBEB',
  white: '#ffffff',

  // user selection colors
  userRed: '#FEAFC2',
  userPurple: '#CDC5F4',
  userBlue: '#B0DFFB',
  userYellow: '#FFF47F',
  userLime: '#E7F4A2',
  userOrange: '#FADDB1',
  userGreen: '#C9F3D2',
  userPink: '#FBCDF6',

  // box shadow
  boxShadow: '0 2px 4px 0 rgba(216, 216, 216, 0.5)',
};

export const breakpoints = {
  small: '500px',
  medium: '800px',
  large: '1000px',
};
