import React from 'react';
import { useStateValue } from 'react-conflux';
import styled from 'styled-components';

// data management
import { SET_MINIMIZE_VIEW } from '../../store/constants';
import { configContext } from '../../store/contexts';

// asset
import Blowup from '../../assets/blowup.svg';

// style
import { colors } from '../../styles/theme';

type propTypes = {
  text: string,
};
const BlowupMenu = ({ text }: propTypes) => {
  // config store
  const [, configDispatch] = useStateValue(configContext);

  const blowupView = () => {
    configDispatch({ type: SET_MINIMIZE_VIEW, payload: '' });

    // append div
    const animationElement = document.createElement('div');
    const board = document.getElementsByClassName('board-layout')[0];

    if (board) {
      board.appendChild(animationElement).classList.add(`blowup-${text}`, 'blowup-menu');

      setTimeout(() => {
        board.removeChild(animationElement);
      }, 500);
    }
  };

  return (
    <Styles>
      <button type="button" onClick={() => blowupView()}>
        {text === 'canvas' ? 'diagram' : text}
        <img src={Blowup} alt={`cancel ${text} minimization`} />
      </button>
    </Styles>
  );
};

export default BlowupMenu;

const Styles: any = styled.div`
  position: absolute;
  bottom: 60px;
  left: 15px;
  display: flex;
  align-items: center;

  background: ${colors.white};

  max-width: 113px;
  height: 44px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  button {
    display: flex;
    justify-content: center;
    border: none;

    height: 20px;
    margin-right: 4px;
  }
`;
