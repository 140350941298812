import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { SET_FULL_SCREEN, SET_ACTIVE_VIEW, SET_MINIMIZE_VIEW } from '../../store/constants';
import { configContext } from '../../store/contexts';

// assets
import expandIcon from '../../assets/fullscreen.svg';
import closeIcon from '../../assets/fullscreen-exit.svg';
import minimizeIcon from '../../assets/minimize.svg';

// style
import { colors } from '../../styles/theme';

type propTypes = {
  componentName: string,
};

const FullScreenButton = ({ componentName }: propTypes) => {
  const [expand, setExpand] = useState(false);
  const [minimize, setMinimize] = useState(false);

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { fullScreen, minimizeScreen } = configState;

  const expandFullScreen = () => {
    setExpand(!expand);
    configDispatch({ type: SET_FULL_SCREEN, payload: componentName });
    configDispatch({ type: SET_ACTIVE_VIEW, payload: componentName });

    if (fullScreen === componentName) configDispatch({ type: SET_FULL_SCREEN, payload: '' });
  };

  const minimizeView = () => {
    setMinimize(!minimize);
    configDispatch({ type: SET_MINIMIZE_VIEW, payload: componentName });

    // append div
    const animationElement = document.createElement('div');
    const board = document.getElementsByClassName('board-layout')[0];
    if (board) {
      board.appendChild(animationElement).classList.add(`minimize-${componentName}`);

      setTimeout(() => {
        board.removeChild(animationElement);
      }, 300);
    }
  };

  return (
    <Styles>
      {fullScreen ? (
        <button type="button" onClick={expandFullScreen}>
          <img src={closeIcon} alt={`close ${componentName}`} />
        </button>
      ) : (
        <div className="header-icon-wrapper">
          {!minimizeScreen && (
            <button type="button" onClick={minimizeView}>
              <img
                src={minimizeIcon}
                alt={`minimize ${componentName}`}
                style={{ marginRight: '4px' }}
              />
            </button>
          )}
          <button type="button" onClick={expandFullScreen}>
            <img src={expandIcon} alt={`expand ${componentName}`} />
          </button>
        </div>
      )}
    </Styles>
  );
};

export default FullScreenButton;

const Styles: any = styled.div`
  position: sticky;
  top: 0;
  right: 5px;

  button {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;

    border: 0;
    padding: 0 4px;

    img {
      width: 16px;
      height: 16px;
    }
    :hover {
      background: ${colors.paleGrey};
    }
  }

  .header-icon-wrapper {
    display: flex;
  }
`;
