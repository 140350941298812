import React from 'react';
import styled from 'styled-components';

// assets
import closeIcon from '../../../assets/close-50.png';

type propTypes = {
  text: string,
  closeModal: Function,
};

const Header = ({ text, closeModal }: propTypes) => {
  return (
    <Styles>
      <h3>{text}</h3>

      <button className="remove-button-styles" type="button" onClick={closeModal}>
        <img src={closeIcon} alt="close modal" />
      </button>
    </Styles>
  );
};

export default Header;

const Styles: any = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;

  button {
    margin: -20px -10px 0 0;
  }
`;
