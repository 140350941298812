import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { tableContext } from '../../../store/contexts';

type propTypes = {
  dataQuality: Object,
};

const Quality = ({ dataQuality }: propTypes) => {
  const { columnId, title } = dataQuality;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // get width of column as it updates
  const { width } = columns.find(c => c.columnId === columnId);

  return (
    <Styles
      className="cell"
      style={{
        width: width < 80 ? 60 : width - 20,
        minWidth: width < 80 ? 60 : width - 20,
        margin: '0 10px',
      }}
    >
      <p>{title || ''}</p>
    </Styles>
  );
};

export default Quality;

const Styles: any = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
  }
`;
