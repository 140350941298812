import React, { useState } from 'react';

// assets
import addIcon from '../../../assets/add-circle-48.png';

// data management
import { UPDATE_ELEMENT_SUCCESS } from '../../../store/constants';

// utility
import generatePON from '../../../util/generatePON';
import focusInputId from '../../../util/focusInputId';
import { generateXPosition, generateYPosition } from '../../../util/canvasMath';
import usePostRequest from '../../../requestHooks/usePostRequest';
import { clickedPos } from '../../../util/constants';
import useFreemium from '../../../hooks/useFreemium';

type propTypes = {
  activeLayerId: number,
  element: Object,
  elementHover: Function,
  elementDispatch: Function,
  getForeignObjectWidth: Function,
};

const NewElementButtons = ({
  activeLayerId,
  element,
  elementHover,
  elementDispatch,
  getForeignObjectWidth,
}: propTypes) => {
  const { elementId, boardId } = element;

  // destructure add directions
  const { TOP, BOTTOM, LEFT, RIGHT } = clickedPos;

  // destructure checkElementCount
  const { checkElementCount } = useFreemium();

  const postRequest = usePostRequest();

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  // element required data - { title, xCoord, yCoord, boardId, PON}
  const addChildElement = async clickedButton => {
    if (!inProgress) {
      // start post request
      setInProgress(true);
      const allowPost = checkElementCount();

      if (allowPost) {
        if (boardId && elementId) {
          // post new element with parentId connection to previous element
          const newPON = generatePON(element);
          const newElement = {
            title: '',
            PON: newPON,
            xCoord: generateXPosition(element, clickedButton, getForeignObjectWidth()),
            yCoord: generateYPosition(element, clickedButton),
            parentId: elementId,
          };

          // send post request and set data to store
          const data = await postRequest(
            'element',
            'element',
            `/elements/board/${boardId}/parent/${elementId}/layer/${activeLayerId}`,
            newElement
          );

          // dispatch newly added element
          if (data) {
            // post success - dispatch new data to store
            setInProgress(false);

            elementDispatch({
              type: UPDATE_ELEMENT_SUCCESS,
              payload: { ...element, childrenPON: [...element.childrenPON, newPON] },
            });

            focusInputId(`canvas${data.elementId}`);
          }
        }
      }
    }
  };

  return (
    <>
      {/* Top Button */}
      <button
        type="button"
        className={elementHover ? 'hover-active hover-active-top' : 'hover-inactive'}
        onClick={() => addChildElement(TOP)}
      >
        <img src={addIcon} alt="Add new child element" />
      </button>
      {/* Bottom Button */}
      <button
        type="button"
        className={elementHover ? 'hover-active hover-active-bottom' : 'hover-inactive'}
        onClick={() => addChildElement(BOTTOM)}
      >
        <img src={addIcon} alt="Add new child element" />
      </button>
      {/* Left Button */}
      <button
        type="button"
        className={elementHover ? 'hover-active hover-active-left' : 'hover-inactive'}
        onClick={() => addChildElement(LEFT)}
      >
        <img src={addIcon} alt="Add new child element" />
      </button>
      {/* Right Button */}
      <button
        type="button"
        className={elementHover ? 'hover-active hover-active-right' : 'hover-inactive'}
        onClick={() => addChildElement(RIGHT)}
      >
        <img src={addIcon} alt="Add new child element" />
      </button>
    </>
  );
};

export default NewElementButtons;
