import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux/dist/cjs.min';

// components
import BoardContextMenu from '../../BoardSelection/BoardContextMenu';
import ButtonWithIcon from '../../../components/General/ButtonWithIcon';
import CopyButton from './CopyButton';
import Input from '../../../components/General/Input';
import Link from '../../../components/General/Link';
import SocialShare from './SocialShare';
import Tooltip from '../../../components/General/Tooltip';
import ViewOnlyButton from './ViewOnlyButton';

// assets
import logo from '../../../assets/BrainSquall-logo.jpg';
import DropdownCarrot from '../../../assets/DropdownCarrot';

// data management
import { globalContext, configContext } from '../../../store/contexts';
import {
  SELECT_BOARD,
  SET_CANVAS_CONFIG,
  SET_FULL_SCREEN,
  SET_ACTIVE_VIEW,
  SET_NOTIFICATION_CONFIG,
} from '../../../store/constants';

// utility
import focusInputId from '../../../util/focusInputId';
import validateTitle from '../../../util/validateTitle';
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';
import useContextMenu from '../../../hooks/useContextMenu';

// theme
import { colors } from '../../../styles/theme';

const BoardHeader = () => {
  // global store
  const [globalState, globalDispatch] = useStateValue(globalContext);
  const { board, boards, selectedBoardId } = globalState;
  const { title, boardId } = board;

  // config store
  const [configStore, configDispatch] = useStateValue(configContext);
  const { contextMenuOpen } = configStore;
  const { contextMenuId } = configStore.contextMenuPosition;

  const [boardTitle, setBoardTitle] = useState('');

  // destructure request utility
  const update = useUpdateRequest();

  useEffect(() => {
    if (board) setBoardTitle(board.title);
  }, [board]);

  const updateBoardTitle = async () => {
    // Checks if duplicate board title exists.
    const validTitle = validateTitle(boards, boardTitle);

    if (!validTitle) {
      const notification = {
        notificationOpen: true,
        message: 'Duplicate board title found. Please try again.',
        type: 'error',
      };

      // trigger error notification
      configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });

      // focus input back on board title
      focusInputId(`board${boardId}`);
    }

    // send request and update store
    if (validTitle) update('global', 'board', `/boards/${selectedBoardId}`, { title: boardTitle });
  };

  const resetBoardSelection = () => {
    // reset selected board
    localStorage.removeItem('boardId');
    globalDispatch({ type: SELECT_BOARD, payload: {} });

    // reset canvas config
    configDispatch({
      type: SET_CANVAS_CONFIG,
      payload: { xAdjustment: 0, yAdjustment: 0, scale: 1 },
    });

    // reset active view
    configDispatch({ type: SET_ACTIVE_VIEW, payload: undefined });

    // close full screen
    configDispatch({ type: SET_FULL_SCREEN, payload: '' });
  };

  // check if route is public
  const { pathname } = window.location;
  const isPublicRoute = pathname.includes('/board/');

  // context menu
  const { openContextMenu } = useContextMenu();

  return (
    <Styles className="board-header">
      <div className="logo-and-title">
        <Tooltip title="my board">
          <Link testId="logo-link" to="/dashboard" onClick={resetBoardSelection}>
            <img src={logo} alt="brainsquall logo" />
          </Link>
        </Tooltip>

        {isPublicRoute && (
          <p className="read-only-title" id={`board${boardId}`}>
            {boardTitle || 'Untitled Board'}
          </p>
        )}

        {!isPublicRoute && (
          <>
            <Input
              data-testid="board-title-input"
              value={boardTitle || ''}
              setValue={setBoardTitle}
              aria-label={boardTitle || 'Untitled Board'}
              placeholder="Enter board title..."
              id={`board${boardId}`}
              handleBlur={() => {
                if (title !== boardTitle) updateBoardTitle();
              }}
            />
            <ButtonWithIcon
              Icon={DropdownCarrot}
              color={colors.darkGrey}
              className="dropdown-carrot"
              onClick={e => openContextMenu(e, boardId, 20)}
              style={{ padding: '0 0 0 10px' }}
            />

            {contextMenuOpen && contextMenuId === boardId && <BoardContextMenu board={board} />}
          </>
        )}
      </div>
      <div className="button-container">
        <CopyButton board={board} isPublicRoute={isPublicRoute} />
        <ViewOnlyButton boardId={boardId} isPublicRoute={isPublicRoute} />
        <SocialShare isPublicRoute={isPublicRoute} />
      </div>
    </Styles>
  );
};

export default BoardHeader;

const Styles: any = styled.div`
  grid-area: board-header;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${colors.paleGrey};
  min-height: 46px;
  padding: 0 20px;
  background: ${colors.white};

  .logo-and-title {
    display: flex;
    width: 350px;

    @media (max-width: 550px) {
      width: 200px;
    }

    a {
      display: flex;
    }

    img {
      margin: 0 0 -2px 5px;
      width: 45px;
      height: 33px;
    }

    input {
      width: 250px;
      margin: 0 10px;
      outline: none;
      border: 2px solid transparent;

      :focus {
        border: 2px solid ${colors.purple};
        border-radius: 3px;
      }

      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }
  .read-only-title {
    width: 100%;
    margin: 0px 10px;
    outline: none;
    line-height: 2;
    color: inherit;
  }

  .button-container {
    display: flex;
  }

  .layer-container {
    display: flex;
    align-items: center;
  }

  .dropdown-carrot {
    :hover {
      background: ${colors.paleGrey};
    }
  }
`;
