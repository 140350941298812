import { css } from 'styled-components';

// theme
import { colors } from './theme';

export default css`
  /* General button styling */
  button {
    font-family: 'SofiaProBold', 'Helvetica', sans-serif;
    background: transparent;
    border-radius: 4px;
    font-size: 1.5rem;
    line-height: 20px;
    text-transform: capitalize;

    height: 35px;
    padding: 0 15px 3px 15px;
    letter-spacing: 0.5px;

    :hover {
      transition: 0.3s;
      cursor: pointer;
    }

    :focus {
      outline: none;
    }
  }

  .invert-button {
    color: ${colors.purple};
    border: solid 1px ${colors.purple};
    background: ${colors.white};

    :hover {
      background: ${colors.palePurple};
    }

    :focus {
      outline: none;
      background: ${colors.lightPurple};
      color: ${colors.white};
    }
  }

  .disabled-button {
    background: ${colors.paleGrey};
    border: 1px solid ${colors.paleGrey};
    color: ${colors.mediumGrey};

    :hover {
      background: ${colors.paleGrey};
      cursor: auto;
    }
  }

  .delete-button {
    :hover {
      background: ${colors.warningRed};
      border: solid 1px ${colors.warningRed};
    }
  }

  .black-button {
    background: ${colors.darkGrey};
    border: 1px solid ${colors.darkGrey};
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 2px 0 0 5px;
    }

    :hover {
      background: ${colors.black};
    }
  }

  .white-button {
    background: ${colors.white};
    color: ${colors.darkGrey};

    :hover,
    :focus {
      background: ${colors.paleGrey};
    }
  }

  .remove-button-styles {
    font-size: 1.6rem;
    font-family: inherit;
    border: 0;
    padding: 0;
  }

  .survey-menu-button {
    font-family: inherit;
    color: ${colors.mediumGrey};

    padding: 0 10px;
    float: right;
    :hover,
    :focus {
      background: ${colors.paleGrey};
    }

    svg {
      margin: 2px 0 0 7px;
      transform: rotate(-90deg);
    }
  }

  .survey-filled-button {
    background: ${colors.purple};
    color: ${colors.white};

    float: right;
    :hover {
      background: ${colors.darkPurple};
    }

    :focus {
      background: ${colors.lightPurple};
    }

    svg {
      margin: 2px 0 0 7px;
      transform: rotate(-90deg);
    }
  }
`;
