import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../store/contexts';
import { SET_CANVAS_CONFIG } from '../store/constants';

export const useCanvasAdjust = () => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { canvasConfig } = configState;
  const { scale } = canvasConfig;

  const setCanvasScale = (newScale: number) => {
    configDispatch({
      type: SET_CANVAS_CONFIG,
      payload: { ...canvasConfig, scale: +newScale.toFixed(2) },
    });
  };

  const increaseScale = () => {
    if (scale <= 1.2) setCanvasScale(scale + 0.1);
  };

  const decreaseScale = () => {
    if (scale >= 0.4) setCanvasScale(scale - 0.1);
  };

  return { increaseScale, decreaseScale };
};

// scale * return must equal 1 to account for scale level on drag calculation
export const getScaleAdjustment = (scale: number) => {
  if (scale === 1.3) return 0.77; // confirm
  if (scale === 1.2) return 0.83;
  if (scale === 1.1) return 0.9;
  if (scale === 0.9) return 1.11;
  if (scale === 0.8) return 1.25;
  if (scale === 0.7) return 1.43;
  if (scale === 0.6) return 1.665;
  if (scale === 0.5) return 2;
  if (scale === 0.4) return 2.5;
  if (scale === 0.3) return 3.33;

  // scale === 1
  return 1;
};
