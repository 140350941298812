import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ContextMenu from '../../../components/General/ContextMenu';
import Loader from '../../../components/General/Loader';
import TabButton from './TabButton';

// data management
import { configContext, elementContext } from '../../../store/contexts';
import {
  SET_ACTIVE_VIEW,
  SET_NOTIFICATION_CONFIG,
  SET_ACTIVE_LAYER_ID,
} from '../../../store/constants';

// utility
import focusInputId from '../../../util/focusInputId';
import useContextMenu from '../../../hooks/useContextMenu';
import validateTitle from '../../../util/validateTitle';
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';
import useGetRequest from '../../../requestHooks/useGetRequest';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  layer: Object,
  layers: Array<Object>,
};

const Layer = ({ layer, layers }: propTypes) => {
  const { layerId, boardId } = layer;

  const [title, setTitle] = useState(layer.title);

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { contextMenuOpen, contextMenuPosition, modalConfig, activeLayerId } = configState;
  const { contextMenuX, contextMenuY, contextMenuId } = contextMenuPosition;
  // element store
  const [elementStore] = useStateValue(elementContext);
  const { layerLoading } = elementStore;

  // context menu
  const { openContextMenu } = useContextMenu();

  // destructure request utility
  const update = useUpdateRequest();
  const getRequest = useGetRequest();

  // update element on change
  const updateLayer = async () => {
    // Checks if duplicate layer title exists.
    const validTitle = validateTitle(layers, title);

    if (title !== layer.title && !validTitle) {
      const notification = {
        notificationOpen: true,
        message: 'Duplicate layer title found. Please try again.',
        type: 'error',
      };

      // trigger error notification
      configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });

      // focus input back on board title
      focusInputId(`layer${layerId}`);
    }

    // send request and update store
    if (validTitle) update('table', 'layer', `/layers/${layerId}`, { title });
  };

  const selectLayer = async () => {
    configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' });

    // set active layerId to newly selected layerId
    configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });

    // send get request and set data to store
    await getRequest('element', 'layer', `/elements/qualities/board/${boardId}/layer/${layerId}`);
  };

  if (activeLayerId === layerId) {
    return (
      <Styles
        className="active-layer-tab layer-tab"
        onContextMenu={e => {
          if (activeLayerId === layerId) openContextMenu(e, layerId); // right click
        }}
      >
        {layerLoading ? (
          <Loader width={20} height={20} />
        ) : (
          <>
            <input
              type="text"
              value={title}
              aria-label={title || 'empty layer title'}
              className="layer-title"
              id={`layer${layerId}`}
              onBlur={() => {
                if (title !== layer.title) updateLayer();
              }}
              onChange={e => {
                if (e.target.value.length < 16) setTitle(e.target.value);
              }}
              onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' })}
              onKeyDown={e => {
                if (e.keyCode === 27) e.target.blur(); // escape key
                if (e.keyCode === 13) e.target.blur(); // return key
              }}
            />
            <span
              style={{
                borderBottom:
                  modalConfig.layerId === layerId
                    ? `4px solid ${colors.warningRed}`
                    : `4px solid ${colors.purple}`,
              }}
            />
          </>
        )}

        {/* Display context menu if right click occurs */}
        {contextMenuOpen && contextMenuId === layerId && (
          <ContextMenu contextMenuX={contextMenuX} contextMenuY={contextMenuY} layerId={layerId} />
        )}
      </Styles>
    );
  }

  return <TabButton event={selectLayer} title={title} />;
};

export default Layer;

const Styles: any = styled.button`
  .layer-title {
    margin-top: -1px;
  }
`;
