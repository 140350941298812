import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { elementContext, configContext } from '../../../store/contexts';
import {
  SET_TITLE,
  SET_HIGHLIGHT,
  SET_MENU_TARGET,
  SET_ACTIVE_VIEW,
} from '../../../store/constants';

type propTypes = {
  updateElement: Function,
  element: Object,
  elements: Array<Object>,
  inputValue: string,
  setInputValue: Function,
  inputWidth: number,
  setInputWidth: Function,
};

const Title = ({
  updateElement,
  element,
  elements,
  inputValue,
  setInputValue,
  inputWidth,
  setInputWidth,
}: propTypes) => {
  const { elementId } = element;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { activeEdit } = elementState;

  // if element title updates on another component, sync local input values
  useEffect(() => {
    if (activeEdit.elementId === elementId) {
      setInputWidth(activeEdit.title.length * 7.2 + 80);
      setInputValue(activeEdit.title);
    }
  }, [activeEdit.title, activeEdit.elementId, elementId, setInputValue, setInputWidth]);

  return (
    <Styles
      data-testid={`canvas${elementId}`}
      id={`canvas${elementId}`}
      value={(activeEdit.elementId === elementId && activeEdit.title) || inputValue}
      aria-label={inputValue || 'empty canvas element'}
      placeholder={elements[0].elementId === elementId ? 'Type here...' : ''}
      style={{ width: inputWidth - 20 || null }}
      onFocus={() => {
        configDispatch({ type: SET_HIGHLIGHT, payload: elementId });
        configDispatch({ type: SET_MENU_TARGET, payload: elementId });
        configDispatch({ type: SET_ACTIVE_VIEW, payload: 'canvas' });
      }}
      onBlur={() => {
        updateElement(activeEdit);
        configDispatch({ type: SET_HIGHLIGHT, payload: undefined });
      }}
      onChange={e => {
        setInputValue(e.target.value);
        elementDispatch({ type: SET_TITLE, payload: { elementId, title: e.target.value } });
        setInputWidth(e.target.value.length * 7.3 + 50);
      }}
      onKeyDown={e => {
        if (e.keyCode === 27) e.target.blur(); // escape key
        if (e.keyCode === 13) e.target.blur(); // return key
      }}
    />
  );
};

export default Title;

const Styles: any = styled.input`
  min-width: 108px;
  text-align: center;
  margin-right: 10px;
  background: transparent;
  padding: 0 5px 2px 0;
`;
