import React from 'react';

// $FlowFixMe react-router-dom
import { Link as RouterLink } from 'react-router-dom';

type propTypes = {
  to: string,
  children: Function,
  onClick?: Function,
  testId?: string,
  tabIndex?: string,
  role?: string,
  className?: string,
};

const Link = ({ to, children, onClick, testId, tabIndex, role, className }: propTypes) => {
  return (
    <RouterLink
      to={to}
      onClick={onClick}
      data-testid={testId}
      className={className}
      tabIndex={tabIndex}
      role={role}
    >
      {children}
    </RouterLink>
  );
};

export default Link;

Link.defaultProps = {
  onClick: () => {},
  testId: '',
  tabIndex: '',
  role: '',
  className: '',
};
