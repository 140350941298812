import React from 'react';
import { useStateValue } from 'react-conflux/dist/cjs';
import styled from 'styled-components';

// component
import Button from './Button';

// data management
import { profileContext } from '../../store/contexts';

// theme
import { colors } from '../../styles/theme';

// utility
import useContextMenu from '../../hooks/useContextMenu';

const SignInDropdown = () => {
  // profile store
  const [profileState] = useStateValue(profileContext);
  const { userId } = profileState.user;

  const { closeContextMenu } = useContextMenu();

  if (userId) {
    return (
      <Styles>
        <p>You can only view this board.</p>
        <p>Like what you see? Start your own BrainSquall!</p>

        <Button
          className="invert-button"
          text="view dashboard"
          redirect="dashboard"
          event={closeContextMenu}
        />
      </Styles>
    );
  }

  return (
    <Styles>
      <p>You can only view this board.</p>
      <p>Like what you see? Start your own BrainSquall!</p>

      <Button
        className="invert-button"
        text="sign in"
        redirect="dashboard"
        event={closeContextMenu}
      />
      <Button text="create account" redirect="dashboard" event={closeContextMenu} />
    </Styles>
  );
};

export default SignInDropdown;

const Styles: any = styled.div`
  position: fixed;
  top: 50px;
  right: 38px;

  width: 232px;
  box-shadow: ${colors.boxShadow};
  background: ${colors.white};
  border-radius: 4px;
  padding: 16px;

  z-index: 1;

  button {
    margin-bottom: 10px;
    width: 100%;
  }

  p {
    font-size: 1.2rem;
  }
`;
