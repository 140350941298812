import React, { useEffect } from 'react';
import styled from 'styled-components';
// $FlowFixMe react-router-dom
import { Route, Switch } from 'react-router-dom';
import { useStateValue } from 'react-conflux';

// components
import BoardSelection from '../../Pages/BoardSelection';
import HandleRedirect from './HandleRedirect';
import Header from './Header';
import BoardView from '../../Pages/BoardView';
import Modal from './Modal';
import Notification from './Notification';
import Settings from '../../Pages/Settings';
import NotFound from '../../Pages/NotFound';

// auth - $FlowFixMe
import { useAuth0 } from '../../auth/AuthWrapper';

// data management
import { configContext, profileContext } from '../../store/contexts';

// util
import { useCopyBoardFromUrl } from '../../hooks/useCopyBoard';
import { SET_MODAL_CONFIG } from '../../store/constants';

const Dashboard = () => {
  // authentication check from auth0
  const { isAuthenticated, loginWithRedirect, loading } = useAuth0();

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { modalConfig } = configState;

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { user } = profileState;

  const { pathname } = window.location;

  // when accessing Dashboard component, invoke authentication
  // NOTE: login_hint works for pre-populating the email address
  useEffect(() => {
    const isSignup = pathname.includes('/signup');
    const options = isSignup ? { screen_hint: 'signup' } : {};

    if (!isAuthenticated && !loading) loginWithRedirect(options);
  }, [isAuthenticated, loginWithRedirect, loading, pathname]);

  // trigger board copy from URL if {pathname} prompts board copy
  useCopyBoardFromUrl();
  // TODO: Fix url after board copies

  useEffect(() => {
    if (!user.acRoleId)
      configDispatch({ type: SET_MODAL_CONFIG, payload: { open: true, type: 'surveyForm' } });
  }, [user, configDispatch]);

  // is user is not authenticated, return Fragment
  if (!isAuthenticated) return <></>;
  if (isAuthenticated && pathname.includes('/signup')) return <HandleRedirect path="/" />;

  // internal dashboard routing -- must be authenticated to access
  return (
    <Styles>
      {pathname === '/dashboard/board' ? <div /> : <Header />}
      <Switch>
        <Route exact path="/dashboard" component={BoardSelection} />
        <Route path="/dashboard/board" component={BoardView} />
        <Route path="/dashboard/settings/account" component={Settings} />
        <Route exact path="/dashboard/template-copy/:boardPublicKey" component={BoardSelection} />

        <Route component={NotFound} />
      </Switch>

      {/* display deletion modal if activated */}
      {modalConfig.open && <Modal modalConfig={modalConfig} />}

      {/* notification modal -- automatically closes after 5 seconds. */}
      <Notification />
    </Styles>
  );
};

export default Dashboard;

const Styles: any = styled.div`
  width: 100%;
`;
