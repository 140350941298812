import React from 'react';
import styled from 'styled-components';

// component
import Tooltip from '../../../components/General/Tooltip';

// utility
import { useCanvasAdjust } from '../../../hooks/useCanvasUtility';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  resetPosition: Function,
};

const AdjustmentButtons = ({ resetPosition }: propTypes) => {
  // get zoom utility functions for canvas
  const { increaseScale, decreaseScale } = useCanvasAdjust();

  return (
    <Styles>
      <div className="zoom-buttons">
        <Tooltip title="zoom in" position="right">
          <button type="button" onClick={increaseScale}>
            +
          </button>
        </Tooltip>

        <Tooltip title="zoom out" position="right">
          <button type="button" onClick={decreaseScale}>
            -
          </button>
        </Tooltip>
      </div>

      <Tooltip title="center root element" position="right">
        <button type="button" onClick={resetPosition}>
          ↻
        </button>
      </Tooltip>
    </Styles>
  );
};

export default AdjustmentButtons;

const Styles: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 95px;
  margin-left: 10px;
  z-index: 1;

  button {
    background: ${colors.canvasPurple};
    color: ${colors.white};
    font-size: 2rem;

    width: 25px;
    height: 32px;
    margin: 0 !important;
    padding: 0 0 5px 0 !important;

    border: none;

    :hover {
      background: ${colors.purple};
    }
  }

  .zoom-buttons {
    display: flex;
    flex-direction: column;

    margin-bottom: 5px;

    :first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      span {
        margin-bottom: 1px !important;
      }
    }

    :last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;
