import { useEffect, useRef } from 'react';
import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../store/contexts';

const useViewSync = () => {
  // config store
  const [configState] = useStateValue(configContext);
  const { activeEditId, isSync } = configState;

  // $FlowFixMe - define element reference
  const syncRef = useRef(null);

  // smooth dragging for view sync
  useEffect(() => {
    if (isSync) {
      const ref = syncRef.current;
      const outlineRef = ref && ref.id === 'outline-ref' && ref;
      const tableRef = ref && ref.id === 'table-ref' && ref;

      if (tableRef) tableRef.scrollIntoView({ block: 'center', inline: 'center' });

      if (outlineRef) outlineRef.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [activeEditId, isSync]);

  return syncRef;
};

export default useViewSync;
