import {
  GET_BOARD_START,
  GET_BOARD_SUCCESS,
  GET_BOARD_FAIL,
  GET_BOARDS_START,
  GET_BOARDS_SUCCESS,
  GET_BOARDS_FAIL,
  ADD_BOARD_START,
  ADD_BOARD_SUCCESS,
  ADD_BOARD_FAIL,
  UPDATE_BOARD_START,
  UPDATE_BOARD_SUCCESS,
  UPDATE_BOARD_FAIL,
  DELETE_BOARD_START,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_FAIL,
  SELECT_BOARD,
  SET_INITIAL_BOARD_LOADING,
} from './constants';

const initialState = {
  board: {},
  boards: [],
  selectedBoardId: undefined,
  boardsLoading: true,
  initialBoardLoading: true,
  addBoardIsLoading: false,
  isLoading: false,
  error: '',
};

const globalReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case GET_BOARD_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case GET_BOARD_SUCCESS:
      return {
        ...state,
        board: action.payload,
        isLoading: false,
        error: '',
      };

    case GET_BOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to fetch board.',
      };

    case GET_BOARDS_START:
      return {
        ...state,
        boardsLoading: true,
        error: '',
      };

    case GET_BOARDS_SUCCESS:
      return {
        ...state,
        boards: action.payload.sort((a, b) => (a.boardId > b.boardId ? 1 : -1)),
        boardsLoading: false,
        error: '',
      };

    case GET_BOARDS_FAIL:
      return {
        ...state,
        boardsLoading: false,
        error: 'Failed to fetch boards.',
      };

    case ADD_BOARD_START:
      return {
        ...state,
        addBoardIsLoading: true,
        error: '',
      };

    case ADD_BOARD_SUCCESS:
      return {
        ...state,
        boards: [...state.boards, action.payload],
        addBoardIsLoading: false,
        error: '',
      };

    case ADD_BOARD_FAIL:
      return {
        ...state,
        addBoardIsLoading: false,
        error: 'Failed to add board.',
      };

    case UPDATE_BOARD_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case UPDATE_BOARD_SUCCESS:
      return {
        ...state,
        board: {
          ...state.board,
          ...action.payload,
        },
        boards: state.boards.map(board => {
          if (board.boardId && action.payload) {
            const payloadMatch = board.boardId === action.payload.boardId;

            if (payloadMatch) return { ...state.board, ...action.payload };
          }
          return board;
        }),
        isLoading: false,
        error: '',
      };

    case UPDATE_BOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to update board.',
      };

    case DELETE_BOARD_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case DELETE_BOARD_SUCCESS:
      return {
        ...state,
        boards: [...state.boards.filter(board => board.boardId !== action.payload)],
        isLoading: false,
        error: '',
      };

    case DELETE_BOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'Failed to delete board.',
      };

    case SELECT_BOARD:
      return {
        ...state,
        selectedBoardId: action.payload.boardId,
        board: action.payload,
      };

    case SET_INITIAL_BOARD_LOADING:
      return {
        ...state,
        initialBoardLoading: false,
      };

    default:
      return state;
  }
};

export default globalReducer;
