import { useEffect, useCallback } from 'react';
import { useStateValue } from 'react-conflux';

// data management
import { SET_CONTEXT_MENU_OPEN, SET_CONTEXT_MENU_POSITION } from '../store/constants';
import { configContext } from '../store/contexts';

const useContextMenu = () => {
  // config state
  const [configState, configDispatch] = useStateValue(configContext);
  const { contextMenuOpen } = configState;

  const openContextMenu = (e: Object, targetId: number, xOffset: number = 0) => {
    // prevent default context menu from opening
    e.preventDefault();

    // dispatch contextMenu open/close state and position to open
    configDispatch({ type: SET_CONTEXT_MENU_OPEN, payload: true });
    configDispatch({
      type: SET_CONTEXT_MENU_POSITION,
      payload: {
        contextMenuX: e.clientX + xOffset,
        contextMenuY: e.clientY,
        contextMenuId: targetId,
      },
    });
  };

  const closeContextMenu = useCallback(() => {
    configDispatch({ type: SET_CONTEXT_MENU_OPEN, payload: false });
  }, [configDispatch]);

  // attach close menu listeners when context menu is opened
  useEffect(() => {
    if (contextMenuOpen) {
      document.addEventListener('keydown', closeContextMenu, false);
      document.addEventListener('click', closeContextMenu, false);
    }

    return () => {
      document.removeEventListener('keydown', closeContextMenu, false);
      document.removeEventListener('click', closeContextMenu, false);
    };
  }, [closeContextMenu, contextMenuOpen]);

  return { openContextMenu, closeContextMenu };
};

export default useContextMenu;
