import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Link from '../../General/Link';
import Logout from '../../General/Logout';

// data management
import { profileContext } from '../../../store/contexts';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  menuOpen: boolean,
  setMenuOpen: Function,
};

const AccountMenu = ({ menuOpen, setMenuOpen }: propTypes) => {
  // profile store
  const [profileState] = useStateValue(profileContext);
  const { planId } = profileState.chargebee;

  const styles = { top: planId === 'beta-trial' ? '40px' : '80px' };

  if (!menuOpen) return <></>;

  return (
    <Styles
      className="context-menu"
      onClick={() => setMenuOpen(false)}
      role="menu"
      aria-haspopup="true"
      styles={styles}
    >
      <Link to="/dashboard/settings/account" role="menuitem">
        <li role="menuitem">My Account</li>
      </Link>

      <Logout />
    </Styles>
  );
};

export default AccountMenu;

const Styles: any = styled.ul`
  display: fixed;
  right: 15px;

  width: 170px;

  border-radius: 4px;
  box-shadow: ${colors.boxShadow};

  a {
    :hover {
      background: ${colors.paleGrey};
    }

    :focus {
      outline: none;
      background: ${colors.paleGrey};
    }

    li,
    button {
      display: flex;
      align-items: center;
      border: 0 !important;

      margin: 0;
      padding: 0 15px;

      :hover {
        color: ${colors.darkGrey};
        background: ${colors.paleGrey};
      }
    }
  }

  button {
    color: ${colors.darkGrey};
    border: 0 !important;

    :hover {
      background: ${colors.paleGrey};
    }
  }
`;
