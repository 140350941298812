import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import Axios from '../../../config/axios_config';

// components
import Title from './Title';
import Quality from './Quality';
import TutorialDropdown from '../TutorialDropdown';

// data management
import { elementContext, configContext, profileContext } from '../../../store/contexts';
import {
  ADD_ELEMENT_START,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  UPDATE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_START,
  DELETE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_FAIL,
  SET_USER_COUNTS,
} from '../../../store/constants';

// utility
import generatePON from '../../../util/generatePON';
import useContextMenu from '../../../hooks/useContextMenu';
import focusInputId from '../../../util/focusInputId';
import { generateXPosition, generateYPosition } from '../../../util/canvasMath';
import { generateLeftMargin } from '../../../util/outlineHelpers';
import useFilterChildren from '../../../hooks/useFilterChildren';
import { useFindPreviousElement, useFindParentElement } from '../../../hooks/useFindElement';
import useHighlightDeleting from '../../../hooks/useHighlightDeleting';
import useDetermineBackground from '../../../hooks/useDetermineBackground';
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';
import usePostRequest from '../../../requestHooks/usePostRequest';
import useFreemium from '../../../hooks/useFreemium';
import useViewSync from '../../../hooks/useViewSync';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  index: number,
  element: Object,
  textQuality: Object,
  updateElement: Function,
};

const Element = ({ index, element, textQuality, updateElement }: propTypes) => {
  const { elementId, boardId, title, PON, childrenPON, parentId } = element;

  // profile store
  const [profileState, profileDispatch] = useStateValue(profileContext);
  const { userCounts } = profileState;
  const { totalElements } = userCounts;

  // config store
  const [configState] = useStateValue(configContext);
  const { activeLayerId, activeEditId } = configState;

  // custom menu hook
  const { openContextMenu } = useContextMenu();

  // set initial title from database, if title is updated, update the local title
  const [inputValue, setInputValue] = useState(title);

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { activeEdit, elements } = elementState;

  // if element title updates on another component, sync local input values
  useEffect(() => {
    if (activeEdit.elementId === elementId) setInputValue(activeEdit.title);
  }, [activeEdit.title, activeEdit.elementId, elementId]);

  // destructure utility functions from hooks
  const [findParentElement] = useFindParentElement();
  const [filterChildren] = useFilterChildren();
  const [findPreviousElement] = useFindPreviousElement();

  // request management
  const [inProgress, setInProgress] = useState(false);

  // when delete modal is open, get ids of elements on pending deletion branch.
  const { getPendingDeletion } = useHighlightDeleting();
  const pendingDeletion = getPendingDeletion();

  // destructure determine background
  const determineBackground = useDetermineBackground();

  // destructure request utility
  const update = useUpdateRequest();
  const postRequest = usePostRequest();

  // destructure checkElementCount
  const { checkElementCount } = useFreemium();

  // create a sibling element to the current element
  const addSiblingElement = async () => {
    const parent = findParentElement(element);

    if (!inProgress) {
      const allowPost = checkElementCount();

      if (allowPost) {
        try {
          // post new element with parentId connection to previous element
          const newPON = generatePON(parent);

          // start request and state updates
          setInProgress(true);

          elementDispatch({ type: ADD_ELEMENT_START });
          const { data } = await Axios.post(
            `/elements/board/${boardId}/parent/${parentId}/layer/${activeLayerId}`,
            {
              title: '',
              PON: newPON,
              xCoord: generateXPosition(parent),
              yCoord: generateYPosition(parent),
              parentId,
            }
          );

          // dispatch newly added element
          if (data) {
            elementDispatch({ type: ADD_ELEMENT_SUCCESS, payload: data });
            elementDispatch({
              type: UPDATE_ELEMENT_SUCCESS,
              payload: {
                ...parent,
                childrenPON: [...parent.childrenPON, newPON],
              },
            });

            // update focus to new element
            focusInputId(`outline${data.elementId}`);

            // finish request and state updates
            setInProgress(false);
          }
        } catch (error) {
          elementDispatch({ type: ADD_ELEMENT_FAIL });
          setInProgress(false);
        }
      }
    }
  };

  // from root element, add child element
  const addChildElement = async () => {
    if (!inProgress) {
      const allowPost = checkElementCount();

      if (allowPost) {
        try {
          const newPON = generatePON(element);

          // start request and state updates
          setInProgress(true);

          elementDispatch({ type: ADD_ELEMENT_START });
          const { data } = await Axios.post(
            `/elements/board/${boardId}/parent/${elementId}/layer/${activeLayerId}`,
            {
              title: '',
              PON: newPON,
              xCoord: generateXPosition(element),
              yCoord: generateYPosition(element),
              parentId: elementId,
            }
          );

          // dispatch newly added element
          if (data) {
            elementDispatch({ type: ADD_ELEMENT_SUCCESS, payload: data });

            // update focus to new element
            focusInputId(`outline${data.elementId}`);

            // update
            elementDispatch({
              type: UPDATE_ELEMENT_SUCCESS,
              payload: {
                ...element,
                childrenPON: [...element.childrenPON, newPON],
              },
            });

            // finish request and state updates
            setInProgress(false);
          }
        } catch (error) {
          elementDispatch({ type: ADD_ELEMENT_FAIL });
          setInProgress(false);
        }
      }
    }
  };

  // update a sibling element to a child element
  const updateElementToChild = async e => {
    // prevent focus from changing
    e.preventDefault();

    const previous = findPreviousElement(index);
    const parent = findParentElement(element);

    if (previous) {
      const newPON = generatePON(previous);

      // post new element with parentId connection to previous element
      const updatedElement = { PON: newPON, parentId: previous.elementId };

      const success = update(
        'element',
        'element',
        `/elements/${elementId}/layer/${activeLayerId}`,
        updatedElement
      );

      if (success) {
        // update previous element childrenPON to include updated element
        elementDispatch({
          type: UPDATE_ELEMENT_SUCCESS,
          payload: { ...previous, childrenPON: [...previous.childrenPON, newPON] },
        });

        // update parent element childrenPON to remove updated element
        elementDispatch({
          type: UPDATE_ELEMENT_SUCCESS,
          payload: { ...parent, childrenPON: filterChildren(element) },
        });
      }
    }
  };

  // update a child element to a sibling
  const updateElementToSibling = e => {
    // prevent focus from changing
    e.preventDefault();

    const previous = findPreviousElement(index);
    const parentOfPrevious = findParentElement(previous);

    if (parentOfPrevious) {
      const newPON = generatePON(parentOfPrevious);

      // update element with parentId connection to previous element's parent
      const updatedElement = { PON: newPON, parentId: parentOfPrevious.elementId };

      const success = update(
        'element',
        'element',
        `/elements/${elementId}/layer/${activeLayerId}`,
        updatedElement
      );

      if (success) {
        // update previous element childrenPON to exclude updated element
        elementDispatch({
          type: UPDATE_ELEMENT_SUCCESS,
          payload: { ...previous, childrenPON: filterChildren(element) },
        });

        // update previous element's parent childrenPON to add updated element
        elementDispatch({
          type: UPDATE_ELEMENT_SUCCESS,
          payload: { ...parentOfPrevious, childrenPON: [...parentOfPrevious.childrenPON, newPON] },
        });
      }
    }
  };

  const deleteElement = async () => {
    if (elements.length !== 1 && elementId && childrenPON.length === 0) {
      const previous = findPreviousElement(index);
      const parent = findParentElement(element);

      if (!inProgress && previous) {
        try {
          // start request and state updates
          setInProgress(true);

          elementDispatch({ type: DELETE_ELEMENT_START });
          const { status } = await Axios.delete(`/elements/${elementId}`);

          if (status === 202) {
            // dispatch element count update
            profileDispatch({
              type: SET_USER_COUNTS,
              payload: { ...userCounts, totalElements: totalElements - 1 },
            });

            // focus on previous element
            focusInputId(`outline${previous.elementId}`);

            // updated elements for dispatch
            const updatedElements = elements.filter(el => el.PON !== PON);

            // dispatch updated elements
            elementDispatch({ type: DELETE_ELEMENT_SUCCESS, payload: updatedElements });

            // update parent element childrenPON to remove updated element
            elementDispatch({
              type: UPDATE_ELEMENT_SUCCESS,
              payload: { ...parent, childrenPON: filterChildren(element) },
            });

            // finish request and state updates
            setInProgress(false);
          }
        } catch (error) {
          elementDispatch({ type: DELETE_ELEMENT_FAIL });
          setInProgress(false);
        }
      }
    }
  };

  // post textQuality to parent element
  const addText = async () => {
    // send post request and set data to store
    const data = await postRequest('element', 'simple_quality', '/qualities', {
      text: '',
      elementId,
      layerId: activeLayerId,
    });

    if (data) {
      // update parent element with new text quality and filtered childrenPON numbers
      elementDispatch({
        type: UPDATE_ELEMENT_SUCCESS,
        payload: { ...element, textQuality: data },
      });

      focusInputId(`textQuality${data.qualityId}`);
    }
  };

  const syncRef = useViewSync();

  return (
    <Styles onContextMenu={e => openContextMenu(e, elementId)} style={generateLeftMargin(PON)}>
      <div
        ref={syncRef}
        className={activeEditId === elementId ? 'active-element-outline' : null}
        id={activeEditId === elementId ? 'outline-ref' : null}
        style={{
          border: pendingDeletion.includes(elementId) && `2px solid ${colors.warningRed}`,
          background: determineBackground(element),
        }}
      >
        {PON.length > 1 && <span className="PON">{PON.slice(2)}</span>}
        <Title
          index={index}
          element={element}
          elements={elements}
          activeEdit={activeEdit}
          updateElement={updateElement}
          inputValue={inputValue}
          setInputValue={setInputValue}
          addChildElement={addChildElement}
          addSiblingElement={addSiblingElement}
          addText={addText}
          updateElementToChild={updateElementToChild}
          updateElementToSibling={updateElementToSibling}
          deleteElement={deleteElement}
        />
      </div>

      <TutorialDropdown element={element} inputValue={inputValue} />

      {/* display text quality if present */}
      {textQuality && (
        <Quality key={textQuality.qualityId} textQuality={textQuality} element={element} />
      )}
    </Styles>
  );
};

export default Element;

const Styles: any = styled.div`
  margin-bottom: 5px;

  div {
    display: flex;
    align-items: center;
    border: 2px solid ${colors.white};
  }

  .PON {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    margin-right: 5px;
    font-weight: bold;
    height: 30px;
    border: 2px solid transparent;
  }

  input {
    width: 100%;
    font-weight: bold;
  }
`;
