import React, { useState } from 'react';
import styled from 'styled-components';

// components
import InputWithLabel from '../../../components/General/InputWithLabel';
import Tooltip from '../../../components/General/Tooltip';

// util
import useUpdateRequest from '../../../requestHooks/useUpdateRequest';

type propTypes = {
  user: Object,
};

const AccountDetails = ({ user }: propTypes) => {
  const { userId, username, email } = user;
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  // destructure request
  const update = useUpdateRequest();

  const updateName = async () => {
    // update name request
    await update('profile', 'user', `/users/${userId}`, { email, firstName, lastName });
  };

  return (
    <Styles className="settings-container">
      <h3 className="container-title">Account Details</h3>

      <div className="container-content">
        <div className="name-fields">
          <InputWithLabel
            label="First Name:"
            placeholder=""
            value={firstName}
            setValue={setFirstName}
            handleBlur={() => {
              if (firstName !== user.firstName) updateName();
            }}
          />
          <InputWithLabel
            label="Last Name:"
            placeholder=""
            value={lastName}
            setValue={setLastName}
            handleBlur={() => {
              if (lastName !== user.lastName) updateName();
            }}
          />
        </div>
        <p>
          <span>Username:</span>{' '}
          <Tooltip title="You cannot change your username at this time.">{username}</Tooltip>
        </p>
        <p>
          <span>Email:</span>{' '}
          <Tooltip title="You cannot change your email at this time.">{email}</Tooltip>
        </p>
      </div>
    </Styles>
  );
};

export default AccountDetails;

const Styles: any = styled.div`
  .name-fields {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 0;
    }
  }
`;
