import React from 'react';
// $FlowFixMe react-router-dom
import { useParams } from 'react-router-dom';

// components
import Button from '../../../components/General/Button';
import Tooltip from '../../../components/General/Tooltip';

// data management
import { useCopyBoard } from '../../../hooks/useCopyBoard';

// util
import { decodePublicKey } from '../../../util/publicKey';

type propTypes = {
  board: Object,
  isPublicRoute: boolean,
};

// TODO: Update logic with design flow -- currently, when the user
// is not logged in they cannot copy the board. The user currently
// no has indication of why this is disabled.
const CopyButton = ({ board, isPublicRoute }: propTypes) => {
  const { boardId, isCopyable } = board;

  // get boardPublicKey from url params
  const { boardPublicKey } = useParams();

  const copyBoard = useCopyBoard();

  const copy = () => {
    // decode boardPublicKey to get public boardId if available, otherwise use internal boardId
    const id = boardPublicKey ? decodePublicKey(boardPublicKey) : boardId;

    copyBoard(id);
  };

  // icon with tooltip

  // owner of board should always be able to copy
  let disableCopy = false;
  // public board should only be able to copy when isCopyable is true
  if (!isCopyable && isPublicRoute) disableCopy = true;

  return (
    <Tooltip title={disableCopy ? 'This feature has been disabled by the board owner.' : ''}>
      <Button
        text="copy board"
        event={copy}
        style={{ marginRight: 10 }}
        redirect="dashboard"
        disabled={disableCopy}
        className={disableCopy ? 'disabled-button' : ''}
      />
    </Tooltip>
  );
};

export default CopyButton;
