import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../../../store/contexts';
import { SET_HIGHLIGHT } from '../../../store/constants';

// components
import ReadOnlyTitle from './Title';
import ReadOnlyQuality from './Quality';

// utility
import { generateLeftMargin } from '../../../util/outlineHelpers';
import useDetermineBackground from '../../../hooks/useDetermineBackground';
import useViewSync from '../../../hooks/useViewSync';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  element: Object,
  textQuality: Object,
};

const ReadOnlyElement = ({ element, textQuality }: propTypes) => {
  const { elementId, title, PON } = element;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeEditId } = configState;

  // destructure determine background
  const determineBackground = useDetermineBackground();

  const syncRef = useViewSync();

  return (
    <Styles
      style={generateLeftMargin(PON)}
      onClick={() => configDispatch({ type: SET_HIGHLIGHT, payload: elementId })}
    >
      <div
        ref={syncRef}
        id={activeEditId === elementId ? 'outline-ref' : null}
        className={
          activeEditId === elementId
            ? 'read-only-element active-element-outline'
            : 'read-only-element'
        }
        style={{ background: determineBackground(element) }}
      >
        {PON.length > 1 && <span className="PON">{PON.slice(2)}</span>}
        <ReadOnlyTitle element={element} inputValue={title} />
      </div>

      {/* display text quality if present */}
      {textQuality && <ReadOnlyQuality key={textQuality.qualityId} textQuality={textQuality} />}
    </Styles>
  );
};

export default ReadOnlyElement;

const Styles: any = styled.div`
  margin-bottom: 5px;

  div {
    display: flex;
    align-items: center;
    border: 2px solid ${colors.white};
  }

  .PON {
    display: flex;
    align-items: center;
    margin-right: 5px;
    height: 30px;
    border: 2px solid transparent;
    user-select: none;
  }
`;
