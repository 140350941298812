import { useStateValue } from 'react-conflux';

// data management
import { profileContext, configContext } from '../store/contexts';
import { SET_MODAL_CONFIG, SET_USER_COUNTS } from '../store/constants';

// utility
import { getMaxResources } from '../util/freemium';

const useFreemium = () => {
  // profile store
  const [profileState, profileDispatch] = useStateValue(profileContext);
  const { chargebee, userCounts } = profileState;
  const { planId, status } = chargebee;
  const { totalBoards, totalElements, addedLayers } = userCounts;

  const { boardMax, elementMax, addedLayerMax } = getMaxResources(planId, status);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  const checkBoardCount = () => {
    // check total boards
    if (totalBoards >= boardMax) {
      // dispatch notification
      configDispatch({
        type: SET_MODAL_CONFIG,
        payload: { open: true, type: 'freemium', resource: 'boards', count: totalBoards },
      });

      return false;
    }

    // dispatch board count update
    profileDispatch({
      type: SET_USER_COUNTS,
      payload: { ...userCounts, totalBoards: totalBoards + 1, totalElements: totalElements + 1 },
    });
    return true;
  };

  const checkElementCount = () => {
    // check total elements
    if (totalElements >= elementMax) {
      // dispatch modal
      configDispatch({
        type: SET_MODAL_CONFIG,
        payload: { open: true, type: 'freemium', resource: 'elements', count: totalElements },
      });
      return false;
    }

    // dispatch element count update
    profileDispatch({
      type: SET_USER_COUNTS,
      payload: { ...userCounts, totalElements: totalElements + 1 },
    });
    return true;
  };

  const checkLayerCount = () => {
    // check addedLayers
    if (addedLayers >= addedLayerMax) {
      // dispatch modal
      configDispatch({
        type: SET_MODAL_CONFIG,
        payload: { open: true, type: 'freemium', resource: 'layers', count: addedLayers },
      });
      return false;
    }

    // dispatch layer count update
    profileDispatch({
      type: SET_USER_COUNTS,
      payload: { ...userCounts, addedLayers: addedLayers + 1 },
    });
    return true;
  };

  return { checkBoardCount, checkElementCount, checkLayerCount };
};

export default useFreemium;
