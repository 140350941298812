import React from 'react';
import styled from 'styled-components';

// components
import BoardList from './BoardList';
import DashboardButtons from './DashboardButtons';
import Divider from '../../components/General/Divider';

const BoardSelection = () => {
  return (
    <Styles>
      <div className="board-selection-header">
        <h2 data-testid="board-selection-text">My Boards</h2>
        <DashboardButtons />
      </div>

      <Divider />

      <BoardList />
    </Styles>
  );
};

export default BoardSelection;

const Styles: any = styled.div`
  width: calc(100vw - 120px);
  margin: 30px 60px 47px 60px;

  @media (max-width: 550px) {
    width: calc(100vw - 60px);
    margin: 30px;
  }

  .board-selection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-size: 3.2rem;
    margin: 0;
  }
`;
