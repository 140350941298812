import React from 'react';
import styled from 'styled-components';

// components
import Element from './Element';

type propTypes = {
  elements: Array<Object>,
  updateElement: Function,
};

const Content = ({ elements, updateElement }: propTypes) => {
  return (
    <Styles>
      {elements.map((element, index) => {
        const { elementId, textQuality } = element;
        return (
          <Element
            key={elementId}
            element={element}
            index={index}
            textQuality={textQuality}
            updateElement={updateElement}
          />
        );
      })}
    </Styles>
  );
};

export default Content;

const Styles: any = styled.div``;
