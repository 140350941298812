import React from 'react';
import styled from 'styled-components';

// component
import ButtonWithIcon from '../../../General/ButtonWithIcon';

// content
import { roleContent } from './surveyContent';

// asset
import DropdownCarrot from '../../../../assets/DropdownCarrot';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  setPage: Function,
  setSurveyData: Function,
};

const RoleModal = ({ setPage, setSurveyData }: propTypes) => {
  // destructure from roleContent
  const { title, description, roles } = roleContent;

  // update acRoleId. If the form is skipped, set acRoleId to 6.
  const handleClick = acRoleId => {
    setSurveyData(surveyData => ({ ...surveyData, acRoleId }));
    setPage(4);
  };

  return (
    <Styles className="survey-modal-wrapper">
      <h3>{title}</h3>
      <p>{description}</p>
      {roles.map(role => (
        <button
          type="button"
          className="role-button"
          key={role.title}
          onClick={() => handleClick(role.acRoleId)}
        >
          <h2>{role.title}</h2>
          <p>{role.role}</p>
        </button>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonWithIcon
          Icon={DropdownCarrot}
          text="skip"
          className="survey-menu-button"
          position="right"
          color={colors.darkGrey}
          onClick={() => setPage(4)}
        />
      </div>
    </Styles>
  );
};

export default RoleModal;

const Styles: any = styled.div``;
