import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ColumnPositionButtons from './ColumnPositionButtons';

// data management
import { globalContext, configContext } from '../../../../store/contexts';
import { SET_MODAL_CONFIG, SET_CANVAS_CONFIG } from '../../../../store/constants';

// utility
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

type propTypes = {
  contextMenuX: number,
  contextMenuY: number,
  columnId: number,
  setColumnWidth?: Function,
  columnIndex: number,
  setIsHidden: Function,
};

const ContextMenu = ({
  contextMenuX,
  contextMenuY,
  columnId,
  setColumnWidth,
  columnIndex,
  setIsHidden,
}: propTypes) => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { canvasConfig } = configState;
  const { labelId } = canvasConfig;

  // global store
  const [globalState] = useStateValue(globalContext);
  const { selectedBoardId } = globalState;

  // destructure request utility
  const update = useUpdateRequest();

  const resetColumnWidthToDefault = async () => {
    if (columnId && setColumnWidth) {
      const success = update('table', 'column', `/columns/${columnId}`, { width: 160 });

      if (success) setColumnWidth(160);
    }
  };

  const setLabel = async () => {
    // send request and update store
    const success = await update('global', 'board', `/boards/${selectedBoardId}`, {
      labelId: columnId,
    });

    if (success) configDispatch({ type: SET_CANVAS_CONFIG, payload: { labelId: columnId } });
  };

  const removeLabel = async () => {
    // send request and update store
    const data = await update('global', 'board', `/boards/${selectedBoardId}`, { labelId: null });

    if (data) configDispatch({ type: SET_CANVAS_CONFIG, payload: { labelId: null } });
  };

  return (
    <Styles
      style={{ left: contextMenuX, top: contextMenuY, zIndex: 100 }}
      className="context-menu"
      onMouseEnter={() => setIsHidden(true)}
    >
      {labelId === columnId ? (
        <button type="button" onClick={removeLabel}>
          Remove Secondary Label
        </button>
      ) : (
        <button type="button" onClick={setLabel} disabled={labelId === columnId}>
          Set Secondary Label
        </button>
      )}

      <ColumnPositionButtons columnIndex={columnIndex} columnId={columnId} />

      <button type="button" onClick={resetColumnWidthToDefault}>
        Reset Column Width
      </button>

      <button
        type="button"
        onClick={() =>
          configDispatch({
            type: SET_MODAL_CONFIG,
            payload: { open: true, type: 'delete', columnId },
          })
        }
      >
        Delete Column
      </button>
    </Styles>
  );
};

export default ContextMenu;

ContextMenu.defaultProps = {
  setColumnWidth: () => {},
};

const Styles: any = styled.div``;
