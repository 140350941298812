import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { elementContext, configContext } from '../../../store/contexts';

type propTypes = {
  element: Object,
  elementTranslation: Object,
};

const Line = ({ element, elementTranslation }: propTypes) => {
  // element position
  const { elementId, parentId, xCoord, yCoord } = element;
  const { x, y } = elementTranslation;

  // config store
  const [configState] = useStateValue(configContext);
  const { activeDragId } = configState;

  // element state
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // test for line coordinates
  const [parentCoordinates, setParentCoordinates] = useState({ fromX: 0, fromY: 0 });
  const { fromX, fromY } = parentCoordinates;

  const conditionalX1 = elementId === activeDragId ? x : xCoord;
  const conditionalY1 = elementId === activeDragId ? y : yCoord;

  const conditionalX2 = parentId === activeDragId ? x : fromX;
  const conditionalY2 = parentId === activeDragId ? y : fromY;

  // Used to add additional spacing to line coords.
  const centerXCoord = 85;
  const centerYCoord = 40;

  useEffect(() => {
    if (parentId) {
      const parent = elements.find(el => el.elementId === parentId);

      setParentCoordinates({ fromX: parent.xCoord, fromY: parent.yCoord });
    }
  }, [elements, element, parentId]);

  return (
    <Styles
      x1={conditionalX1 + centerXCoord}
      y1={conditionalY1 + centerYCoord}
      x2={conditionalX2 + centerXCoord}
      y2={conditionalY2 + centerYCoord}
    />
  );
};

export default Line;

const Styles: any = styled.line`
  width: 100%;
  height: 100%;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
`;
