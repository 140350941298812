import React from 'react';
import styled from 'styled-components';

// components
import Button from '../../General/Button';
import Header from './Header';

type propTypes = {
  modalConfig: Object,
  closeModal: Function,
};

const FreemiumModal = ({ modalConfig, closeModal }: propTypes) => {
  const { resource } = modalConfig;

  return (
    <Styles>
      <div>
        <Header text="Upgrade Your Plan" closeModal={closeModal} />
        <p>
          You&apos;ve used all available {resource} across your{' '}
          {resource === 'layers' ? 'board(s)' : 'Starter Plan'}. Upgrade your plan to create more{' '}
          {resource}!
        </p>
      </div>
      <Button
        type="button"
        text="See Plans"
        redirect="dashboard/settings/account"
        event={closeModal}
      />
    </Styles>
  );
};

export default FreemiumModal;

const Styles: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
