import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext, tableContext } from '../../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../../store/constants';

// utility
import focusInputId from '../../../../util/focusInputId';
import usePostRequest from '../../../../requestHooks/usePostRequest';

type propTypes = {
  elementId: number,
  columnId: number,
  rowIndex: number,
  columnIndex: number,
};

const EmptyQuality = ({ elementId, columnId, rowIndex, columnIndex }: propTypes) => {
  const [title, setTitle] = useState('');

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeLayerId } = configState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // get width of column as it updates
  const { width } = columns.find(c => c.columnId === columnId);

  // destructure request utility
  const postRequest = usePostRequest();

  const addQuality = async () => {
    // if elementId and columnId exist, create new quality
    if (title.length > 0 && elementId && columnId) {
      const newQuality = { title, elementId, columnId, layerId: activeLayerId };

      // send post request and set data to store
      await postRequest('element', 'quality', '/qualities', newQuality);
    }
  };

  return (
    <Styles
      style={{
        width: width < 80 ? 60 : width - 20,
        minWidth: width < 80 ? 60 : width - 20,
        margin: '0 10px',
      }}
    >
      <input
        id={`tableQuality${rowIndex}${columnIndex}`}
        type="text"
        value={title}
        aria-label={title || 'empty column cell'}
        style={{
          width: width < 80 ? 60 : width - 20,
          minWidth: width < 80 ? 60 : width - 20,
        }}
        onChange={e => setTitle(e.target.value)}
        onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' })}
        onBlur={addQuality}
        onKeyDown={e => {
          if (e.keyCode === 27) e.target.blur(); // escape key
          if (e.keyCode === 13) e.target.blur(); // return key
          if (e.keyCode === 38) focusInputId(`tableQuality${rowIndex - 1}${columnIndex}`); // up arrow
          if (e.keyCode === 38 && rowIndex === 0) focusInputId(`tableColumn${columnId}`); // up arrow
          if (e.keyCode === 40) focusInputId(`tableQuality${rowIndex + 1}${columnIndex}`); // down arrow
        }}
      />
    </Styles>
  );
};

export default EmptyQuality;

const Styles: any = styled.div``;
