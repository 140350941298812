import { useState } from 'react';
import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import {
  globalContext,
  elementContext,
  tableContext,
  configContext,
  profileContext,
} from '../store/contexts';

import { SET_MODAL_CONFIG } from '../store/constants';

// dynamic delete request utility
const useDeleteRequest = () => {
  // config store
  const [, configDispatch] = useStateValue(configContext); // eslint-disable-line

  // profile store
  const [, profileDispatch] = useStateValue(profileContext); // eslint-disable-line

  // global store
  const [, globalDispatch] = useStateValue(globalContext); // eslint-disable-line

  // element store
  const [, elementDispatch] = useStateValue(elementContext); // eslint-disable-line

  // table store
  const [, tableDispatch] = useStateValue(tableContext); // eslint-disable-line

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  const deleteRequest = async (store: string, resource: string, url: string, deleteId: string) => {
    // uppercase table for dispatch
    const RESOURCE = resource.toUpperCase();

    // if request is in progress, block new request
    if (!inProgress) {
      try {
        // start delete request
        setInProgress(true);
        eval(`${store}Dispatch`)({ type: `DELETE_${RESOURCE}_START` }); // eslint-disable-line

        // optimistic delete - dispatch delete to store
        eval(`${store}Dispatch`)({ type: `DELETE_${RESOURCE}_SUCCESS`, payload: deleteId }); // eslint-disable-line

        // close delete modal
        configDispatch({ type: SET_MODAL_CONFIG, payload: { open: false } });

        // delete request
        const { status } = await Axios.delete(`${url}/${deleteId}`);

        // successful delete - no dispatch needed
        if (status === 202) setInProgress(false);

        // dispatch old data back to store
        if (status === 500) {
          // stop delete request
          setInProgress(false);

          return undefined;
        }

        return true;
      } catch (error) {
        // stop delete request
        setInProgress(false);

        // dispatch update failure
        eval(`${store}Dispatch`)({ type: `DELETE_${RESOURCE}_FAIL` }); // eslint-disable-line
        return false;
      }
    }

    // if request is already in progress - return false
    return false;
  };

  return deleteRequest;
};

export default useDeleteRequest;
