import React from 'react';
import styled from 'styled-components';

// assets
import oops from '../../assets/oops.png';
import RightArrow from '../../assets/RightArrow';

// component
import ButtonWithIcon from '../../components/General/ButtonWithIcon';

// style
import { colors } from '../../styles/theme';

const NotFound = () => {
  return (
    <Styles>
      <img src={oops} alt="oops" style={{ marginBottom: '45px' }} />
      <h2>404. That&apos;s an error.</h2>
      <p>We can&apos;t find the page you&apos;re looking for.</p>
      <ButtonWithIcon
        text="Back To Dashboard"
        className="black-button"
        redirect="dashboard"
        color={colors.white}
        Icon={RightArrow}
      />
    </Styles>
  );
};

export default NotFound;

const Styles: any = styled.div`
  height: calc(100vh - 151px);
  padding: 200px 0 0 13.8%;

  h2 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 64px;
  }

  button {
    display: inline-block;
  }

  /* Break points*/
  @media (max-width: 992px) {
    height: calc(100vh - 136px);
    padding: 150px 0 0 10%;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 172px);
    padding: 150px 0 0 8%;
  }
  @media (max-width: 550px) {
    padding: 150px 36px;
  }
`;
