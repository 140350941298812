import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Content from './Content';
import Header from './Header';
import Loader from '../../../components/General/Loader';

// data management
import { elementContext, configContext } from '../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../store/constants';

type propTypes = {
  updateElement: Function,
};

const Outline = ({ updateElement }: propTypes) => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { fullScreen, activeView, minimizeScreen } = configState;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements, elementsLoading } = elementState;

  // handle className logic for  fullscreen, minimize screen and active view
  const determineClassName = () => {
    if (fullScreen === 'outline') return 'full-screen-view outline active-view-outline';
    if (fullScreen === 'table' || fullScreen === 'canvas' || minimizeScreen === 'outline')
      return 'hide';
    if (activeView === 'outline') return 'active-view-outline outline';

    return 'outline';
  };

  const setView = () => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'outline' });

  return (
    <Styles className={determineClassName()} onClick={setView}>
      <Header />
      <div
        className={fullScreen === 'outline' ? 'tall-content outline-content' : 'outline-content'}
      >
        {elementsLoading ? (
          <Loader containerHeight="35vh" />
        ) : (
          <Content elements={elements} updateElement={updateElement} />
        )}
      </div>
    </Styles>
  );
};

export default Outline;

const Styles: any = styled.div``;
