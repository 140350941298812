import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  keyOne: string,
  keyTwo?: string,
  text: string,
};

const TextDisplay = ({ keyOne, keyTwo, text }: propTypes) => {
  return (
    <Styles>
      <span>{keyOne}</span>

      {keyTwo && (
        <>
          <p>+ </p>
          <span> {keyTwo}</span>
        </>
      )}

      <p>{text}</p>
    </Styles>
  );
};

export default TextDisplay;

TextDisplay.defaultProps = {
  keyTwo: undefined,
};

const Styles: any = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
  padding-bottom: 10px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 92px;
    height: 39px;
    margin-right: 15px;

    background: ${colors.paleGrey};
    border-radius: 4px;

    font-size: 1.6rem;
  }

  p {
    margin: 0;
    margin-right: 15px;
  }
`;
