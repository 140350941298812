import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import BoardContextMenu from '../../Pages/BoardSelection/BoardContextMenu';

// data management
import { configContext } from '../../store/contexts';

// theme
import { colors } from '../../styles/theme';

// utility
import useContextMenu from '../../hooks/useContextMenu';

type propTypes = {
  board: Object,
};

const OptionsButton = ({ board }: propTypes) => {
  const { boardId } = board;

  // config store
  const [configState] = useStateValue(configContext);
  const { contextMenuOpen, contextMenuPosition } = configState;
  const { contextMenuId } = contextMenuPosition;

  // context menu
  const { openContextMenu } = useContextMenu();

  // TODO: Make dropdown menu tab accessible
  return (
    <Styles>
      <button
        type="button"
        className="dropdown-button"
        onClick={e => openContextMenu(e, boardId, 15)}
        tabIndex={-1}
      >
        <span />
        <span />
        <span />
      </button>

      {/* Display context menu if right click occurs */}
      {contextMenuOpen && contextMenuId === boardId && <BoardContextMenu board={board} />}
    </Styles>
  );
};

export default OptionsButton;

const Styles: any = styled.div`
  margin-top: -25px;

  .dropdown-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 100%;
    border: 0;

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${colors.lightGrey};
      margin: 1px;
    }
  }
`;
