import React, { useState } from 'react';
import { useStateValue } from 'react-conflux';

// components
import NameModal from './NameModal';
import RoleModal from './RoleModal';
import IntroModal from './IntroModal';
import OutroModal from './OutroModal';
import UserReference from './UserReference';

// data management
import { profileContext } from '../../../../store/contexts';

// utility
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

type propTypes = {
  closeModal: Function,
  page: number,
  setPage: Function,
};

const SurveyModal = ({ closeModal, page, setPage }: propTypes) => {
  // profile store
  const [profileState] = useStateValue(profileContext);
  const { user } = profileState;
  const { email, userId } = profileState.user;

  const update = useUpdateRequest();

  // survey data object
  const [surveyData, setSurveyData] = useState({ email, acRoleId: 6 });

  const handleSubmit = () => {
    update('profile', 'user', `/users/${userId}`, surveyData);
    closeModal();
  };

  if (page === 2) return <NameModal setPage={setPage} user={user} setSurveyData={setSurveyData} />;
  if (page === 3) return <RoleModal setPage={setPage} setSurveyData={setSurveyData} />;
  if (page === 4) return <UserReference setPage={setPage} setSurveyData={setSurveyData} />;
  if (page === 5) return <OutroModal handleSubmit={handleSubmit} />;

  return <IntroModal setPage={setPage} />; // fallback to first form page
};

export default SurveyModal;
