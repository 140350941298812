import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Loader from '../../../components/General/Loader';
import TabButton from './TabButton';

// data management
import { configContext, elementContext } from '../../../store/contexts';
import { SET_ACTIVE_LAYER_ID } from '../../../store/constants';

// utility
import useGetRequest from '../../../requestHooks/useGetRequest';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  layer: Object,
};

const ReadOnlyLayer = ({ layer }: propTypes) => {
  const { layerId, boardId, title } = layer;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeLayerId } = configState;

  // element store
  const [elementStore] = useStateValue(elementContext);
  const { layerLoading } = elementStore;

  // destructure request utility
  const getRequest = useGetRequest();

  const selectLayer = async () => {
    // set active layerId to newly selected layerId
    configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });

    // send get request and set data to store
    await getRequest('element', 'layer', `/elements/qualities/board/${boardId}/layer/${layerId}`);
  };

  if (activeLayerId === layerId) {
    return (
      <Styles className="active-layer-tab layer-tab">
        {layerLoading ? (
          <Loader width={20} height={20} />
        ) : (
          <>
            <p className="layer-title" id={`layer${layerId}`}>
              {title || 'Untitled Layer'}
            </p>
            <span style={{ borderBottom: `4px solid ${colors.purple}` }} />
          </>
        )}
      </Styles>
    );
  }
  return <TabButton event={selectLayer} title={title} />;
};

export default ReadOnlyLayer;

const Styles: any = styled.button`
  .layer-title {
    margin-bottom: 6px;
  }
`;
