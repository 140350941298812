import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  title: String,
  event: Function,
};

const TabButton = ({ title, event }: propTypes) => {
  return (
    <Styles className="inactive-layer-tab layer-tab" onClick={event}>
      <p>{title}</p>
      <span />
    </Styles>
  );
};

export default TabButton;

const Styles: any = styled.button`
  span {
    min-width: 125px;
  }

  p {
    font-family: 'SofiaProBold', 'Helvetica', sans-serif;
    color: ${colors.mediumGrey};
    margin: 0;
    padding-bottom: 6px;
    text-transform: initial;
  }
`;
