import { css } from 'styled-components';

// theme
import { colors } from './theme';

export default css`
  .board-layout {
    background: ${colors.background};
    height: 100vh;
    overflow: hidden;

    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 46px 1fr 1fr 46px;
    grid-template-areas:
      'board-header board-header'
      'canvas outline'
      'canvas table'
      'board-footer board-footer';
  }

  .minimization-layout {
    grid-template-rows: 46px 1fr 46px;
  }

  .no-canvas-layout {
    grid-template-areas:
      'board-header board-header'
      'table outline'
      'board-footer board-footer';
  }

  .no-outline-layout {
    grid-template-areas:
      'board-header board-header'
      'canvas table'
      'board-footer board-footer';
  }

  .no-table-layout {
    grid-template-areas:
      'board-header board-header'
      'canvas outline'
      'board-footer board-footer';
  }

  .canvas,
  .outline,
  .table {
    border: 3px solid transparent;
    box-shadow: ${colors.boxShadow};
    background: ${colors.white};
    border-radius: 6px;
    overflow: auto;

    :focus {
      outline: none;
    }
  }

  .canvas {
    grid-area: canvas;
    margin-left: 15px;
  }

  .outline {
    margin-right: 15px;
    grid-area: outline;
  }

  .table {
    margin-right: 15px;
    grid-area: table;
  }

  .board-header {
    grid-area: board-header;
  }

  .board-footer {
    grid-area: board-footer;
  }

  #svg-canvas {
    height: calc(100% - 32px);
    width: 100%;

    :hover {
      cursor: grab;
    }

    :active {
      user-select: none;
      cursor: grabbing;
    }
  }

  .outline-content {
    padding: 15px;
  }

  .tall-content {
    height: calc(100vh - 92px - 30px);
  }

  .full-screen-view {
    width: calc(100vw - 30px);
    height: auto;
    grid-area: canvas;
    margin-left: 15px;
    margin-right: 0;
  }

  .active-view-canvas {
    border: solid 3px ${colors.canvasPurple};
    border-radius: 6px;
  }

  .active-element-canvas {
    border: solid 2px ${colors.canvasPurple} !important;
  }

  .active-view-outline {
    border: solid 3px ${colors.outlineBlue};
    border-radius: 6px;
  }

  .active-view-table {
    border: solid 3px ${colors.tableGreen};
    border-radius: 6px;
  }

  .active-element-table {
    border: solid 2px ${colors.tableGreen} !important;
  }

  .active-element-outline {
    border: 2px solid ${colors.outlineBlue} !important;
  }

  .update-margin {
    margin-right: 0px;
    margin-left: 15px;
  }

  .placeholder-border {
    border: 3px solid transparent;
  }

  .hide {
    display: none !important;
  }

  /* animation for minimizing canvas, outline, table */
  .minimize-canvas,
  .minimize-outline,
  .minimize-table {
    position: absolute;
    border-radius: 6px;
    background: ${colors.lightTransparency};
  }

  .minimize-canvas {
    left: 2%;
    animation: minimize-canvas 0.5s;
  }

  .minimize-outline {
    animation: minimize-outline 0.5s;
  }

  .minimize-table {
    animation: minimize-table 0.5s;
  }

  @keyframes minimize-canvas {
    0% {
      top: 10%;
      width: 48%;
      height: 81%;
    }

    75% {
      top: 90%;
      width: 0;
      height: 0;
    }

    100% {
      background: none;
    }
  }

  @keyframes minimize-outline {
    0% {
      top: 10%;
      left: 53%;
      width: 48%;
      height: 42%;
    }

    75% {
      top: 90%;
      left: 2%;
      width: 0;
      height: 0;
    }

    100% {
      background: none;
    }
  }

  @keyframes minimize-table {
    0% {
      top: 50%;
      left: 53%;
      width: 48%;
      height: 42%;
    }

    75% {
      top: 90%;
      left: 2%;
      width: 0;
      height: 0;
    }

    100% {
      background: none;
    }
  }

  /* animation for blowing up canvas, outline, table */
  .blowup-menu {
    position: absolute;
    border-radius: 6px;
    border-radius: 6px;
    background: none;

    z-index: 3;
  }

  .blowup-canvas {
    left: 2%;
    animation: blowup-canvas 0.5s;
  }

  .blowup-outline {
    animation: blowup-outline 0.5s;
  }

  .blowup-table {
    animation: blowup-table 0.5s;
  }

  @keyframes blowup-canvas {
    0% {
      top: 90%;
      width: 0;
      height: 0;
    }

    100% {
      top: 10%;
      width: 48%;
      height: 81%;
    }

    100% {
      background: ${colors.lightTransparency};
    }
  }

  @keyframes blowup-outline {
    0% {
      top: 90%;
      left: 2%;
      width: 0;
      height: 0;
    }

    75% {
      top: 10%;
      left: 50%;
      width: 48%;
      height: 42%;
    }

    100% {
      background: ${colors.lightTransparency};
    }
  }

  @keyframes blowup-table {
    0% {
      top: 90%;
      left: 2%;
      width: 0;
      height: 0;
    }

    75% {
      top: 50%;
      left: 50%;
      width: 48%;
      height: 42%;
    }

    100% {
      background: ${colors.lightTransparency};
    }
  }

  /* canvas node styling */
  .node {
    display: flex;
    align-items: center;
    min-width: 150px;
    height: 60px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px 0;
    border-radius: 8px;
    border: solid 2px ${colors.darkGrey};
    background: ${colors.white};
    cursor: default;

    :hover {
      border: solid 2px ${colors.canvasPurple} !important;
    }

    :focus {
      outline: none;
    }

    .pon-label {
      display: flex;
      justify-content: flex-end;
      margin: -9px 5px 0 0;
      font-size: 1.2rem;
      height: 12px;
    }

    .read-only-div {
      width: 100%;
      user-select: none; /* standard syntax */
      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
    }

    .read-only-pon-label {
      margin: -12px 8px 0 0;
    }

    .read-only-p {
      margin-bottom: 0;
      min-width: 108px;
      min-height: 24px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 10px;
    }

    .icon-and-text {
      display: flex;
    }

    .hover-active {
      position: fixed;
      border: 0;
      margin: 0;
      padding: 0;
      height: 18px;

      img {
        width: 18px;
        height: 18px;
        z-index: 3;
      }

      :hover {
        cursor: pointer;
      }

      :focus {
        outline: none;
      }
    }

    .hover-active-top {
      top: 0;
      left: calc(50% - 9px);
    }

    .hover-active-bottom {
      bottom: 0;
      left: calc(50% - 9px);
    }

    .hover-active-right {
      right: 0;
    }

    .hover-active-left {
      left: 0;
    }

    .hover-inactive {
      display: none;
    }
  }

  /* table styling */
  .table {
    .full-width-layer-tabs {
      width: calc(100vw - 50px);
    }

    .header-and-table {
      box-shadow: ${colors.boxShadow};
      border-radius: 6px;
      background: ${colors.white};
    }

    .cell {
      height: 40px;
      min-width: 80px;
    }

    input {
      min-width: 76px;
      height: 40px;
      margin: 0;
      padding-left: 4px;
      background: transparent;
      border: 2px solid transparent;

      :focus {
        outline: none;
        border: 2px solid ${colors.tableGreen};
        border-radius: 3px;
      }
    }
  }

  .color-quality {
    border: none;
    border-radius: 0px;
    background-image: none;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -webkit-appearance: none;

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 768px) {
    .board-layout {
      grid-template-rows: 46px 1fr 1fr 1fr 46px;
      grid-template-areas:
        'board-header'
        'canvas'
        'outline'
        'table'
        'board-footer';
    }

    .minimization-layout {
      grid-template-rows: 46px 1fr 1fr 46px;
    }

    .no-canvas-layout {
      grid-template-areas:
        'board-header'
        'table'
        'outline'
        'board-footer';

      .table {
        margin-right: 15px;
      }
    }

    .no-outline-layout {
      grid-template-areas:
        'board-header'
        'canvas'
        'table'
        'board-footer';
    }

    .no-table-layout {
      grid-template-areas:
        'board-header'
        'canvas'
        'outline'
        'board-footer';
    }

    .outline,
    .table {
      margin-left: 15px;
    }

    .canvas {
      margin-right: 15px;
    }

    .full-screen-view {
      height: calc(100vh - 152px);
    }

    .board-footer {
      width: 100vw;
    }
  }
`;
