import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Layer from './Layer';
import ReadLayer from './ReadLayer';
import AddLayer from './AddLayer';

// data management
import { tableContext } from '../../../store/contexts';

// theme
import { colors } from '../../../styles/theme';

const LayerTabs = () => {
  const { pathname } = window.location;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { layers } = tableState;

  const isPublicRoute = pathname.includes('/board/');

  return (
    <Styles className="board-footer">
      <AddLayer isPublicRoute={isPublicRoute} />

      <div className="layers">
        {layers.map(layer =>
          isPublicRoute ? (
            <ReadLayer key={layer.layerId} layer={layer} />
          ) : (
            <Layer key={layer.layerId} layer={layer} layers={layers} />
          )
        )}
      </div>

      {/*
      // TODO: add in advanced left/right functionality when users are actively using more than 8 layers.
      {layers.length > 5 && !isPublicRoute && (
        <div className="button-container">
          <ButtonWithIcon
            Icon={Carrot}
            color={!isPublicRoute ? colors.mediumGrey : colors.purple}
          />
          <ButtonWithIcon
            Icon={Carrot}
            color={!isPublicRoute ? colors.purple : colors.mediumGrey}
          />
        </div>
      )} */}
    </Styles>
  );
};

export default LayerTabs;

const Styles: any = styled.div`
  display: flex;
  align-items: center;

  background: ${colors.white};
  box-shadow: 0 -2px 4px rgba(216, 216, 216, 0.5);

  .layers {
    margin-bottom: -12px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 90%;
    height: 90%;

    /* layer tab buttons */
    .layer-tab {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 131px;

      padding: 0;
      margin: 0 5px;

      color: ${colors.mediumGrey};

      border: 0;

      span {
        width: 108%;
        border-radius: 4px;
      }

      :hover {
        cursor: pointer;
      }

      :focus {
        outline: none;
      }
    }

    .active-layer-tab {
      input {
        padding-bottom: 6px;
      }
    }

    .inactive-layer-tab {
      :hover {
        span {
          border: 2px solid ${colors.mediumGrey};
        }
      }

      :focus {
        span {
          border: 2px solid ${colors.mediumGrey};
        }
      }
    }
  }

  .layer-title {
    width: 125px;
    text-align: center;
    color: ${colors.purple};

    :focus {
      border: 0;
    }
  }
`;
