import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// content
import planContent from './planContent';

// components
import Button from '../../../components/General/Button';

// theme
import { colors } from '../../../styles/theme';

// assets
import conversationIcon from '../../../assets/conversation-icon.svg';

// data management
import { profileContext } from '../../../store/contexts';
import Divider from '../../../components/General/Divider';

// utility
import { convertPlanId } from '../../../util/freemium';

type propTypes = {
  plan: string,
};

const Card = ({ plan }: propTypes) => {
  const { title, price, subText, text, stats } = planContent[plan];

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { planId, status } = profileState.chargebee;

  const currentPlan = convertPlanId(planId, status);
  const activePlan = currentPlan === plan;

  const determineCardStyles = () => {
    if (activePlan) return { background: colors.palePurple, color: colors.darkGrey };

    return { background: colors.purple, color: colors.white };
  };

  return (
    <Styles>
      <div className="plan-top" style={determineCardStyles()}>
        <h4>{title}</h4>
        {price ? (
          <h3>{price.toUpperCase()}</h3>
        ) : (
          <img src={conversationIcon} alt="conversation icon" />
        )}
        <p>{subText}</p>
      </div>

      <div className="plan-bottom">
        <p className="plan-text">{text}</p>
        <Divider style={{ margin: '6px 0 16px 0' }} />
        <p className="includes">includes:</p>
        <div>
          {stats.map(stat => {
            return (
              <p key={plan + stat}>
                <span role="img" aria-label="Checkmark">
                  ✔️
                </span>
                {stat}
              </p>
            );
          })}
        </div>
        <Divider style={{ margin: '6px 0 16px 0' }} />

        {plan === 'enterprise' && (
          <a href="https://brainsquall.co/contact" target="_blank" rel="noopener noreferrer">
            <Button text="Contact Us" />
          </a>
        )}
        {plan === 'starter' && <Button text={`Choose ${title}`} disabled={activePlan} />}

        {(plan === 'personal-monthly' || plan === 'professional-monthly') && (
          <a
            data-cb-type="checkout"
            data-cb-plan-id={plan}
            data-cb-plan-quantity="1"
            href="https://create.brainsquall.co/dashboard/settings/account"
          >
            <Button text={`Choose ${title}`} disabled={activePlan} />
          </a>
        )}
      </div>
    </Styles>
  );
};

export default Card;

const Styles: any = styled.div`
  width: 232px;
  box-shadow: ${colors.boxShadow};

  @media (max-width: 800px) {
    width: 90%;
  }

  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 40px;
    padding: 100px 0 60px 0;
    margin: 0;
  }

  h3 {
    font-size: 4.6rem !important;
    line-height: 70px;
    font-family: SofiaProBold;
  }

  h4 {
    font-size: 2rem;
    text-transform: capitalize;
  }

  p {
    font-size: 1.2rem;
    width: 100%;
  }

  .plan-top {
    width: 100%;
    height: 152px;
    color: white;
    border-radius: 4px 4px 0 0;
    padding: 20px;

    img {
      margin-top: 14px;
      margin-bottom: -8px;
    }

    p {
      color: ${colors.white};
    }
  }

  .plan-bottom {
    width: 100%;
    background: ${colors.white};
    border-radius: 0 0 4px 4px;
    padding: 20px;

    /* Display Settings */
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 100%;
    }

    .plan-text {
      color: ${colors.mediumGrey};
      line-height: 16px;
      min-height: 48px;
    }

    .includes {
      color: ${colors.mediumGrey};
    }

    span {
      margin-right: 5px;
    }
  }
`;
