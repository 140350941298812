import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Columns from './Columns';
import Elements from './Elements';
import Header from './Header';
import Loader from '../../../components/General/Loader';

// data management
import { elementContext, tableContext, configContext } from '../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../store/constants';

type propTypes = {
  updateElement: Function,
};

const Table = ({ updateElement }: propTypes) => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { fullScreen, activeView, minimizeScreen } = configState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elementsLoading } = elementState;

  // table ref
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);

  // set table width when elements finish loading and when columns are added.
  useEffect(() => {
    if (tableRef.current && !elementsLoading) setTableWidth(tableRef.current.scrollWidth);
  }, [elementsLoading, columns.length, fullScreen, columns]);

  const setView = () => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' });

  // track active column adjustment
  const [activeId, setActiveId] = useState(0);

  // track mouse hold for column adjustment
  const [isHeld, setIsHeld] = useState(false);

  const submitColumnAdjust = () => {
    setActiveId(0);
    setIsHeld(false);
  };

  // handle className logic for  fullscreen, minimize screen and active view
  const determineClassName = () => {
    if (fullScreen === 'table') return 'full-screen-view table active-view-table';
    if (fullScreen === 'outline' || fullScreen === 'canvas' || minimizeScreen === 'table')
      return 'hide';

    // update margin when the board becomes two columns
    if (activeView === 'table' && minimizeScreen === 'canvas')
      return 'table update-margin active-view-table';
    if (minimizeScreen === 'canvas') return 'table update-margin';
    if (activeView === 'table') return 'active-view-table table';

    return 'table';
  };

  return (
    <Styles onClick={setView} onMouseUp={submitColumnAdjust} className={determineClassName()}>
      <div>
        <Header tableWidth={tableWidth} />
        <div ref={tableRef}>
          {elementsLoading ? (
            <Loader containerHeight="35vh" />
          ) : (
            <>
              <Columns
                tableWidth={tableWidth}
                activeId={activeId}
                setActiveId={setActiveId}
                isHeld={isHeld}
                setIsHeld={setIsHeld}
              />
              <Elements updateElement={updateElement} tableWidth={tableWidth} />
            </>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default Table;

const Styles: any = styled.div``;
