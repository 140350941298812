import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/theme';

type propTypes = {}; // eslint-disable-line

const PhoneNumber = () => {
  return (
    <Styles>
      <p>Give Us a Call @</p>
      <p>
        <span>802-8-SQUALL</span>
      </p>
    </Styles>
  );
};

export default PhoneNumber;

const Styles: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${colors.paleGrey};

  p {
    margin: 0;
    padding-left: 16px;

    span {
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0.39px;
    }
  }
`;
