import { createGlobalStyle } from 'styled-components';

import Reset from './Reset';
import Global from './Global';
import Button from './Button';
import BoardView from './BoardView';

const GlobalStyles = createGlobalStyle`
  ${Reset}
  ${Global}
  ${Button}
  ${BoardView}
`;

export default GlobalStyles;
