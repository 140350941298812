import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../../../store/contexts';
import { SET_HIGHLIGHT } from '../../../store/constants';

// utility
import useDetermineBackground from '../../../hooks/useDetermineBackground';
import useViewSync from '../../../hooks/useViewSync';

type propTypes = {
  element: Object,
  elementWidth: number,
  ponWidth: number,
};

const ReadOnlyElement = ({ element, elementWidth, ponWidth }: propTypes) => {
  const { elementId, parentId, title, PON } = element;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeEditId } = configState;

  // destructure determine background
  const determineBackground = useDetermineBackground();

  const syncRef = useViewSync();

  return (
    <Styles onClick={() => configDispatch({ type: SET_HIGHLIGHT, payload: elementId })}>
      <div
        ref={syncRef}
        id={activeEditId === elementId ? 'table-ref' : null}
        className={activeEditId === elementId ? 'cell active-element-table' : 'cell'}
        style={{
          background: determineBackground(element),
          width: elementWidth < 80 ? 80 : elementWidth + 10,
          minWidth: elementWidth < 80 ? 80 : elementWidth + 10,
        }}
      >
        <p>{title}</p>
      </div>
      <div
        className="cell"
        style={{
          width: ponWidth < 80 ? 80 : ponWidth + 20,
          minWidth: ponWidth < 80 ? 80 : ponWidth + 20,
        }}
      >
        {parentId ? <p className="pon-value">{PON.slice(2)}</p> : <p />}
      </div>
    </Styles>
  );
};

export default ReadOnlyElement;

const Styles: any = styled.div`
  display: flex;

  .cell {
    p {
      display: flex;
      align-items: center;
      margin: 0 4px;
      height: 100%;
      overflow: hidden !important;
      white-space: nowrap !important;
      word-wrap: break-word !important;
    }
  }

  .pon-value {
    margin: 0 10px !important;
  }
`;
