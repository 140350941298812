import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// component
import ButtonWithIcon from './ButtonWithIcon';
import ColorQuality from '../../Pages/BoardView/ColorQuality';

// data management
import { configContext, elementContext, tableContext } from '../../store/contexts';
import { SET_MODAL_CONFIG } from '../../store/constants';

// asset
import Delete from '../../assets/Delete';
import { colors } from '../../styles/theme';

type propTypes = {
  contextMenuX: number,
  contextMenuY: number,
  elementId?: number,
  layerId?: number,
};

const ContextMenu = ({ contextMenuX, contextMenuY, elementId, layerId }: propTypes) => {
  // config state
  const [, configDispatch] = useStateValue(configContext);

  // element state
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // table state
  const [tableState] = useStateValue(tableContext);
  const { layers } = tableState;

  // check if element right clicked element is the root element
  const [isRootElement, setIsRootElement] = useState(false);
  useEffect(() => {
    if (elementId) {
      const isRoot = elements.find(el => el.elementId === elementId).parentId === null;
      setIsRootElement(isRoot);
    }
  }, [elementId, elements]);

  if (layerId) {
    return (
      <Styles
        style={{ left: contextMenuX + 35, top: contextMenuY - 60, zIndex: 100 }}
        className="context-menu"
      >
        <ButtonWithIcon
          Icon={Delete}
          className={layers.length === 1 ? 'button-disabled white-button' : 'white-button'}
          text="delete"
          disabled={layers.length === 1}
          onClick={() =>
            configDispatch({
              type: SET_MODAL_CONFIG,
              payload: { open: true, type: 'delete', layerId },
            })
          }
          style={{ color: `${colors.darkGrey}` }}
        />
      </Styles>
    );
  }

  return (
    <Styles style={{ left: contextMenuX, top: contextMenuY }} className="context-menu">
      <ButtonWithIcon
        Icon={Delete}
        className={
          elements.length === 1 || isRootElement ? 'button-disabled white-button' : 'white-button'
        }
        text="delete"
        disabled={elements.length === 1 || isRootElement}
        onClick={() =>
          configDispatch({
            type: SET_MODAL_CONFIG,
            payload: { open: true, type: 'delete', elementId },
          })
        }
      />
      <ColorQuality elementId={elementId} />
    </Styles>
  );
};

export default ContextMenu;

ContextMenu.defaultProps = {
  elementId: undefined,
  layerId: undefined,
};

const Styles: any = styled.div``;
