import React from 'react';
import styled from 'styled-components';

// components
import HelpButton from './HelpButton';
import Terms from './Terms';
import PhoneNumber from './PhoneNumber';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  menuOpen: boolean,
};

const HelpMenuModal = ({ menuOpen }: propTypes) => {
  if (!menuOpen) return <></>;

  return (
    <Styles>
      <HelpButton
        testId="help-center-link"
        href="https://brainsquall.co/resources"
        text="Help Center"
      />
      <HelpButton
        testId="request-feature-link"
        href="https://brainsquall.co/resources#request-feature"
        text="Request a Feature"
      />
      <PhoneNumber />
      <Terms />
    </Styles>
  );
};

export default HelpMenuModal;

const Styles: any = styled.div`
  width: 218px;
  height: 280px;
  border-radius: 4px;
  box-shadow: ${colors.boxShadow};
  border: solid 1px ${colors.paleGrey};
  background: ${colors.white};
  z-index: 1;

  position: fixed;
  bottom: 65px;
  right: 20px;
`;
