import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import AdjustWidth from './AdjustWidth';
import ColumnContextMenu from './ColumnContextMenu';

// data management
import { configContext, tableContext } from '../../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../../store/constants';

// utility
import focusInputId from '../../../../util/focusInputId';
import useContextMenu from '../../../../hooks/useContextMenu';
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  column: Object,
  currentPosition: number,
  setCurrentPosition: Function,
  columnIndex: number,
  isHeld: boolean,
  setIsHeld: Function,
  activeId: number,
  setActiveId: Function,
  isHidden: boolean,
  setIsHidden: Function,
};

const Column = ({
  column,
  currentPosition,
  setCurrentPosition,
  columnIndex,
  isHeld,
  setIsHeld,
  activeId,
  setActiveId,
  isHidden,
  setIsHidden,
}: propTypes) => {
  const { columnId, width, columnType } = column;

  const [title, setTitle] = useState(column.title);

  // local column width variable used for live calculation
  const [columnWidth, setColumnWidth] = useState(width);

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { contextMenuOpen, contextMenuPosition, modalConfig } = configState;
  const { contextMenuX, contextMenuY, contextMenuId } = contextMenuPosition;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // context menu
  const { openContextMenu } = useContextMenu();

  // destructure request utility
  const update = useUpdateRequest();

  // update element on change
  const updateColumn = async e => {
    setTitle(e.target.value);

    const oldColumn = columns.find(col => col.columnId === columnId);

    // update requires a columnId and a change in the title
    if (oldColumn && oldColumn.title !== title && columnId) {
      // send request and update store
      update('table', 'column', `/columns/${columnId}`, { title: e.target.value });
    }
  };

  return (
    <Styles
      className="cell"
      style={{
        width: columnWidth < 80 ? 80 : columnWidth,
        minWidth: columnWidth < 80 ? 80 : columnWidth,
      }}
      onContextMenu={e => openContextMenu(e, columnId, -40)}
    >
      {columnType === 'text' ? (
        <input
          id={`tableColumn${columnId}`}
          type="text"
          placeholder="Untitled"
          value={title}
          aria-label={title || 'empty column cell'}
          style={{
            width: columnWidth < 80 ? 60 : columnWidth - 20,
            minWidth: columnWidth < 80 ? 60 : columnWidth - 20,
            border: modalConfig.columnId === columnId && `2px solid ${colors.warningRed}`,
          }}
          onChange={e => setTitle(e.target.value)}
          onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' })}
          onBlur={e => updateColumn(e)}
          onKeyDown={e => {
            if (e.keyCode === 27) e.target.blur(); // escape key
            if (e.keyCode === 13) e.target.blur(); // return key
            if (e.keyCode === 40) focusInputId(`tableQuality${0}${columnIndex}`); // down arrow
          }}
        />
      ) : (
        <div
          className="cell"
          style={{
            width: columnWidth < 80 ? 60 : columnWidth - 20,
            minWidth: columnWidth < 80 ? 60 : columnWidth - 20,
            border: modalConfig.columnId === columnId && `2px solid ${colors.warningRed}`,
          }}
        >
          <p>{columnType}</p>
        </div>
      )}

      {/* Adjust column width element */}
      <AdjustWidth
        columnId={columnId}
        currentPosition={currentPosition}
        setCurrentPosition={setCurrentPosition}
        columnWidth={columnWidth}
        setColumnWidth={setColumnWidth}
        isHeld={isHeld}
        setIsHeld={setIsHeld}
        activeId={activeId}
        setActiveId={setActiveId}
        isHidden={isHidden}
      />

      {/* Display context menu if right click occurs */}
      {contextMenuOpen && contextMenuId === columnId && (
        <ColumnContextMenu
          contextMenuX={contextMenuX}
          contextMenuY={contextMenuY}
          columnId={columnId}
          setColumnWidth={setColumnWidth}
          columnIndex={columnIndex}
          setIsHidden={setIsHidden}
        />
      )}
    </Styles>
  );
};

export default Column;

const Styles: any = styled.div`
  display: flex;
  align-items: center;

  input {
    font-weight: 600;
    font-family: 'SofiaProBold';
    background: inherit;
  }

  .cell p {
    text-transform: capitalize;
    font-family: 'SofiaProBold';
  }
`;
