import { clickedPos } from './constants';

const { TOP, BOTTOM, LEFT, RIGHT } = clickedPos;

export const roundToNth = (value: number, n: number) => {
  if (value && n) {
    const roundedValue = Math.round(value / n) * n;

    return roundedValue;
  }
  return 0;
};

export const generateXPosition = (
  element: Object,
  clickedButton: string = '',
  buttonWidth: number = 0
) => {
  const { xCoord, childrenPON } = element;
  const { length } = childrenPON;

  // floor xCoord
  const position = Math.floor(xCoord);
  const secondColumn = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40];
  const thirdColumn = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41];

  // Determines which button is clicked and makes calculation on where to place next element.
  switch (clickedButton) {
    case TOP:
      return roundToNth(position, 20);
    case BOTTOM:
      return roundToNth(position, 20);
    case LEFT:
      return roundToNth(position - 180, 20);
    case RIGHT:
      return roundToNth(position + buttonWidth, 20);
    default:
      if (secondColumn.includes(length)) return roundToNth(position + 180, 20);
      if (thirdColumn.includes(length)) return roundToNth(position + 360, 20);
      return roundToNth(position, 20);
  }
};

export const generateYPosition = (element: Object, clickedButton: string = '') => {
  const { yCoord, childrenPON } = element;
  const { length } = childrenPON;

  // floor yCoord and add default 80px addition to start elements 20px below parent.
  const position = Math.floor(yCoord);
  const secondRow = [3, 4, 5];
  const thirdRow = [6, 7, 8];

  // determines which button is clicked and makes calculation on where to place next element.
  switch (clickedButton) {
    case TOP:
      return roundToNth(position - 80, 20);
    case BOTTOM:
      return roundToNth(position + 80, 20);
    case LEFT:
      return roundToNth(position, 20);
    case RIGHT:
      return roundToNth(position, 20);
    default:
      if (secondRow.includes(length)) return roundToNth(position + 160, 20);
      if (thirdRow.includes(length)) return roundToNth(position + 240, 20);
      return roundToNth(position + 80, 20);
  }
};

export const toFixedDecimal = (num: number, fix: number) => {
  const result = num.toFixed(fix);

  return +result;
};
