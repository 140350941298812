import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
// $FlowFixMe - react-router-dom
import { Redirect } from 'react-router-dom';

// components
import BoardHeader from '../BoardView/BoardHeader';
import ReadOnlyCanvas from './Canvas';
import ReadOnlyOutline from './Outline';
import ReadOnlyTable from './Table';
import LayerTabs from '../BoardView/Layers';
import Loader from '../../components/General/Loader';
import BlowupMenu from '../../components/General/BlowupMenu';

// data management
import { globalContext, configContext, elementContext } from '../../store/contexts';

// utility
import useGetPublicBoardData from '../../hooks/useGetPublicBoardData';

const ReadOnlyMainView = () => {
  const [globalState] = useStateValue(globalContext);
  const { isPublic } = globalState.board;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // config store
  const [configState] = useStateValue(configContext);
  const { minimizeScreen, fullScreen } = configState;

  // destructure getPublicBoardData
  const { getPublicBoardData } = useGetPublicBoardData();

  // get board key from URL param
  const { pathname } = window.location;
  const boardPublicKey = pathname.slice(7);

  // get elements with qualities for board upon page refresh.
  useEffect(() => {
    const fetchPublicBoardData = async () => {
      try {
        // if the return is undefined update isBoardEmpty
        await getPublicBoardData(boardPublicKey);
      } catch (error) {
        console.log(error);
      }
    };

    if (elements.length === 0) fetchPublicBoardData();
  }, [elements.length]); // eslint-disable-line

  // redirect to 404 page is isPublic is false
  if (isPublic === false) return <Redirect to="/not-found" />;

  // return blank screen while data is loading
  if (elements.length === 0)
    return (
      <div style={{ height: '100vh' }}>
        <Loader />
      </div>
    );

  const determineClassName = () => {
    if (minimizeScreen && fullScreen) return 'board-layout';
    if (minimizeScreen === 'outline') return 'no-outline-layout board-layout minimization-layout';
    if (minimizeScreen === 'table') return 'no-table-layout board-layout minimization-layout';
    if (minimizeScreen === 'canvas') return 'no-canvas-layout board-layout minimization-layout';
    return 'board-layout';
  };

  return (
    <Styles className={determineClassName()}>
      <BoardHeader isPublic />
      <ReadOnlyCanvas />
      <ReadOnlyOutline />
      <ReadOnlyTable />
      <LayerTabs />
      {minimizeScreen && !fullScreen && <BlowupMenu text={minimizeScreen} />}
    </Styles>
  );
};

export default ReadOnlyMainView;

const Styles: any = styled.div``;
