import React from 'react';
import styled from 'styled-components';

// component
import ButtonWithIcon from '../../../General/ButtonWithIcon';

// content
import { introContent } from './surveyContent';

// asset
import DropdownCarrot from '../../../../assets/DropdownCarrot';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  setPage: Function,
};

const IntroModal = ({ setPage }: propTypes) => {
  return (
    <Styles>
      <h3>{introContent.title}</h3>
      <p>{introContent.description}</p>

      <ButtonWithIcon
        Icon={DropdownCarrot}
        text="next"
        position="right"
        className="survey-filled-button"
        color={colors.white}
        onClick={() => setPage(2)}
      />
    </Styles>
  );
};

export default IntroModal;

const Styles: any = styled.div`
  p {
    width: 350px;
    margin-bottom: 32px;
  }
`;
