import { useStateValue } from 'react-conflux';

// data management
import { elementContext } from '../store/contexts';

export const useFindNextElement = () => {
  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // get the next element
  // TODO: account for last element of outline
  const findNextElement = (index: number) => elements.find((el, i) => index + 1 === i);

  return [findNextElement];
};

export const useFindParentElement = () => {
  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // get the parent element
  const findParentElement = (element: Object) => {
    const { parentId } = element;

    return elements.find(el => el.elementId === parentId);
  };

  return [findParentElement];
};

export const useFindPreviousElement = () => {
  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // get the previous element
  const findPreviousElement = (index: number) => {
    const previousElement = elements.find((el, i) => index - 1 === i);

    return previousElement;
  };

  return [findPreviousElement];
};
