import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import Axios from '../../../config/axios_config';

// components
import Button from '../../../components/General/Button';

// data management
import { profileContext } from '../../../store/contexts';

const ManagePlan = () => {
  // profile store
  const [profileState] = useStateValue(profileContext);
  const { customerId } = profileState.chargebee;

  const [chargebeeInstance, setChargebeeInstance] = useState({});
  const [renderOnce, setRenderOnce] = useState(false);

  useEffect(() => {
    if (!renderOnce) {
      setChargebeeInstance(window.Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE }));
      setRenderOnce(true);
    }
  }, [chargebeeInstance, renderOnce]);

  const handleChargebeePortal = async () => {
    try {
      const { data } = await Axios.post(`/chargebee/createPortalSession/${customerId}`);

      chargebeeInstance.setPortalSession(data);
    } catch (error) {
      console.log(error);
    }

    chargebeeInstance.createChargebeePortal().open();
  };

  return (
    <Styles className="settings-container">
      <h3 className="container-title">Account Management</h3>

      <div className="container-content">
        <p>
          Update your plan, account information, billing & shipping address, or view your billing
          history.
        </p>

        <Button text="Manage Plan" event={handleChargebeePortal} />
      </div>
    </Styles>
  );
};

export default ManagePlan;

const Styles: any = styled.div``;
