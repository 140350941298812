import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';

// components
import BoardHeader from './BoardHeader';
import Canvas from './Canvas';
import ContextMenu from '../../components/General/ContextMenu';
import Outline from './Outline';
import Table from './Table';
import Notification from '../../components/Dashboard/Notification';
import LayerTabs from './Layers';
import BlowupMenu from '../../components/General/BlowupMenu';

// data management
import { globalContext, elementContext, configContext, profileContext } from '../../store/contexts';
import {
  SET_TITLE,
  SELECT_BOARD,
  SET_ACTIVE_LAYER_ID,
  SET_CANVAS_CONFIG,
} from '../../store/constants';

// utility
import useGetRequest from '../../requestHooks/useGetRequest';
import useUpdateRequest from '../../requestHooks/useUpdateRequest';

// environment variables
const secret = process.env.REACT_APP_PK;

const BoardView = () => {
  // global store
  const [, globalDispatch] = useStateValue(globalContext);

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { userId } = profileState.user;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const {
    contextMenuOpen,
    contextMenuPosition,
    menuTargetId,
    activeLayerId,
    minimizeScreen,
    fullScreen,
  } = configState;
  const { contextMenuX, contextMenuY, contextMenuId } = contextMenuPosition;

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { elements, elementsLoading } = elementState;

  const tokenizedBoard = localStorage.getItem('board');

  // destructure request utility
  const getRequest = useGetRequest();
  const update = useUpdateRequest();

  // get elements with qualities for board upon page refresh.
  useEffect(() => {
    const getElementsAndTable = async () => {
      // $FlowFixMe - get selectedBoardId from token
      const selectedBoard = jwt.verify(tokenizedBoard, secret);
      const { boardId, layerId, labelId } = selectedBoard;

      globalDispatch({ type: SELECT_BOARD, payload: selectedBoard });
      configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });
      configDispatch({ type: SET_CANVAS_CONFIG, payload: labelId });

      // send get request and set data to store
      const elementsData = await getRequest(
        'element',
        'elements',
        `/elements/qualities/board/${boardId}/layer/${layerId}`
      );

      // if elements data comes back, get table column data
      if (elementsData) {
        // send get request and set data to store
        await getRequest('table', 'table', `/table/board/${boardId}`);
      }
    };
    if (tokenizedBoard && elements.length === 0 && userId && !elementsLoading)
      getElementsAndTable();
  }, [
    tokenizedBoard,
    configDispatch,
    globalDispatch,
    elements.length,
    getRequest,
    userId,
    elementsLoading,
  ]);

  // update element on change
  const updateElement = async ({ title, elementId }) => {
    const oldElement = elements.find(el => el.elementId === elementId);

    // update requires an elementId and a change in the title
    if (oldElement && oldElement.title !== title && elementId) {
      // update local element title
      elementDispatch({ type: SET_TITLE, payload: { elementId, title } });

      // send request and update store
      update('element', 'element', `/elements/${elementId}/layer/${activeLayerId}`, { title });
    }
  };

  const determineClassName = () => {
    if (minimizeScreen && fullScreen) return 'board-layout';
    if (minimizeScreen === 'outline') return 'no-outline-layout board-layout minimization-layout';
    if (minimizeScreen === 'table') return 'no-table-layout board-layout minimization-layout';
    if (minimizeScreen === 'canvas') return 'no-canvas-layout board-layout minimization-layout';
    return 'board-layout';
  };

  return (
    <Styles className={determineClassName()}>
      <BoardHeader />
      <Canvas updateElement={updateElement} />
      <Outline updateElement={updateElement} />
      <Table updateElement={updateElement} />
      <LayerTabs className="layer-tab" />

      {/* Display context menu if right click occurs */}
      {contextMenuOpen && contextMenuId === menuTargetId && (
        <ContextMenu
          contextMenuX={contextMenuX}
          contextMenuY={contextMenuY}
          elementId={menuTargetId}
        />
      )}
      {minimizeScreen && !fullScreen && <BlowupMenu text={minimizeScreen} />}

      {/* notification modal -- automatically closes after 5 seconds. */}
      <Notification />
    </Styles>
  );
};

export default BoardView;

const Styles: any = styled.div`
  position: relative;
`;
