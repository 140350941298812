export default {
  starter: {
    title: 'starter',
    price: 'FREE',
    text: 'A easy way to get started for new users.',
    subText: '',
    stats: ['3 boards', '50 elements', '3 layers'],
  },
  'personal-monthly': {
    title: 'personal',
    price: '$8',
    subText: 'per month, billed monthly',
    text: 'More boards and more elements for more productivity.',
    stats: ['10 boards', '500 elements', '3 layers'],
  },
  'personal-yearly': {
    title: 'personal',
    price: '$6',
    subText: 'per month, billed yearly',
    text: 'More boards and more elements for more productivity.',
    stats: ['10 boards', '500 elements', '3 layers'],
  },
  'professional-monthly': {
    title: 'professional',
    price: '$32',
    subText: 'per month, billed monthly',
    text: 'More boards and elements PLUS additional layers for deeper comparative analysis.',
    stats: ['25 boards', '5000 elements', '30 layers'],
  },
  'professional-yearly': {
    title: 'professional',
    price: '$24',
    subText: 'per month, billed yearly',
    text: 'More boards and elements PLUS additional layers for deeper comparative analysis.',
    stats: ['25 boards', '5000 elements', '30 layers'],
  },
  enterprise: {
    title: 'enterprise',
    price: undefined,
    subText: 'Custom Pricing',
    text: 'For companies seeking enterprise-grade controls, integrations, and support.',
    stats: ['Unlimited boards', 'Unlimited elements', 'Unlimited layers'],
  },
};
