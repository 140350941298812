import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ReadOnlyColumn from './Column';

// data management
import { tableContext, elementContext, globalContext } from '../../../store/contexts';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  tableWidth: number,
};

const ReadOnlyColumnRow = ({ tableWidth }: propTypes) => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { elementWidth, ponWidth } = globalState.board;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  return (
    <Styles style={{ width: tableWidth }}>
      {elements.length > 0 && (
        <div className="column-titles">
          <div
            className="cell"
            style={{
              width: elementWidth < 80 ? 80 : elementWidth + 10,
              minWidth: elementWidth < 80 ? 80 : elementWidth + 10,
            }}
          >
            <p>Element</p>
          </div>

          <div
            className="cell"
            style={{
              minWidth: ponWidth < 70 ? 70 : ponWidth + 10,
              width: ponWidth < 70 ? 70 : ponWidth + 10,
              marginRight: 15,
            }}
          >
            <p>PON</p>
          </div>

          {columns.map(column => {
            const { columnId } = column;
            return <ReadOnlyColumn key={columnId} column={column} />;
          })}
        </div>
      )}
    </Styles>
  );
};

export default ReadOnlyColumnRow;

const Styles: any = styled.div`
  background: ${colors.paleGreen};
  padding-left: 15px;

  position: sticky;
  top: 30px;

  .column-titles {
    display: flex;
    align-items: center;
  }

  .cell p {
    font-family: 'SofiaProBold';
    padding: 0 18px 0px 4px;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
