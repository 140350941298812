import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import { globalContext, configContext } from '../store/contexts';
import {
  UPDATE_BOARD_SUCCESS,
  UPDATE_BOARD_START,
  UPDATE_BOARD_FAIL,
  SET_MODAL_CONFIG,
} from '../store/constants';

// utility
import { generatePublicKey } from '../util/publicKey';
import { setLocalTokenizedStorage } from '../util/localStorage';
import useUpdateBoardStatus from './useUpdateBoardStatus';

const useGetBoardPublicKey = () => {
  // global store
  const [, globalDispatch] = useStateValue(globalContext);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // destructure updatePublicStatus
  const { updatePublicStatus } = useUpdateBoardStatus();

  const getBoardPublicKey = async (board: Object) => {
    const { isPublic, boardPublicKey, boardId } = board;

    // if the board is private, make it public
    if (!isPublic && boardPublicKey) updatePublicStatus(board);

    // generate boardPublicKey Key if it doesn't exist.
    if (!boardPublicKey) {
      const publicBoardData = {
        boardId,
        boardPublicKey: generatePublicKey(boardId),
        isPublic: true,
      };

      try {
        globalDispatch({ type: UPDATE_BOARD_START });
        const { status } = await Axios.post('/boardShare', publicBoardData);

        if (status === 201) {
          globalDispatch({ type: UPDATE_BOARD_SUCCESS, payload: { ...board, ...publicBoardData } });

          // tokenize board and store in local storage for refresh reference.
          setLocalTokenizedStorage('board', { ...board, ...publicBoardData });
        }
      } catch (error) {
        globalDispatch({ type: UPDATE_BOARD_FAIL });
      }
    }

    // open share modal with public link
    configDispatch({
      type: SET_MODAL_CONFIG,
      payload: { open: true, type: 'boardShare', isPublic, boardId, boardPublicKey },
    });

    // tokenize board and store in local storage for refresh reference.
    setLocalTokenizedStorage('board', board);
  };

  return { getBoardPublicKey };
};

export default useGetBoardPublicKey;
