// global
export const GET_BOARD_START = 'GET_BOARD_START';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_FAIL = 'GET_BOARD_FAIL';

export const GET_BOARDS_START = 'GET_BOARDS_START';
export const GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';
export const GET_BOARDS_FAIL = 'GET_BOARDS_FAIL';

export const ADD_BOARD_START = 'ADD_BOARD_START';
export const ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';
export const ADD_BOARD_FAIL = 'ADD_BOARD_FAIL';

export const UPDATE_BOARD_START = 'UPDATE_BOARD_START';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';
export const UPDATE_BOARD_FAIL = 'UPDATE_BOARD_FAIL';

export const DELETE_BOARD_START = 'DELETE_BOARD_START';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_FAIL = 'DELETE_BOARD_FAIL';

export const SELECT_BOARD = 'SELECT_BOARD';
export const SET_INITIAL_BOARD_LOADING = 'SET_INITIAL_BOARD_LOADING';

// config
export const SET_MODAL_CONFIG = 'SET_MODAL_CONFIG';

export const SET_NOTIFICATION_CONFIG = 'SET_NOTIFICATION_CONFIG';

export const SET_MENU_TARGET = 'SET_MENU_TARGET';
export const SET_CONTEXT_MENU_OPEN = 'SET_CONTEXT_MENU_OPEN';
export const SET_CONTEXT_MENU_POSITION = 'SET_CONTEXT_MENU_POSITION';

export const SET_CANVAS_CONFIG = 'SET_CANVAS_CONFIG';
export const SET_TABLE_CONFIG = 'SET_TABLE_CONFIG';

export const SET_FULL_SCREEN = 'SET_FULL_SCREEN';
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
export const SET_HIGHLIGHT = 'SET_HIGHLIGHT';
export const SET_ACTIVE_LAYER_ID = 'SET_LAYER_ID';
export const SET_DRAGGING_ID = 'SET_DRAGGING_ID';

export const SET_MINIMIZE_VIEW = 'SET_MINIMIZE_VIEW';

// table
export const GET_TABLE_START = 'GET_TABLE_START';
export const GET_TABLE_SUCCESS = 'GET_TABLE_SUCCESS';
export const GET_TABLE_FAIL = 'GET_TABLE_FAIL';

export const ADD_COLUMN_START = 'ADD_COLUMN_START';
export const ADD_COLUMN_SUCCESS = 'ADD_COLUMN_SUCCESS';
export const ADD_COLUMN_FAIL = 'ADD_COLUMN_FAIL';

export const UPDATE_COLUMN_START = 'UPDATE_COLUMN_START';
export const UPDATE_COLUMN_SUCCESS = 'UPDATE_COLUMN_SUCCESS';
export const UPDATE_COLUMN_FAIL = 'UPDATE_COLUMN_FAIL';

export const DELETE_COLUMN_START = 'DELETE_COLUMN_START';
export const DELETE_COLUMN_SUCCESS = 'DELETE_COLUMN_SUCCESS';
export const DELETE_COLUMN_FAIL = 'DELETE_COLUMN_FAIL';

export const ADD_LAYER_START = 'ADD_LAYER_START';
export const ADD_LAYER_SUCCESS = 'ADD_LAYER_SUCCESS';
export const ADD_LAYER_FAIL = 'ADD_LAYER_FAIL';

export const UPDATE_LAYER_START = 'UPDATE_LAYER_START';
export const UPDATE_LAYER_SUCCESS = 'UPDATE_LAYER_SUCCESS';
export const UPDATE_LAYER_FAIL = 'UPDATE_LAYER_FAIL';

export const DELETE_LAYER_START = 'DELETE_LAYER_START';
export const DELETE_LAYER_SUCCESS = 'DELETE_LAYER_SUCCESS';
export const DELETE_LAYER_FAIL = 'DELETE_LAYER_FAIL';

// element
export const GET_INITIAL_ELEMENTS_START = 'GET_INITIAL_ELEMENTS_START';
export const GET_INITIAL_ELEMENTS_SUCCESS = 'GET_INITIAL_ELEMENTS_SUCCESS';
export const GET_INITIAL_ELEMENTS_FAIL = 'GET_INITIAL_ELEMENTS_FAIL';

export const GET_ELEMENTS_START = 'GET_ELEMENTS_START';
export const GET_ELEMENTS_SUCCESS = 'GET_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_FAIL = 'GET_ELEMENTS_FAIL';

export const GET_LAYER_START = 'GET_LAYER_START';
export const GET_LAYER_SUCCESS = 'GET_LAYER_SUCCESS';
export const GET_LAYER_FAIL = 'GET_LAYER_FAIL';

export const GET_ELEMENT_START = 'GET_ELEMENT_START';
export const GET_ELEMENT_SUCCESS = 'GET_ELEMENT_SUCCESS';
export const GET_ELEMENT_FAIL = 'GET_ELEMENT_FAIL';

export const ADD_ELEMENT_START = 'ADD_ELEMENT_START';
export const ADD_ELEMENT_SUCCESS = 'ADD_ELEMENT_SUCCESS';
export const ADD_ELEMENT_FAIL = 'ADD_ELEMENT_FAIL';

export const UPDATE_ELEMENT_START = 'UPDATE_ELEMENT_START';
export const UPDATE_ELEMENT_SUCCESS = 'UPDATE_ELEMENT_SUCCESS';
export const UPDATE_ELEMENT_FAIL = 'UPDATE_ELEMENT_FAIL';

export const DELETE_ELEMENT_START = 'DELETE_ELEMENT_START';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAIL = 'DELETE_ELEMENT_FAIL';

export const SET_TITLE = 'SET_TITLE';

// quality
export const ADD_SIMPLE_QUALITY_START = 'ADD_SIMPLE_QUALITY_START';
export const ADD_SIMPLE_QUALITY_SUCCESS = 'ADD_SIMPLE_QUALITY_SUCCESS';
export const ADD_SIMPLE_QUALITY_FAIL = 'ADD_SIMPLE_QUALITY_FAIL';

export const ADD_QUALITY_START = 'ADD_QUALITY_START';
export const ADD_QUALITY_SUCCESS = 'ADD_QUALITY_SUCCESS';
export const ADD_QUALITY_FAIL = 'ADD_QUALITY_FAIL';

export const UPDATE_QUALITY_START = 'UPDATE_QUALITY_START';
export const UPDATE_QUALITY_SUCCESS = 'UPDATE_QUALITY_SUCCESS';
export const UPDATE_QUALITY_FAIL = 'UPDATE_QUALITY_FAIL';

export const DELETE_QUALITY_START = 'DELETE_QUALITY_START';
export const DELETE_QUALITY_SUCCESS = 'DELETE_QUALITY_SUCCESS';
export const DELETE_QUALITY_FAIL = 'DELETE_QUALITY_FAIL';

// profile
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const ADD_CHARGEBEE_PORTAL_REQUEST_START = 'CHARGEBEE_PORTAL_REQUEST_START';
export const ADD_CHARGEBEE_PORTAL_REQUEST_SUCCESS = 'CHARGEBEE_PORTAL_REQUEST_SUCCESS';
export const ADD_CHARGEBEE_PORTAL_REQUEST_FAIL = 'CHARGEBEE_PORTAL_REQUEST_FAIL';

export const GET_CHARGEBEE_SUBSCRIPTION_START = 'GET_CHARGEBEE_SUBSCRIPTION_START';
export const GET_CHARGEBEE_SUBSCRIPTION_SUCCESS = 'GET_CHARGEBEE_SUBSCRIPTION_SUCCESS';
export const GET_CHARGEBEE_SUBSCRIPTION_FAIL = 'GET_CHARGEBEE_SUBSCRIPTION_FAIL';

export const SET_USER_COUNTS = 'SET_USER_COUNTS';
