import React from 'react';
import styled from 'styled-components';

// components
import FullScreenButton from '../../../components/General/FullScreenButton';

// theme
import { colors } from '../../../styles/theme';

const Header = () => {
  return (
    <Styles>
      <div />
      <FullScreenButton componentName="outline" />
    </Styles>
  );
};

export default Header;

const Styles: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  background: ${colors.white};

  position: sticky;
  top: 0;
`;
