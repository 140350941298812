const freemiumConfig = {
  starter: {
    boardMax: 3,
    elementMax: 50,
    addedLayerMax: 3,
  },
  personal: {
    boardMax: 10,
    elementMax: 500,
    addedLayerMax: 3,
  },
  professional: {
    boardMax: 25,
    elementMax: 5000,
    addedLayerMax: 30,
  },
  enterprise: {
    boardMax: 999999999999,
    elementMax: 999999999999,
    addedLayerMax: 999999999999,
  },
};

export default freemiumConfig;
