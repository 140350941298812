import React from 'react';
import styled from 'styled-components';

// components
import FullScreenButton from '../../../components/General/FullScreenButton';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  tableWidth: number,
};

const Header = ({ tableWidth }: propTypes) => {
  return (
    <Styles style={{ width: tableWidth }}>
      <div />
      <FullScreenButton componentName="table" />
    </Styles>
  );
};

export default Header;

const Styles: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 30px;
  z-index: 1;
  background: ${colors.white};
`;
