import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import Axios from '../../../../config/axios_config';

// data management
import { elementContext, globalContext, configContext } from '../../../../store/contexts';
import {
  ADD_ELEMENT_START,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  UPDATE_ELEMENT_SUCCESS,
  SET_ACTIVE_VIEW,
} from '../../../../store/constants';

// utility
import generatePON from '../../../../util/generatePON';
import focusInputId from '../../../../util/focusInputId';
import { generateXPosition, generateYPosition } from '../../../../util/canvasMath';
import { useFindParentElement } from '../../../../hooks/useFindElement';
import useFreemium from '../../../../hooks/useFreemium';

// theme
import { colors } from '../../../../styles/theme';

const EmptyElement = () => {
  const [title, setTitle] = useState('');

  // global store
  const [globalState] = useStateValue(globalContext);
  const { board } = globalState;
  const { elementWidth } = board;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeLayerId } = configState;

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { lastElement } = elementState;
  const { elementId, parentId, boardId } = lastElement;

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  // destructure utility functions from hooks
  const [findParentElement] = useFindParentElement();

  // destructure checkElementCount
  const { checkElementCount } = useFreemium();

  // element required data - { title, xCoord, yCoord, boardId, PON, parentId}
  const addChildElement = async () => {
    if (title.length > 0 && lastElement) {
      if (!inProgress) {
        // start post request
        setInProgress(true);
        const allowPost = checkElementCount();

        if (allowPost) {
          try {
            const newPON = generatePON(lastElement);

            elementDispatch({ type: ADD_ELEMENT_START });
            // post new element with parentId connection to previous element
            const { data } = await Axios.post(
              `/elements/board/${boardId}/parent/${elementId}/layer/${activeLayerId}`,
              {
                title,
                PON: newPON,
                xCoord: generateXPosition(lastElement),
                yCoord: generateYPosition(lastElement),
                parentId: elementId,
              }
            );

            if (data) {
              // post success - dispatch new data to store
              setInProgress(false);

              // dispatch newly added element
              elementDispatch({ type: ADD_ELEMENT_SUCCESS, payload: data });
              elementDispatch({
                type: UPDATE_ELEMENT_SUCCESS,
                payload: {
                  ...lastElement,
                  childrenPON: [...lastElement.childrenPON, newPON],
                },
              });
              setTitle('');

              focusInputId('empty-table-input');
            }
          } catch (error) {
            //  end post request
            setInProgress(false);
            elementDispatch({ type: ADD_ELEMENT_FAIL });
          }
        }
        // post request not triggered
        setInProgress(false);
      }
    }
  };

  // create a sibling element to the current element
  const addSiblingElement = async () => {
    if (title.length > 0) {
      const parent = findParentElement(lastElement);

      if (!inProgress) {
        // start post request
        setInProgress(true);
        const allowPost = checkElementCount();

        if (allowPost) {
          try {
            const newPON = generatePON(parent);

            elementDispatch({ type: ADD_ELEMENT_START });
            const { data } = await Axios.post(
              `/elements/board/${boardId}/parent/${parent.elementId}/layer/${activeLayerId}`,
              {
                title,
                PON: newPON,
                xCoord: generateXPosition(parent),
                yCoord: generateYPosition(parent),
                parentId: parent.elementId,
              }
            );

            // dispatch newly added element
            if (data) {
              // post success - dispatch new data to store
              setInProgress(false);

              elementDispatch({ type: ADD_ELEMENT_SUCCESS, payload: data });
              elementDispatch({
                type: UPDATE_ELEMENT_SUCCESS,
                payload: {
                  ...parent,
                  childrenPON: [...parent.childrenPON, newPON],
                },
              });
              setTitle('');

              // update focus to new element
              focusInputId('empty-table-input');
            }
          } catch (error) {
            //  end post request
            setInProgress(false);

            elementDispatch({ type: ADD_ELEMENT_FAIL });
          }
        }
      }
    }
  };

  return (
    <Styles>
      <div className="cell">
        <input
          id="empty-table-input"
          type="text"
          style={{ width: elementWidth + 10 || null }}
          placeholder="Add element"
          value={title}
          aria-label={title || 'add element input'}
          onChange={e => setTitle(e.target.value)}
          onFocus={() => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' })}
          onBlur={() => {
            if (parentId) addSiblingElement();
            if (!parentId) addChildElement();
          }}
          onKeyDown={e => {
            if (e.keyCode === 27) setTitle(''); // escape key
            if (e.keyCode === 13 && title !== '') e.target.blur(); // return key
            if (e.keyCode === 8 && title === '') focusInputId(`table${elementId}`, e); // delete key
            if (e.keyCode === 38) focusInputId(`table${elementId}`); // up arrow
          }}
        />
      </div>
    </Styles>
  );
};

export default EmptyElement;

const Styles: any = styled.div`
  display: flex;
  padding-left: 15px;

  input {
    border: 3px solid ${colors.white};
  }
`;
