import React from 'react';
import styled from 'styled-components';

// components
import Link from '../../../components/General/Link';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {}; // eslint-disable-line

const settingsNav = ['account'];

const SettingsNavigation = () => {
  const { pathname } = window.location;

  return (
    <Styles>
      {settingsNav.map(item => (
        <Link
          key={item}
          to={`/dashboard/settings/${item}`}
          className={pathname === `/dashboard/settings/${item}` ? 'menu-item active' : 'menu-item'}
        >
          {item}
        </Link>
      ))}
    </Styles>
  );
};

export default SettingsNavigation;

const Styles: any = styled.div`
  display: flex;
  margin-top: 30px;
  border-bottom: solid 2px ${colors.paleGrey};

  /* menu items */
  .menu-item {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;
    letter-spacing: 0.44px;
    text-transform: capitalize;

    width: 120px;
    min-width: 120px;
    height: 45px;
    min-height: 45px;
    margin-right: 5px;

    border: 0;
    border-radius: 0;
    border-bottom: 3px solid transparent;

    :hover {
      border-bottom: 3px solid ${colors.purple} !important;
    }

    :focus {
      outline: none;
      border-bottom: 3px solid ${colors.purple} !important;
    }
  }

  .active {
    color: ${colors.darkGrey};
    border-bottom: 3px solid ${colors.purple} !important;

    :hover {
      color: ${colors.mediumGrey};
    }
  }
`;
