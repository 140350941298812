import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import { createPortal } from 'react-dom';

// components
import DeleteModal from './DeleteModal';
import FreemiumModal from './FreemiumModal';
import ShareModal from './ShareModal';
import SurveyModal from './SurveyModal';

// data management
import { configContext } from '../../../store/contexts';
import { SET_MODAL_CONFIG } from '../../../store/constants';

// theme
import { colors } from '../../../styles/theme';

const Modal = () => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { modalConfig } = configState;

  // check the survey modal page
  const [page, setPage] = useState(1);

  // modal div reference - $FlowFixMe
  const modalRef = useRef(null);

  if (!modalRef.current) {
    const div = document.createElement('div');
    modalRef.current = div;
  }

  // set and remove modal from DOM.
  useEffect(() => {
    const modalRoot = document.getElementById('modal');

    if (modalRoot) modalRoot.appendChild(modalRef.current);

    return () => {
      if (modalRoot) modalRoot.removeChild(modalRef.current);
    };
  }, []);

  // function to close menu on event trigger
  const closeMenu = useCallback(
    ({ keyCode }: Object) => {
      if (keyCode === 27 && modalConfig.type !== 'surveyForm') {
        configDispatch({ type: SET_MODAL_CONFIG, payload: { open: false } });
      }
    },
    [configDispatch, modalConfig.type]
  );

  // attach close menu listeners when context menu is opened
  useEffect(() => {
    document.addEventListener('keydown', closeMenu, false);

    return () => document.removeEventListener('keydown', closeMenu, false);
  }, [closeMenu]);

  // sends dispatch to close modal.
  const closeModal = () => configDispatch({ type: SET_MODAL_CONFIG, payload: { open: false } });

  const [modalHeightPosition, setModalHeightPosition] = useState(0);
  useEffect(() => {
    const modalWindow = modalRef.current.querySelector('.modal-window');
    // calculate  modal position in center of screen
    if (modalWindow) setModalHeightPosition(window.innerHeight / 2 - modalWindow.clientHeight / 2);
  }, [modalRef, page]);

  return createPortal(
    <Styles onClick={modalConfig.type !== 'surveyForm' ? closeModal : null}>
      {/* eslint-disable-next-line */} {/* to fix later */}
      <div
        onClick={e => e.stopPropagation()}
        className="modal-window"
        style={{ top: modalHeightPosition }}
      >
        {modalConfig.type === 'delete' && (
          <DeleteModal modalConfig={modalConfig} closeModal={closeModal} />
        )}

        {modalConfig.type === 'freemium' && (
          <FreemiumModal modalConfig={modalConfig} closeModal={closeModal} />
        )}

        {modalConfig.type === 'boardShare' && (
          <ShareModal modalConfig={modalConfig} closeModal={closeModal} />
        )}

        {modalConfig.type === 'surveyForm' && (
          <SurveyModal closeModal={closeModal} setPage={setPage} page={page} />
        )}
      </div>
    </Styles>,
    modalRef.current
  );
};

export default Modal;

const Styles: any = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background:  rgba(0, 0, 0, 0.5);
  z-index: 2;

  .modal-window {
    position: absolute;
    left: calc(50% - 220px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 200px;
    width: 504px;
    padding: 32px;

    border-radius: 4px;
    box-shadow: ${colors.boxShadow};
    background: ${colors.background};

    z-index: 100;

    h3 {
      font-size: 2rem;
      font-family: 'SofiaProBold';
      margin-bottom: 8px;
      padding: 0;
    }

    h4 {
      font-family: 'SofiaProBold';
      font-size: 1.5rem;
    }

    p {
      font-size: 1.5rem;
    }

    img {
      height: 1.4rem;
      width: 1.4rem;
    }

    .survey-modal-wrapper {
      display: flex;
      flex-direction: column;

      width: 440px;

      legend {
        font-size: 2rem;
        font-family: 'SofiaProBold';
        margin-bottom: 32px;
      }

      input {
        border: 1px solid ${colors.black};
        border-radius: 4px;

        width: 100%;
        height: 40px;

        padding-left: 10px;
        margin-top: 4px;
        margin-bottom: 16px;
        :focus {
          border: 1px solid ${colors.purple};
        }
      }

      p {
        font-size: 12px;
        color: ${colors.mediumGrey};
        margin-bottom: 32px;
      }

      .role-button {
        display: block;
        background: ${colors.palePurple};
        border: none;
        text-align: start;

        height: 68px;

        margin-bottom: 16px;
        padding: 12px 16px;

        :last-child {
          margin-bottom: 0;
        }

        h2 {
          font-size: 20px;
          margin-bottom: 0;
          text-transform: initial;
        }

        p {
          font-size: 12px;
          font-family: 'sofiaPro';
        }

        :hover,
        :focus {
          background: ${colors.darkPurple};
          h2, p {
            color: ${colors.white};
          }
        }

      }
    }
  }

  @media (max-width: 550px) {
    .modal-window {
      left: calc(50% - 175px);

      width: 350px;

      .survey-modal-wrapper {
        width: 290px;

        h2 {
          font-size: 20px;
        }

        .role-button {
          height: 85px;
        }
    }
`;
