import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import { profileContext } from '../store/contexts';
import {
  GET_CHARGEBEE_SUBSCRIPTION_START,
  GET_CHARGEBEE_SUBSCRIPTION_SUCCESS,
  GET_CHARGEBEE_SUBSCRIPTION_FAIL,
} from '../store/constants';

// blank page which verifies chargebee subscription login sequence
const useChargebee = () => {
  // profile store
  const [, profileDispatch] = useStateValue(profileContext);

  // verify account has active chargebee account
  const getChargebeeSubscription = async (email: string) => {
    profileDispatch({ type: GET_CHARGEBEE_SUBSCRIPTION_START });

    try {
      const { data } = await Axios.post(`/chargebee/subscription`, { email });

      profileDispatch({ type: GET_CHARGEBEE_SUBSCRIPTION_SUCCESS, payload: data });
    } catch (error) {
      profileDispatch({ type: GET_CHARGEBEE_SUBSCRIPTION_FAIL });
    }
  };

  return { getChargebeeSubscription };
};

export default useChargebee;
