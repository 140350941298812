import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { tableContext } from '../../../../store/contexts';

// utility
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

type propTypes = {
  columnIndex: number,
  columnId: number,
};

const ColumnPositionButtons = ({ columnIndex, columnId }: propTypes) => {
  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // destructure request utility
  const update = useUpdateRequest();

  // adjust column position
  const moveColumnLeft = () => {
    const column = columns[columnIndex];
    const previous = columns[columnIndex - 1];

    // send request and update store
    update('table', 'column', `/columns/${columnId}`, { position: previous.position });
    update('table', 'column', `/columns/${previous.columnId}`, { position: column.position });
  };

  const moveColumnRight = () => {
    const column = columns[columnIndex];
    const next = columns[columnIndex + 1];

    // send request and update store
    update('table', 'column', `/columns/${columnId}`, { position: next.position });
    update('table', 'column', `/columns/${next.columnId}`, { position: column.position });
  };

  if (columnIndex === 0) {
    return (
      <Styles type="button" onClick={moveColumnRight}>
        Move Column Right
      </Styles>
    );
  }

  if (columnIndex === columns.length - 1) {
    return (
      <Styles type="button" onClick={moveColumnLeft}>
        Move Column Left
      </Styles>
    );
  }

  return (
    <>
      <Styles type="button" onClick={moveColumnRight}>
        Move Column Right
      </Styles>
      <Styles type="button" onClick={moveColumnLeft}>
        Move Column Left
      </Styles>
    </>
  );
};

export default ColumnPositionButtons;

const Styles: any = styled.button``;
