import React from 'react';

// theme
import { colors } from '../styles/theme';

type propTypes = {
  color: string,
};

const AddIcon = ({ color }: propTypes) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <title>ic_add_circle</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M17,13 L13,13 L13,17 L11,17 L11,13 L7,13 L7,11 L11,11 L11,7 L13,7 L13,11 L17,11 L17,13 L17,13 Z"
          id="path-1"
        />
        <rect id="path-3" x="0" y="0" width="50" height="50" />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="add-layer" transform="translate(-2.000000, -2.000000)">
          <g id="ic_add_circle">
            <g>
              <circle id="Oval" fill={colors.white} fillRule="evenodd" cx="12" cy="12" r="9" />
              <mask id="mask-2" fill={colors.white}>
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Mask" />
              <g id="Colors/primary-purple" mask="url(#mask-2)">
                <g transform="translate(-13.000000, -13.000000)">
                  <mask id="mask-4" fill={colors.white}>
                    <use xlinkHref="#path-3" />
                  </mask>
                  <use
                    id="Black"
                    stroke="none"
                    fill={color}
                    fillRule="evenodd"
                    xlinkHref="#path-3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddIcon;
