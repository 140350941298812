import React, { useState } from 'react';
import { useStateValue } from 'react-conflux';
import styled from 'styled-components';

// data management
import { globalContext, configContext } from '../../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../../store/constants';

// utility
import focusInputId from '../../../../util/focusInputId';
import usePostRequest from '../../../../requestHooks/usePostRequest';

type propTypes = {
  columns: Array<Object>,
};

const EmptyTextColumn = ({ columns }: propTypes) => {
  const [title, setTitle] = useState('');

  // global store
  const [globalState] = useStateValue(globalContext);
  const { selectedBoardId } = globalState;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // destructure request utility
  const postRequest = usePostRequest();

  // column required data - { title, boardId, position}
  const addColumn = async () => {
    if (title.length > 0 && columns) {
      const newColumn = {
        title,
        boardId: selectedBoardId,
        position: columns.length > 0 ? columns[columns.length - 1].position + 1 : 1,
      };

      // send post request and set data to store
      const addColumnSuccess = await postRequest(
        'table',
        'column',
        `/columns/board/${selectedBoardId}`,
        newColumn
      );

      if (addColumnSuccess) {
        setTitle('');

        // update focus to new column input
        focusInputId('empty-column-input');
      }
    }
  };

  return (
    <Styles className="cell">
      <input
        id="empty-column-input"
        type="text"
        placeholder="Add column"
        value={title}
        aria-label={title || 'add column input'}
        onChange={e => setTitle(e.target.value)}
        onFocus={() => {
          configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' });
        }}
        onBlur={addColumn}
        onKeyDown={e => {
          // escape key and return key
          if (e.keyCode === 27 || e.keyCode === 13) e.target.blur();
        }}
        style={{ minWidth: 140 }}
      />
    </Styles>
  );
};

export default EmptyTextColumn;

const Styles: any = styled.div`
  display: flex;
  position: relative;

  input {
    background: inherit;
  }
`;
