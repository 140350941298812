import React, { useEffect } from 'react';
import styled from 'styled-components';

// components
import Card from './Card';

type propTypes = {}; // eslint-disable-line

const Plans = () => {
  // ensure Chargebee instance is available for upgrade button.
  useEffect(() => {
    window.Chargebee.registerAgain();
  }, []);

  return (
    <Styles className="settings-container">
      <h3 className="container-title">BrainSquall Plans</h3>

      <div className="container-content plan-container">
        <Card plan="starter" />
        <Card plan="personal-monthly" />
        <Card plan="professional-monthly" />
        <Card plan="enterprise" />
      </div>
    </Styles>
  );
};

export default Plans;

const Styles: any = styled.div`
  .plan-container {
    margin: 0 auto;
    padding: 0 80px;

    /* Display Settings */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
    grid-gap: 20px;
    justify-items: center;

    @media (max-width: 800px) {
      padding: 40px 20px 0 20px;
    }

    @media (max-width: 600px) {
      padding: 40px 0 0 0;
    }
  }
`;
