import { useState, useCallback, useEffect } from 'react';
import { useStateValue } from 'react-conflux';

// data management
import { elementContext, configContext } from '../store/contexts';

// utility
import { getScaleAdjustment } from './useCanvasUtility';

const useDraggable = () => {
  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  // config store
  const [configState] = useStateValue(configContext);
  const { scale } = configState.canvasConfig;

  // local canvas state
  const [state, setState] = useState({
    isDragging: false,
    origin: { x: 0, y: 0 },
    translation: { x: 0, y: 0 },
  });

  // sets the position of origin to the mouse clientX & clientY - the previous origin
  const handleMouseDown = useCallback(
    ({ clientX, clientY, currentTarget, target, button }: Object) => {
      if (currentTarget === target && button === 0) {
        setState(prevState => ({
          ...prevState,
          isDragging: true,
          origin: { x: clientX - state.origin.x, y: clientY - state.origin.y },
        }));
      }
    },
    [] // eslint-disable-line
  );

  // moves the canvas when holding mouse down
  const handleMouseMove = useCallback(
    ({ clientX, clientY }) => {
      const translation = {
        x: clientX - state.origin.x + state.translation.x,
        y: clientY - state.origin.y + state.translation.y,
      };
      setState(prevState => ({ ...prevState, translation }));
    },
    [state.origin] // eslint-disable-line
  );

  const handleMouseUp = () => {
    setState(prevState => ({ ...prevState, isDragging: false }));
  };

  // adds event listener and removes it based on isDragging
  useEffect(() => {
    if (state.isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [state.isDragging, handleMouseMove]);

  const resetPosition = () => {
    const canvas = document.getElementById('svg-canvas');

    if (canvas) {
      const { width, height } = canvas.getBoundingClientRect();
      const halfWidth = Math.floor(width / 2);
      const halfHeight = Math.floor(height / 2);
      const scaleAdjustment = getScaleAdjustment(scale);
      const xOffset = 75 / scaleAdjustment;
      const yOffset = 30 / scaleAdjustment;

      // root element
      const { xCoord, yCoord } = elements.find(el => el.parentId === null);

      const x = halfWidth - xCoord / scaleAdjustment - xOffset;
      const y = halfHeight - yCoord / scaleAdjustment - yOffset;

      setState(prevState => ({ ...prevState, translation: { x, y } }));
    }
  };

  return {
    handleMouseDown,
    handleMouseUp,
    resetPosition,
    translation: state.translation,
  };
};

export default useDraggable;
