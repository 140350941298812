import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';
import Axios from '../config/axios_config';

// data management
import { globalContext, configContext } from '../store/contexts';
import {
  SELECT_BOARD,
  GET_BOARD_SUCCESS,
  SET_ACTIVE_LAYER_ID,
  SET_CANVAS_CONFIG,
} from '../store/constants';

// utility
import useGetRequest from '../requestHooks/useGetRequest';
import { decodePublicKey } from '../util/publicKey';

declare class process {
  static env: {
    REACT_APP_PK: string,
  };
}

// token secret
const secret = process.env.REACT_APP_PK;

const useGetPublicBoardData = () => {
  // global store
  const [, globalDispatch] = useStateValue(globalContext);

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // destructure request utility
  const getRequest = useGetRequest();

  const getPublicBoardData = async (publicKey: string) => {
    const publicBoardId = decodePublicKey(publicKey);

    // publicBoardId of 0 is an invalid key.
    if (publicBoardId === 0)
      globalDispatch({ type: GET_BOARD_SUCCESS, payload: { isPublic: false } });

    if (publicBoardId !== 0) {
      // tokenize publicBoardId for middlewareToken and attach to Axios instance
      const middlewareToken = jwt.sign(publicBoardId, secret);
      Axios.defaults.headers.common.Authorization = middlewareToken;

      const { data } = await Axios.get(`/boards/${publicBoardId}`);

      // if board isPublic is false, route to 404 page.
      if (data.isPublic === false)
        globalDispatch({ type: GET_BOARD_SUCCESS, payload: { ...data, isPublic: false } });

      if (data && data.isPublic) {
        const { boardId, layerId, labelId } = data;

        // set config
        globalDispatch({ type: SELECT_BOARD, payload: boardId });
        globalDispatch({ type: GET_BOARD_SUCCESS, payload: data });
        configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });
        configDispatch({ type: SET_CANVAS_CONFIG, payload: labelId });

        // send get request and set data to store
        const elementsData = await getRequest(
          'element',
          'elements',
          `/elements/qualities/board/${boardId}/layer/${layerId}`
        );

        // if elements data comes back, get table column data
        if (elementsData) {
          // send get request and set data to store
          getRequest('table', 'table', `/table/board/${publicBoardId}`);
        }
      }
    }
    return publicBoardId;
  };

  return { getPublicBoardData };
};

export default useGetPublicBoardData;
