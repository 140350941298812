// validate title is not a duplicate
const validateTitle = (items: Array<Object>, title: string) => {
  const duplicate = items.find(item => item.title.toLowerCase() === title.toLowerCase());

  // Returns false if new title is a duplicate
  if (duplicate) return false;

  // Otherwise return true for valid title
  return true;
};

export default validateTitle;
