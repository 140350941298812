import axios from 'axios';

declare class process {
  static env: {
    REACT_APP_ENV: string,
    REACT_APP_PROD_SERVER: string,
    REACT_APP_DEV_SERVER: string,
    REACT_APP_LOCAL_SERVER: string,
    REACT_APP_STORAGE: string,
  };
}

// generate baseURL for server based on environment
const generateBaseURL = () => {
  if (process.env.REACT_APP_ENV === 'development') return process.env.REACT_APP_DEV_SERVER;
  if (process.env.REACT_APP_ENV === 'production') return process.env.REACT_APP_PROD_SERVER;

  return process.env.REACT_APP_LOCAL_SERVER;
};

const Axios = axios.create({
  baseURL: generateBaseURL(),
  timeout: 12000, // 12 second request timeout.
});

export default Axios;
