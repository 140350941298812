import { useStateValue } from 'react-conflux';

// data management
import { configContext, elementContext } from '../store/contexts';

const useHighlightDeleting = () => {
  // config store
  const [configState] = useStateValue(configContext);
  const { modalConfig } = configState;
  const pendingDeletion = modalConfig.elementId;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  const getPendingDeletion = () => {
    const element = elements.find(({ elementId }) => elementId === pendingDeletion);

    if (element) {
      // find all children elements of targeted element
      const pending = elements.filter(({ PON }) => PON.startsWith(`${element.PON}.`));

      // extract ids from array
      const pendingIds = pending.map(({ elementId }) => elementId);

      // return array of elements included in the deletion branch
      return [pendingDeletion, ...pendingIds];
    }

    return []; // no matching elements
  };

  return { getPendingDeletion };
};

export default useHighlightDeleting;
