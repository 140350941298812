import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ReadOnlyElements from './Elements';
import Header from '../../BoardView/Table/Header';
import Loader from '../../../components/General/Loader';
import ReadOnlyColumnRow from './ColumnRow';

// data management
import { elementContext, tableContext, configContext } from '../../../store/contexts';
import { SET_ACTIVE_VIEW } from '../../../store/constants';

const Table = () => {
  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { fullScreen, activeView, minimizeScreen } = configState;

  // table store
  const [tableState] = useStateValue(tableContext);
  const { columns } = tableState;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { elementsLoading } = elementState;

  // table ref
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);

  // set table width when elements finish loading and when columns are added.
  useEffect(() => {
    if (tableRef.current && !elementsLoading) setTableWidth(tableRef.current.scrollWidth);
  }, [elementsLoading, columns.length, fullScreen, columns]);

  // handle className logic for  fullscreen, minimize screen and active view
  const determineClassName = () => {
    if (fullScreen === 'table') return 'full-screen-view table active-view-table';
    if (fullScreen === 'outline' || fullScreen === 'canvas' || minimizeScreen === 'table')
      return 'hide';

    // update margin when the board becomes two columns
    if (activeView === 'table' && minimizeScreen === 'canvas')
      return 'table update-margin active-view-table';
    if (minimizeScreen === 'canvas') return 'table update-margin';
    if (activeView === 'table') return 'active-view-table table';

    return 'table';
  };

  const setView = () => configDispatch({ type: SET_ACTIVE_VIEW, payload: 'table' });

  return (
    <Styles onClick={setView} className={determineClassName()}>
      <Header tableWidth={tableWidth} />

      <div
        className={fullScreen === 'table' ? 'tall-table-content' : 'table-content'}
        ref={tableRef}
      >
        {elementsLoading ? (
          <Loader containerHeight="35vh" />
        ) : (
          <>
            <ReadOnlyColumnRow tableWidth={tableWidth} />
            <ReadOnlyElements tableWidth={tableWidth} />
          </>
        )}
      </div>
    </Styles>
  );
};

export default Table;

const Styles: any = styled.div``;
