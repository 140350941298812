import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// data management
import { configContext, elementContext } from '../../../store/contexts';
import {
  SET_TITLE,
  SET_HIGHLIGHT,
  SET_MENU_TARGET,
  SET_ACTIVE_VIEW,
} from '../../../store/constants';

// utility
import focusInputId from '../../../util/focusInputId';
import { useFindNextElement, useFindPreviousElement } from '../../../hooks/useFindElement';

type propTypes = {
  index: number,
  element: Object,
  elements: Array<Object>,
  updateElement: Function,
  inputValue: string,
  setInputValue: Function,
  addChildElement: Function,
  addSiblingElement: Function,
  addText: Function,
  updateElementToChild: Function,
  updateElementToSibling: Function,
  deleteElement: Function,
};

const Title = ({
  index,
  element,
  elements,
  updateElement,
  inputValue,
  setInputValue,
  addChildElement,
  addSiblingElement,
  addText,
  updateElementToChild,
  updateElementToSibling,
  deleteElement,
}: propTypes) => {
  const { elementId, parentId, textQuality, PON } = element;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { activeEdit } = elementState;

  // destructure utility functions from hooks
  const [findPreviousElement] = useFindPreviousElement();
  const [findNextElement] = useFindNextElement();

  // find next and previous
  const next = findNextElement(index);
  const previous = findPreviousElement(index);

  return (
    <Styles
      data-testid={`outline${elementId}`}
      id={`outline${elementId}`}
      type="text"
      value={(activeEdit.elementId === elementId && activeEdit.title) || inputValue}
      aria-label={inputValue || 'empty outline input'}
      placeholder={elements[0].elementId === elementId ? 'Or type here...' : ''}
      onFocus={() => {
        configDispatch({ type: SET_MENU_TARGET, payload: elementId });
        configDispatch({ type: SET_HIGHLIGHT, payload: elementId });
        configDispatch({ type: SET_ACTIVE_VIEW, payload: 'outline' });
      }}
      onBlur={() => {
        updateElement(activeEdit);
        configDispatch({ type: SET_HIGHLIGHT, payload: undefined });
      }}
      onChange={e => {
        setInputValue(e.target.value);
        elementDispatch({ type: SET_TITLE, payload: { elementId, title: e.target.value } });
      }}
      onKeyDown={e => {
        if (e.keyCode === 27) e.target.blur(); // escape key
        if (!e.shiftKey && e.keyCode === 13) addSiblingElement(); // return key
        if (parentId === null && !next && !e.shiftKey && e.keyCode === 13) addChildElement(); // return key from root
        if (parentId === null && next && textQuality && e.keyCode === 13) addChildElement(); // return key from root
        if (!textQuality && e.shiftKey && e.keyCode === 13) addText(); // shift + return key
        if (
          !textQuality &&
          !next &&
          previous &&
          previous.childrenPON.length === 0 &&
          !e.shiftKey &&
          e.keyCode === 9
        ) {
          updateElementToChild(e); // tab key
        }
        if (
          !textQuality &&
          !next &&
          previous &&
          previous.PON.length !== PON.length &&
          e.shiftKey &&
          e.keyCode === 9
        ) {
          updateElementToSibling(e); // shift + tab key
        }
        if (inputValue === '' && !textQuality && e.keyCode === 8) deleteElement(); // delete key
        if (previous && e.keyCode === 38) focusInputId(`outline${previous.elementId}`); // up arrow
        if (next && e.keyCode === 40) focusInputId(`outline${next.elementId}`); // down arrow
      }}
    />
  );
};

export default Title;

const Styles: any = styled.input`
  width: 100%;
  height: 26px;
  font-weight: bold;
  border: 2px solid transparent;
  background: transparent;
`;
