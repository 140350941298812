// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';
import Axios from '../config/axios_config';

// data management
import { profileContext } from '../store/contexts';
import { GET_USER_START, GET_USER_SUCCESS, GET_USER_FAIL } from '../store/constants';

// utility
import useChargebee from '../hooks/useChargebee';

declare class process {
  static env: {
    REACT_APP_PK: string,
  };
}

// token secret
const secret = process.env.REACT_APP_PK;

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children, // eslint-disable-line
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, // eslint-disable-line
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [, profileDispatch] = useStateValue(profileContext);
  const { getChargebeeSubscription } = useChargebee();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const authenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(authenticated);

      if (authenticated) {
        const userFromAuth0 = await auth0FromHook.getUser();

        // eslint-disable-next-line camelcase
        const { email, name, nickname, given_name, family_name } = userFromAuth0;

        const userForDb = {
          email,
          fullName: name,
          username: nickname,
          firstName: given_name,
          lastName: family_name,
        };

        // tokenize the user data
        const token = jwt.sign(userForDb, secret);
        const authHeaders = { headers: { Authorization: token } };

        // POST - send the token to the server to login or register a user
        profileDispatch({ type: GET_USER_START });
        const { data } = await Axios.post(`/users`, {}, authHeaders);

        // decrypt the token and verify it has not been tampered with
        const userFromDb = jwt.verify(data, secret);

        // dispatch user data to profile store
        if (userForDb.email === userFromDb.email) {
          profileDispatch({ type: GET_USER_SUCCESS, payload: userFromDb });

          // set token with userId to Axios instance for middleware
          const middlewareToken = jwt.sign(userFromDb.userId, secret);
          Axios.defaults.headers.common.Authorization = middlewareToken;

          // get and set chargebee subscription data to store
          getChargebeeSubscription(userFromDb.email);
        } else {
          profileDispatch({ type: GET_USER_FAIL });
        }
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const authenticatedUser = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(authenticatedUser);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
