import React from 'react';
import styled from 'styled-components';

type propTypes = {
  inputValue: string,
};

const ReadOnlyTitle = ({ inputValue }: propTypes) => {
  return <Styles>{inputValue || ''}</Styles>;
};

export default ReadOnlyTitle;

const Styles: any = styled.p`
  width: 100%;
  border: 2px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0px;
`;
