import React from 'react';
import { useStateValue } from 'react-conflux';
// auth  - $FlowFixMe
import { useAuth0 } from '../../../auth/AuthWrapper';
import Axios from '../../../config/axios_config';

// components
import DeleteButtons from '../../General/DeleteButtons';
import Header from './Header';

// data management
import {
  elementContext,
  configContext,
  tableContext,
  profileContext,
} from '../../../store/contexts';
import {
  UPDATE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_START,
  DELETE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_FAIL,
  SET_ACTIVE_LAYER_ID,
  SET_USER_COUNTS,
} from '../../../store/constants';

// utility
import { useFindParentElement } from '../../../hooks/useFindElement';
import useFilterChildren from '../../../hooks/useFilterChildren';
import useGetRequest from '../../../requestHooks/useGetRequest';
import useDeleteRequest from '../../../requestHooks/useDeleteRequest';
import useHistory from '../../../hooks/useHistory';

declare class process {
  static env: {
    REACT_APP_STORAGE: string,
  };
}

type propTypes = {
  modalConfig: Object,
  closeModal: Function,
};

const DeleteModal = ({ modalConfig, closeModal }: propTypes) => {
  // auth store
  const { logout } = useAuth0();

  // profile store
  const [profileState, profileDispatch] = useStateValue(profileContext);
  const { userCounts, user } = profileState;
  const { totalElements, addedLayers } = userCounts;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  // table store
  const [tableState] = useStateValue(tableContext);
  const { layers } = tableState;

  // element store
  const [elementState, elementDispatch] = useStateValue(elementContext);
  const { elements } = elementState;

  // destructure utility functions from hooks
  const [findParentElement] = useFindParentElement();
  const [filterChildren] = useFilterChildren();

  // destructure request utility
  const getRequest = useGetRequest();
  const deleteRequest = useDeleteRequest();

  const history = useHistory();

  // delete element functionality
  const deleteElement = async () => {
    const { elementId } = modalConfig;
    if (elementId && elements.length !== 1) {
      elementDispatch({ type: DELETE_ELEMENT_START });

      try {
        const { status } = await Axios.delete(`/elements/${elementId}`);

        if (status === 202) {
          // remove element from total element count
          profileDispatch({
            type: SET_USER_COUNTS,
            payload: { ...userCounts, totalElements: totalElements - 1 },
          });

          const { PON } = elements.find(el => el.elementId === elementId); // element being deleted

          // returns an array excluding elements in the deletion branch and targeted element
          const updatedElements = elements.filter(
            el => !el.PON.startsWith(`${PON}.`) && el.PON !== PON
          );

          elementDispatch({ type: DELETE_ELEMENT_SUCCESS, payload: updatedElements });

          // parentElement for updating childrenPON numbers and focus target
          const element = elements.find(el => el.elementId === elementId);
          const parent = findParentElement(element);

          // update parent element childrenPON to remove updated element
          elementDispatch({
            type: UPDATE_ELEMENT_SUCCESS,
            payload: { ...parent, childrenPON: filterChildren(element) },
          });

          // close delete modal
          closeModal();
        }
      } catch (error) {
        elementDispatch({ type: DELETE_ELEMENT_FAIL });
      }
    }
  };

  // delete column functionality
  const deleteColumn = () => {
    const { columnId } = modalConfig;

    if (columnId) deleteRequest('table', 'column', '/columns', columnId);
  };

  // delete layer functionality
  const deleteLayer = async () => {
    const { layerId } = modalConfig;

    // get previous and next layerIds for request
    const layer = layers.find(layer1 => layer1.layerId === layerId);
    const { boardId, position } = layer;
    const firstLayerId = layers[0].layerId;
    const previousLayer = layers.find(layer2 => layer2.position === position - 1);
    const previousLayerId = previousLayer && previousLayer.layerId;
    const nextLayer = layers.find(layer3 => layer3.position === position + 1);
    const nextLayerId = nextLayer && nextLayer.layerId;

    if (layerId && layers.length !== 1) {
      const deleteSuccess = deleteRequest('table', 'layer', '/layers', layerId);

      if (deleteSuccess) {
        // remove layer from added layer count
        profileDispatch({
          type: SET_USER_COUNTS,
          payload: { ...userCounts, addedLayers: addedLayers - 1 },
        });

        // fetch next layer for table.
        if (layerId === firstLayerId) {
          // set active layerId to newly selected layerId
          configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: nextLayerId });

          // send get request and set data to store
          await getRequest(
            'element',
            'layer',
            `/elements/qualities/board/${boardId}/layer/${nextLayerId}`
          );
        }

        if (layerId !== firstLayerId) {
          // set active layerId to newly selected layerId
          configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: previousLayerId });

          // send get request and set data to store
          await getRequest(
            'element',
            'layer',
            `/elements/qualities/board/${boardId}/layer/${previousLayerId}`
          );
        }
      }
    }
  };

  // delete board functionality
  const deleteBoard = async () => {
    const { boardId } = modalConfig;

    if (boardId) {
      const success = await deleteRequest('global', 'board', '/boards', boardId);

      if (success) {
        // fetch updated counts from database (since board contains elements and layers)
        try {
          const { data } = await Axios.get(`/users/${user.userId}/counts`);

          profileDispatch({ type: SET_USER_COUNTS, payload: data });
        } catch (error) {
          console.log(error);
        }
      }

      // Redirect to /dashboard if it's you're in /dashboard/board
      if (window.location.pathname.includes('/board')) history.push('/dashboard');
    }
  };

  // delete account functionality
  const deleteAccount = async () => {
    const { userId } = modalConfig;

    const success = deleteRequest('global', 'user', '/users', userId);

    if (success) {
      logout({});
      localStorage.removeItem(process.env.REACT_APP_STORAGE);
    }
  };

  // determine conditional display based on id type in modalConfig

  const { columnId, boardId, userId, layerId } = modalConfig;

  if (columnId) {
    return (
      <>
        <div>
          <Header text="Delete Column" closeModal={closeModal} />
          <p>Are you sure you want to delete this column?</p>
        </div>
        <DeleteButtons event={deleteColumn} />
      </>
    );
  }

  if (layerId) {
    return (
      <>
        <div>
          <Header text="Delete Layer" closeModal={closeModal} />
          <p>Are you sure you want to delete this layer?</p>
        </div>
        <DeleteButtons event={deleteLayer} />
      </>
    );
  }

  if (boardId) {
    return (
      <>
        <div>
          <Header text="Delete Board" closeModal={closeModal} />
          <p>Are you sure you want to delete this board?</p>
        </div>

        <DeleteButtons event={deleteBoard} />
      </>
    );
  }

  if (userId) {
    return (
      <>
        <div>
          <Header text="Delete User" closeModal={closeModal} />
          <p>
            All projects and information of this account will be permanently deleted. Are you sure
            you want to proceed? This action is irreversible.
          </p>
        </div>
        <DeleteButtons event={deleteAccount} deleteAccount />
      </>
    );
  }

  return (
    <>
      <div>
        <Header text="Delete Element" closeModal={closeModal} />
        <p>Are you sure you want to delete this element?</p>
      </div>
      <DeleteButtons event={deleteElement} />
    </>
  );
};

export default DeleteModal;
