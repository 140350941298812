import React, { useState } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ButtonWithIcon from '../../../components/General/ButtonWithIcon';
import AddIcon from '../../../assets/AddIcon';

// data management
import { tableContext, configContext } from '../../../store/contexts';
import { SET_ACTIVE_LAYER_ID } from '../../../store/constants';

// utility
import useGetRequest from '../../../requestHooks/useGetRequest';
import usePostRequest from '../../../requestHooks/usePostRequest';
import useFreemium from '../../../hooks/useFreemium';

import { colors } from '../../../styles/theme';

type propTypes = {
  isPublicRoute: boolean,
};

const AddLayer = ({ isPublicRoute }: propTypes) => {
  // config state
  const [, configDispatch] = useStateValue(configContext);

  // table state
  const [tableState] = useStateValue(tableContext);
  const { layers } = tableState;

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  // destructure request utility
  const getRequest = useGetRequest();
  const postRequest = usePostRequest();

  // destructure getBoardCount
  const { checkLayerCount } = useFreemium();

  const addLayer = async () => {
    if (!inProgress) {
      // start post request
      setInProgress(true);
      const allowPost = checkLayerCount();

      if (allowPost) {
        const lastLayer = layers[layers.length - 1];
        if (lastLayer) {
          const { position, boardId } = lastLayer;

          // send post request and set data to store
          const data = await postRequest('table', 'layer', `/layers/board/${boardId}`, {
            title: `Layer ${position + 1}`,
            boardId,
            position: position + 1,
          });

          if (data) {
            // post success - dispatch new data to store
            setInProgress(false);

            // set active layerId to new layerId
            configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: data.layerId });

            // send get request and set data to store
            await getRequest(
              'element',
              'layer',
              `/elements/qualities/board/${boardId}/layer/${data.layerId}`
            );
          }
        }
      }
      // post request not triggered
      setInProgress(false);
    }
  };

  return (
    <Styles>
      <ButtonWithIcon
        Icon={AddIcon}
        color={!isPublicRoute ? colors.purple : colors.mediumGrey}
        onClick={addLayer}
        disabled={isPublicRoute}
      />
    </Styles>
  );
};

export default AddLayer;

const Styles: any = styled.div`
  margin: 0 10px;

  button {
    padding: 0 0 0 10px;
  }
`;
