import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_CHARGEBEE_PORTAL_REQUEST_START,
  ADD_CHARGEBEE_PORTAL_REQUEST_SUCCESS,
  ADD_CHARGEBEE_PORTAL_REQUEST_FAIL,
  GET_CHARGEBEE_SUBSCRIPTION_START,
  GET_CHARGEBEE_SUBSCRIPTION_SUCCESS,
  GET_CHARGEBEE_SUBSCRIPTION_FAIL,
  SET_USER_COUNTS,
} from './constants';

const initialState = {
  user: {},
  userCounts: {
    totalBoards: undefined,
    totalElements: undefined,
    addedLayers: undefined,
  },
  chargebee: {},
  planVerified: undefined,
  isLoading: false,
  error: '',
};

const profileReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case GET_USER_START:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        error: '',
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userCounts: action.payload.counts,
        isAuthenticated: true,
        isLoading: false,
        error: '',
      };

    case GET_USER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: 'Failed to fetch user.',
      };

    case ADD_CHARGEBEE_PORTAL_REQUEST_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_CHARGEBEE_PORTAL_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case ADD_CHARGEBEE_PORTAL_REQUEST_FAIL:
      return {
        ...state,
        isLoading: true,
        error: action.payload,
      };

    case GET_CHARGEBEE_SUBSCRIPTION_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case GET_CHARGEBEE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        chargebee: action.payload,
        planVerified: true,
        isLoading: false,
        error: '',
      };

    case GET_CHARGEBEE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        planVerified: false,
        isLoading: false,
        error: 'Failed to fetch chargebee subscription data.',
      };

    case SET_USER_COUNTS:
      return {
        ...state,
        userCounts: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default profileReducer;
