import React from 'react';
import { useStateValue } from 'react-conflux/dist/cjs.min';

// components
import ButtonWithIcon from '../../../components/General/ButtonWithIcon';
import SocialShareIcon from '../../../components/General/Icons/SocialShareIcon';
import Tooltip from '../../../components/General/Tooltip';

// data management
import { globalContext } from '../../../store/contexts';

// utility
import useGetBoardPublicKey from '../../../hooks/useGetBoardPublicKey';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  isPublicRoute: boolean,
};

const SocialShare = ({ isPublicRoute }: propTypes) => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { board } = globalState;
  const { isPublic } = board;

  // destructure getBoardPublicKey
  const { getBoardPublicKey } = useGetBoardPublicKey();

  const clickShareButton = async () => getBoardPublicKey(board);

  // TODO: fix this logic, it is unclear
  // dynamically set public icon color and pass to ButtonWithIcon
  const disableButton = isPublic && !isPublicRoute;
  const color = !disableButton ? colors.lightGrey : colors.purple;

  return (
    <Tooltip title={isPublicRoute ? 'You can only view this board.' : ''}>
      <ButtonWithIcon
        text="share"
        className="invert-button"
        Icon={SocialShareIcon}
        color={color}
        onClick={clickShareButton}
        disabled={isPublicRoute}
      />
    </Tooltip>
  );
};

export default SocialShare;
