import React from 'react';

type propTypes = {
  color: string,
};

const DropdownCarrot = ({ color }: propTypes) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 6L11 1" stroke={color} strokeWidth="1" />
    </svg>
  );
};

export default DropdownCarrot;
