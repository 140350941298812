import { useState } from 'react';
import { useStateValue } from 'react-conflux';
import Axios from '../config/axios_config';

// data management
import {
  globalContext,
  elementContext,
  tableContext,
  profileContext,
  configContext,
} from '../store/contexts';

// dynamic update request utility
const useUpdateRequest = () => {
  // config store
  const [configDispatch] = useStateValue(configContext); // eslint-disable-line

  // profile store
  const [, profileDispatch] = useStateValue(profileContext); // eslint-disable-line

  // global store
  const [, globalDispatch] = useStateValue(globalContext); // eslint-disable-line

  // element store
  const [, elementDispatch] = useStateValue(elementContext); // eslint-disable-line

  // table store
  const [, tableDispatch] = useStateValue(tableContext); // eslint-disable-line

  // request management boolean
  const [inProgress, setInProgress] = useState(false);

  const update = async (store: string, resource: string, url: string, incomingUpdate: Object) => {
    // uppercase table for dispatch
    const RESOURCE = resource.toUpperCase();

    // if request is in progress, block new request
    if (!inProgress) {
      try {
        // start update request
        setInProgress(true);
        eval(`${store}Dispatch`)({ type: `UPDATE_${RESOURCE}_START` }); // eslint-disable-line

        // update board
        const { data } = await Axios.put(`${url}`, incomingUpdate);
        if (data) {
          // update success - dispatch new data to store
          setInProgress(false);
          eval(`${store}Dispatch`)({ type: `UPDATE_${RESOURCE}_SUCCESS`, payload: data }); // eslint-disable-line
        }

        return true;
      } catch (error) {
        // stop update request
        setInProgress(false);

        // dispatch update failure
        eval(`${store}Dispatch`)({ type: `UPDATE_${RESOURCE}_FAIL` }); // eslint-disable-line
        return false;
      }
    }

    // if request is already in progress - return false
    return false;
  };

  return update;
};

export default useUpdateRequest;
