import React from 'react';
import styled from 'styled-components';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {}; // eslint-disable-line

const Terms = () => {
  return (
    <Styles>
      <p>
        <a
          data-testid="subscription-agreement-link"
          href="https://brainsquall.co/subscription-agreement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Subscription Agreement
        </a>
        <br />
        <span>&</span>
        <a
          data-testid="privacy-policy-link"
          href="https://brainsquall.co/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
    </Styles>
  );
};

export default Terms;

const Styles: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  width: 100%;
  height: 70px;
  background: ${colors.white};

  a {
    font-size: 1.4rem;
  }

  p {
    margin: 0;
    padding-left: 16px;

    span {
      margin-right: 5px;
    }
  }
`;
