import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import jwt from 'jsonwebtoken';

// data management
import { globalContext } from '../../../../store/contexts';

// utility
import useUpdateRequest from '../../../../requestHooks/useUpdateRequest';

declare class process {
  static env: {
    REACT_APP_PK: string,
  };
}

// environment variables
const secret = process.env.REACT_APP_PK;

type propTypes = {
  boardId: number,
  currentPosition: number,
  setCurrentPosition: Function,
  ponColumnWidth: number,
  setPONColumnWidth: Function,
  isHeld: boolean,
  setIsHeld: Function,
  isHidden: boolean,
};

const AdjustPONWidth = ({
  boardId,
  currentPosition,
  setCurrentPosition,
  ponColumnWidth,
  setPONColumnWidth,
  isHeld,
  setIsHeld,
  isHidden,
}: propTypes) => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { board } = globalState;

  // track start position of column adjustment
  const [startPosition, setStartPosition] = useState(0);

  // track active column adjustment
  const [activeId, setActiveId] = useState(undefined);

  // destructure request utility
  const update = useUpdateRequest();

  // update local element column width with column width as it is adjusted
  useEffect(() => {
    // calculate adjustment then reset current position to avoid compounding
    const distanceMoved = Math.floor(currentPosition - startPosition);
    setStartPosition(currentPosition);

    if (distanceMoved > -100 && distanceMoved < 100) {
      if (board && boardId === activeId && ponColumnWidth === 0) setPONColumnWidth(1);
      if (board && boardId === activeId) setPONColumnWidth(ponColumnWidth + distanceMoved);
    }
  }, [currentPosition, isHeld, boardId, startPosition]); // eslint-disable-line

  // set start position of column, isHeld, and set targetId
  const startAdjustment = (e, targetId) => {
    setActiveId(targetId);
    setStartPosition(e.pageX);
    setIsHeld(true);
  };

  // submit request to update column width

  const submitColumnAdjustment = useCallback(async () => {
    setIsHeld(false);
    setActiveId(0);
    setStartPosition(0);
    setCurrentPosition(0);

    if (boardId && boardId === activeId) {
      // send request and update store
      const success = update('global', 'board', `/boards/${boardId}`, {
        ponWidth: ponColumnWidth,
      });

      if (success) {
        // tokenize boardId and store in local storage for refresh reference.
        const tokenizedBoard = jwt.sign(board, secret);
        localStorage.setItem('board', tokenizedBoard);
      }
    }
  }, [
    setIsHeld,
    setActiveId,
    setStartPosition,
    setCurrentPosition,
    boardId,
    activeId,
    board,
    ponColumnWidth,
    update,
  ]);

  // submit column adjustment is mouse is let go or leaves first row of table
  useEffect(() => {
    if (isHeld === false && activeId) submitColumnAdjustment();
  }, [isHeld, submitColumnAdjustment, activeId]);

  return (
    <Styles
      className="column-adjustment-button"
      onMouseDown={e => startAdjustment(e, boardId)}
      onMouseUp={submitColumnAdjustment}
    >
      <span
        className={activeId === boardId ? 'active-column' : null}
        style={{ display: isHidden ? 'none' : null }}
      />
    </Styles>
  );
};

export default AdjustPONWidth;

const Styles: any = styled.div``;
