import React from 'react';
import styled from 'styled-components';

// component
import ButtonWithIcon from '../../../General/ButtonWithIcon';

// content
import { mediaContent } from './surveyContent';

// asset
import DropdownCarrot from '../../../../assets/DropdownCarrot';

// theme
import { colors } from '../../../../styles/theme';

type propTypes = {
  setPage: Function,
  setSurveyData: Function,
};

const UserReference = ({ setPage, setSurveyData }: propTypes) => {
  // destructure from mediaContent
  const { references } = mediaContent;

  // update userReferenceId
  const handleClick = userReferenceId => {
    setSurveyData(surveyData => ({ ...surveyData, userReferenceId }));
    setPage(5);
  };

  return (
    <Styles className="survey-modal-wrapper">
      <h3 style={{ marginBottom: '32px' }}>{mediaContent.title}</h3>
      {references.map(reference => (
        <button
          type="button"
          className="role-button"
          key={reference.title}
          onClick={() => handleClick(reference.userReferenceId)}
        >
          <h2>{reference.title}</h2>
        </button>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonWithIcon
          Icon={DropdownCarrot}
          text="skip"
          className="survey-menu-button"
          position="right"
          color={colors.darkGrey}
          onClick={() => setPage(5)}
        />
      </div>
    </Styles>
  );
};

export default UserReference;

const Styles: any = styled.div`
  h2 {
    margin-bottom: 32px;
  }
`;
