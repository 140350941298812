import { sortElementsByPON, sortChildrenByPON } from './sortElements';

export const getLastElement = (elements: Array<Object>) => {
  const sortedElements = sortElementsByPON(elements);

  const lastElement = sortedElements[sortedElements.length - 1];

  return lastElement;
};

export const getLastChildPON = (children: Array<string>) => {
  const sortedChildren: Array<string> = sortChildrenByPON(children);

  return sortedChildren[sortedChildren.length - 1];
};
