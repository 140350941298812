import React from 'react';
import { useStateValue } from 'react-conflux';

// components
import Labels from '../../BoardView/Canvas/Labels';

// data management
import { configContext } from '../../../store/contexts';
import { SET_HIGHLIGHT } from '../../../store/constants';

// utility
import useDetermineBackground from '../../../hooks/useDetermineBackground';

type propTypes = {
  element: Object,
};

const ReadOnlyElement = ({ element }: propTypes) => {
  const { elementId, parentId, xCoord, yCoord, title, PON } = element;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { activeEditId } = configState;

  // destructure determine background
  const determineBackground = useDetermineBackground();

  const inputWidth = title.length * 7.3 + 50;

  const getForeignObjectWidth = () => {
    if (inputWidth + 40 < 170) return 170;

    return inputWidth + 40;
  };

  return (
    <foreignObject
      x={xCoord}
      y={yCoord}
      height={80}
      width={getForeignObjectWidth()}
      onClick={() => configDispatch({ type: SET_HIGHLIGHT, payload: elementId })}
    >
      <div
        className={activeEditId === elementId ? 'node active-element-canvas' : 'node'}
        style={{
          width: inputWidth + 20,
          background: determineBackground(element),
        }}
      >
        <div className="read-only-div">
          {parentId ? (
            <span className="pon-label read-only-pon-label">{PON.slice(2)}</span>
          ) : (
            <span className="pon-label read-only-pon-label" />
          )}
          <p className="read-only-p">{title}</p>

          <Labels element={element} />
        </div>
      </div>
    </foreignObject>
  );
};

export default ReadOnlyElement;
