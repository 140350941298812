import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import ElementRow from './ElementRow';
import EmptyElement from './EmptyElement';

// data management
import { elementContext } from '../../../../store/contexts';

type propTypes = {
  updateElement: Function,
  tableWidth: number,
};

const Elements = ({ updateElement, tableWidth }: propTypes) => {
  const [elementState] = useStateValue(elementContext);
  const { elements } = elementState;

  return (
    <Styles>
      {elements.map((element, index) => {
        const { elementId } = element;
        return (
          <ElementRow
            key={elementId}
            index={index}
            element={element}
            updateElement={updateElement}
            tableWidth={tableWidth}
          />
        );
      })}
      {/* empty element row at the bottom of the table */}
      {elements.length > 0 && <EmptyElement />}
    </Styles>
  );
};

export default Elements;

const Styles: any = styled.div``;
