import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';
import moment from 'moment';

// components
import Button from '../../components/General/Button';
import Loader from '../../components/General/Loader';
import OptionsButton from '../../components/General/OptionsButton';
import SocialShareIcon from '../../components/General/Icons/SocialShareIcon';
import Tooltip from '../../components/General/Tooltip';

// data management
import { globalContext, elementContext, configContext } from '../../store/contexts';
import {
  SELECT_BOARD,
  SET_ACTIVE_LAYER_ID,
  SET_CANVAS_CONFIG,
  SET_NOTIFICATION_CONFIG,
} from '../../store/constants';

// utility
import useGetRequest from '../../requestHooks/useGetRequest';
import { setLocalTokenizedStorage } from '../../util/localStorage';
import useHistory from '../../hooks/useHistory';

// theme
import { colors } from '../../styles/theme';
import ButtonWithIcon from '../../components/General/ButtonWithIcon';

type propTypes = {
  board: Object,
};

const BoardCard = ({ board }: propTypes) => {
  const history = useHistory();

  const { boardId, title, layerId, labelId, createdAt, isPublic, boardPublicKey } = board;

  // global store
  const [globalState, globalDispatch] = useStateValue(globalContext);
  const { selectedBoardId } = globalState;

  // config store
  const [configState, configDispatch] = useStateValue(configContext);
  const { modalConfig } = configState;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { initialElementsLoading } = elementState;

  // destructure request utility
  const getRequest = useGetRequest();

  // overlay display boolean
  const [overlayHidden, setOverlayHidden] = useState(true);

  // get board data
  const selectBoard = async () => {
    // tokenize board and store in local storage for refresh reference.
    setLocalTokenizedStorage('board', board);

    globalDispatch({ type: SELECT_BOARD, payload: board });
    configDispatch({ type: SET_ACTIVE_LAYER_ID, payload: layerId });
    configDispatch({ type: SET_CANVAS_CONFIG, payload: { labelId } });

    // send get request and set data to store
    const tableData = await getRequest('table', 'table', `/table/board/${boardId}`);

    if (tableData) {
      // send get request and set data to store
      await getRequest(
        'element',
        'initial_elements',
        `/elements/qualities/board/${boardId}/layer/${layerId}`
      );

      history.push('/dashboard/board');
    }
  };

  // hide overlay if delete modal is opened.
  useEffect(() => {
    if (modalConfig.open && modalConfig.boardId === boardId) setOverlayHidden(true);
  }, [modalConfig, boardId]);

  // dynamically set public icon color and pass to ButtonWithIcon
  const color = isPublic ? colors.purple : colors.lightGrey;

  // copy public URL to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`https://create.brainsquall.co/board/${boardPublicKey}`);
    const notification = {
      notificationOpen: true,
      message: 'link copied to clipboard',
      type: 'success',
    };

    // trigger link copied notification
    configDispatch({ type: SET_NOTIFICATION_CONFIG, payload: notification });
  };

  return (
    <Styles
      className={
        modalConfig.open && modalConfig.boardId === boardId && modalConfig.type === 'delete'
          ? 'deletion-card'
          : null
      }
    >
      {initialElementsLoading && boardId === selectedBoardId ? (
        <Loader />
      ) : (
        <div
          className="card"
          onMouseOver={() => setOverlayHidden(false)}
          onMouseLeave={() => setOverlayHidden(true)}
          onFocus={() => setOverlayHidden(false)}
          onBlur={() => setOverlayHidden(true)}
        >
          <button type="button" className="card-top" onClick={selectBoard}>
            <h3
              style={{ fontSize: title && title.length > 38 ? '2.0rem' : '2.8rem' }}
              data-testid="board-title"
            >
              {title}
            </h3>
            <p>{moment(createdAt).format('M/DD/YYYY')}</p>
          </button>

          <div className="overlay" style={{ display: overlayHidden ? 'none' : 'flex' }}>
            <Button text="View Board" event={selectBoard} />
          </div>

          <div className="card-line" />
          <div className="card-bottom">
            <Tooltip title={isPublic ? 'copy link' : 'inactive link'}>
              <ButtonWithIcon
                Icon={SocialShareIcon}
                color={color}
                isPublic={isPublic}
                style={isPublic ? { cursor: 'pointer' } : { cursor: 'auto' }}
                onClick={isPublic ? handleCopyToClipboard : null}
              />
            </Tooltip>

            <OptionsButton board={board} />
          </div>
        </div>
      )}
    </Styles>
  );
};

export default BoardCard;

const Styles: any = styled.div`
  min-width: 312px;
  max-width: 312px;
  height: 222px;

  border-radius: 8px;
  border: solid 1px ${colors.paleGrey};
  box-shadow: ${colors.boxShadow};

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .card-top {
      font-family: inherit;
      padding: 20px 20px 0 20px;
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-transform: none;

      margin: 0;
      text-align: left;

      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border: 0;

      h3 {
        padding: 0;
        max-width: 290px;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }

      p {
        font-size: 1.2rem;
        color: ${colors.mediumGrey};
        padding-left: 2px;
      }
    }

    .overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 132px;
      width: 100%;

      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background: ${colors.lightTransparency};
    }

    .card-line {
      position: absolute;
      top: 132px;
      height: 1px;
      background: ${colors.paleGrey};
      width: 100%;
    }

    .card-bottom {
      display: flex;
      justify-content: space-between;
      padding: 24px 20px 0 20px;
      height: 40%;

      span {
        svg {
          width: 20px;
          height: 20px;
          margin-top: 10px;
        }
      }
    }
  }
`;
