import React from 'react';
import styled from 'styled-components';

// components
import Link from './Link';

// theme
import { colors } from '../../styles/theme';

type propTypes = {
  text: string,
  type?: string,
  className?: string,
  redirect?: string,
  disabled?: boolean,
  event?: Function,
  testId?: string,
  style?: Object,
};

const Button = ({ testId, text, type, className, redirect, disabled, event, style }: propTypes) => {
  if (!disabled && redirect) {
    return (
      <Link to={redirect === 'home' ? '/' : `/${redirect}`} onClick={event}>
        <Styles className={className} data-testid={testId} type={type} style={style}>
          {text}
        </Styles>
      </Link>
    );
  }

  if (disabled) {
    return (
      <Styles data-testid={testId} className="disabled-button" type={type} style={style}>
        {text}
      </Styles>
    );
  }

  return (
    <Styles data-testid={testId} className={className} type={type} onClick={event} style={style}>
      {text}
    </Styles>
  );
};

export default Button;

Button.defaultProps = {
  className: '',
  type: 'button',
  redirect: undefined,
  disabled: false,
  event: undefined,
  testId: '',
  style: {},
};

const Styles: any = styled.button`
  min-width: 110px;
  border: 1px solid ${colors.purple};
  background: ${colors.purple};
  color: ${colors.white};

  :hover {
    background: ${colors.darkPurple};
  }

  :focus {
    background: ${colors.lightPurple};
  }
`;
