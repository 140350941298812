import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux/dist/cjs.min';

// components
import Button from '../../../components/General/Button';

// data management
import { configContext } from '../../../store/contexts';
import { SET_MODAL_CONFIG } from '../../../store/constants';

// theme
import { colors } from '../../../styles/theme';

type propTypes = {
  user: Object,
};

const DangerZone = ({ user }: propTypes) => {
  const { userId } = user;

  // config store
  const [, configDispatch] = useStateValue(configContext);

  return (
    <Styles className="settings-container">
      <h3 className="container-title">DANGER ZONE</h3>
      <div className="container-content">
        <p>Proceed with caution.</p>
        <Button
          type="button"
          text="Delete Account"
          event={() =>
            configDispatch({
              type: SET_MODAL_CONFIG,
              payload: { open: true, type: 'delete', userId },
            })
          }
        />
      </div>
    </Styles>
  );
};

export default DangerZone;

const Styles: any = styled.div`
  h3 {
    color: ${colors.warningRed} !important;
  }
`;
