import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import Title from './Title';

// data management
import { elementContext, globalContext } from '../../../../store/contexts';

// utility
import useContextMenu from '../../../../hooks/useContextMenu';

type propTypes = {
  rowIndex: number,
  element: Object,
  updateElement: Function,
};

const Element = ({ rowIndex, element, updateElement }: propTypes) => {
  const { elementId, parentId, title, PON } = element;

  // set initial title from database, if title is updated, update the local title
  const [inputValue, setInputValue] = useState(title);

  // global store
  const [globalState] = useStateValue(globalContext);
  const { board } = globalState;
  const { elementWidth, ponWidth } = board;

  // element store
  const [elementState] = useStateValue(elementContext);
  const { activeEdit, elements } = elementState;

  // context menu
  const { openContextMenu } = useContextMenu();

  // if element title updates on another component, sync local input values
  useEffect(() => {
    if (activeEdit.elementId === elementId) setInputValue(activeEdit.title);
  }, [activeEdit.title, activeEdit.elementId, elementId]);

  return (
    <Styles>
      <div className="cell" onContextMenu={e => openContextMenu(e, elementId)}>
        <Title
          rowIndex={rowIndex}
          element={element}
          elements={elements}
          inputValue={inputValue}
          setInputValue={setInputValue}
          elementWidth={elementWidth}
          updateElement={updateElement}
        />
      </div>

      <div
        className="cell"
        style={{
          width: ponWidth < 60 ? 80 : ponWidth + 20 || null,
          minWidth: ponWidth < 60 ? 80 : ponWidth + 20 || null,
        }}
      >
        {parentId ? <p>{PON.slice(2)}</p> : <p />}
      </div>
    </Styles>
  );
};

export default Element;

const Styles: any = styled.div`
  display: flex;

  .cell p {
    display: flex;
    align-items: center;
    margin: 0 15px;
    height: 100%;
  }
`;
