import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// components
import HelpMenuModal from './HelpMenuModal';
import Button from '../../General/Button';
import Tooltip from '../../General/Tooltip';

// theme
import { colors } from '../../../styles/theme';

// utility
import useGetBoards from '../../../hooks/useGetBoards';

const HelpMenuButton = () => {
  // request boards when user has loaded
  useGetBoards();

  // open / close menu state management
  const [menuOpen, setMenuOpen] = useState(false);

  // open / close menu helper functions
  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [setMenuOpen, menuOpen]);

  // attach close menu listeners when help menu is opened
  useEffect(() => {
    if (menuOpen) document.addEventListener('click', toggleMenu, false);

    return () => document.removeEventListener('click', toggleMenu, false);
  }, [toggleMenu, menuOpen]);

  return (
    <Styles>
      <HelpMenuModal menuOpen={menuOpen} toggleHelpMenu={toggleMenu} />
      <Tooltip title="help & resources" position="left">
        <Button
          testId="help-menu-button"
          type="button"
          text="?"
          className="help-menu-button"
          event={toggleMenu}
        />
      </Tooltip>
    </Styles>
  );
};

export default HelpMenuButton;

const Styles: any = styled.div`
  .help-menu-button {
    display: flex;
    justify-content: center;

    font-size: 2.4rem;
    align-items: center;
    color: ${colors.white};
    font-weight: normal;

    position: fixed;
    bottom: 20px;
    right: 20px;

    border: 0;
    border-radius: 50%;

    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    box-shadow: ${colors.boxShadow};
    background: ${colors.purple};

    font-family: 'SofiaPro' !important;

    z-index: 2;

    :hover {
      background: ${colors.darkPurple};
    }
  }
`;
