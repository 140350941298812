/* eslint-disable */
export const introContent = {
  title: 'Thanks for signing up!',
  description: "We're almost done setting up your account. There're just 3 more short questions.",
};

export const nameContent = {
  title: "1) What's your name?",
  description: 'You can change this later in the account settings.',
};
export const roleContent = {
  title: '2) Which role best describes you?',
  description:
    'This information helps us curate educational videos, articles, and templates that’ll help your workflow.',
  roles: [
    {
      title: 'I design and build',
      role: 'Architecture, Design, Engineering, Project Mgmt',
      acRoleId: 1,
    },
    {
      title: 'I sell and communicate',
      role: 'Marketing, Sales, Implementation, Customer Success',
      acRoleId: 2,
    },
    {
      title: 'I research and advise',
      role: 'Analysis, Investment, Product Mgmt, Consulting',
      acRoleId: 3,
    },
    {
      title: 'I support and advocate',
      role: 'Law, Human Resources, Management',
      acRoleId: 4,
    },
    {
      title: 'Other',
      role: 'Student, Teacher, Accounting, Counselor, Entrepreneur, etc.',
      acRoleId: 5,
    },
  ],
};

export const mediaContent = {
  title: '3) How did you hear about BrainSquall?',
  references: [
    {
      title: 'Friend or colleague',
      userReferenceId: 1,
    },
    {
      title: 'LinkedIn',
      userReferenceId: 2,
    },
    {
      title: 'YouTube',
      userReferenceId: 3,
    },
    {
      title: 'Medium',
      userReferenceId: 4,
    },
    {
      title: 'Other',
      userReferenceId: 5,
    },
  ],
};

export const outroContent = {
  title: "Thanks! You're all set up.",
  description: 'Have fun exploring BrainSquall!',
};
