import React from 'react';
// $FlowFixMe - react-router-dom
import { Redirect } from 'react-router-dom';

// config
import redirects from '../../../config/redirects';

// redirect if pathname is found in redirect config

type propTypes = {
  path: string,
};

const HandleRedirect = ({ path }: propTypes) => {
  return <Redirect exact from={path} to={redirects[path]} />;
};

export default HandleRedirect;
