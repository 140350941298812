import React from 'react';
import styled from 'styled-components';
import { useStateValue } from 'react-conflux';

// components
import AccountDetails from './AccountDetails';
import DangerZone from './DangerZone';
import Loader from '../../../components/General/Loader';
import ManagePlan from '../ManagePlan';
import Plans from '../Plans';
import PlanDetails from './PlanDetails';

// data management
import { globalContext, profileContext } from '../../../store/contexts';

type propTypes = {}; // eslint-disable-line

const Account = () => {
  // global store
  const [globalState] = useStateValue(globalContext);
  const { isLoading } = globalState;

  // profile store
  const [profileState] = useStateValue(profileContext);
  const { user, chargebee, userCounts } = profileState;
  const { planId, status } = chargebee;

  if (isLoading === true) return <Loader />;

  return (
    <Styles>
      <AccountDetails user={user} />

      <PlanDetails chargebee={chargebee} userCounts={userCounts} />

      {planId === 'starter' || status === 'cancelled' ? <Plans /> : <ManagePlan />}

      <DangerZone user={user} />
    </Styles>
  );
};

export default Account;

const Styles: any = styled.div``;
