import React from 'react';
import styled from 'styled-components';

// components
import AdjustPONWidth from './AdjustPONWidth';

type propTypes = {
  boardId: number,
  currentPosition: number,
  setCurrentPosition: Function,
  ponColumnWidth: number,
  setPONColumnWidth: Function,
  isHeld: boolean,
  setIsHeld: Function,
  isHidden: boolean,
};

const PONColumnTitle = ({
  boardId,
  currentPosition,
  setCurrentPosition,
  ponColumnWidth,
  setPONColumnWidth,
  isHeld,
  setIsHeld,
  isHidden,
}: propTypes) => {
  return (
    <Styles>
      <div
        className="cell"
        style={{
          minWidth: ponColumnWidth < 60 ? 60 : ponColumnWidth,
          width: ponColumnWidth < 60 ? 60 : ponColumnWidth,
        }}
      >
        <p>PON</p>
      </div>

      <AdjustPONWidth
        boardId={boardId}
        currentPosition={currentPosition}
        setCurrentPosition={setCurrentPosition}
        ponColumnWidth={ponColumnWidth}
        setPONColumnWidth={setPONColumnWidth}
        isHeld={isHeld}
        setIsHeld={setIsHeld}
        isHidden={isHidden}
      />
    </Styles>
  );
};

export default PONColumnTitle;

const Styles: any = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'SofiaProBold';
  }
`;
