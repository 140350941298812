import React from 'react';
import ReactDOM from 'react-dom';
import { StateProvider } from 'react-conflux';

// component imports
import App from './App';

// auth
import { Auth0Provider } from './auth/AuthWrapper';
import config from './config/auth_config';

// data management
import {
  globalContext,
  tableContext,
  elementContext,
  profileContext,
  configContext,
} from './store/contexts';
import globalReducer from './store/globalReducer';
import elementReducer from './store/elementReducer';
import tableReducer from './store/tableReducer';
import profileReducer from './store/profileReducer';
import configReducer from './store/configReducer';

// google analytics
import { InitializeGA } from './util/analytics';

// global styles
import GlobalStyles from './styles';

// Routes the user to the right place after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

// invoke analytics
InitializeGA();

ReactDOM.render(
  <StateProvider reducer={globalReducer} stateContext={globalContext}>
    <StateProvider reducer={elementReducer} stateContext={elementContext}>
      <StateProvider reducer={tableReducer} stateContext={tableContext}>
        <StateProvider reducer={profileReducer} stateContext={profileContext}>
          <StateProvider reducer={configReducer} stateContext={configContext}>
            <Auth0Provider
              domain={config.domain}
              client_id={config.clientId}
              redirect_uri={window.location.origin}
              onRedirectCallback={onRedirectCallback}
            >
              <GlobalStyles />
              <App />
            </Auth0Provider>
          </StateProvider>
        </StateProvider>
      </StateProvider>
    </StateProvider>
  </StateProvider>,
  document.getElementById('root')
);
