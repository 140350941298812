import { useStateValue } from 'react-conflux';

// data management
import { configContext } from '../store/contexts';

// config
import { colorNames } from '../config/element_config';

const useDetermineBackground = () => {
  // config store
  const [configState] = useStateValue(configContext);
  const { activeLayerId } = configState;

  // determine background color for element
  const determineBackground = (element: Object) => {
    const { configQuality } = element;

    if (configQuality) {
      const { colorId, layerId } = configQuality;

      if (layerId === activeLayerId) return colorNames[colorId];
    }

    return undefined;
  };

  return determineBackground;
};

export default useDetermineBackground;
