import React from 'react';
import styled from 'styled-components';

// components
import Account from './Account';
import SettingsNavigation from './SettingsNavigation';

// theme
import { colors } from '../../styles/theme';

type propTypes = {}; // eslint-disable-line

const Settings = () => {
  return (
    <Styles>
      <SettingsNavigation />
      <Account />
    </Styles>
  );
};

export default Settings;

const Styles: any = styled.div`
  height: calc(100vh - 140px);
  max-width: 1050px;
  margin: 0 auto;

  @media (max-width: 1100px) {
    max-width: 95%;
  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    max-width: 600px;
  }

  .settings-container {
    border-radius: 8px;
    box-shadow: ${colors.boxShadow};
    border: solid 1px ${colors.paleGrey};
    margin: 20px 0;

    .container-title {
      font-family: 'SofiaProBold';
      font-size: 1.6rem;
      letter-spacing: 0.33px;

      padding: 20px;
      border-bottom: solid 1px ${colors.paleGrey};
    }

    .container-content {
      padding: 30px;
    }
  }
`;
